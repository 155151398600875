import React, { useState } from 'react';
import { Card, Divider, Stack, Typography } from '@mui/material';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { MyButton } from '../../UI/MyButton';
import { MyPasswordTextField } from '../../UI/MyPasswordTextField';
import { MyTextFiled } from '../../UI/MyTextField';
import { IApiResult } from '../../../_interface/IApiResult';
import { apiSignUp } from '../../../services/customer/apiSignUp';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { CustomerSignUpDto } from '../../../_dto/_customer/CustomerSignUpDto';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { MyAlert } from '../../UI/MyAlert';

export const SignUpDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [customerSignUpDto, setCustomerSignUpDto] = useState<CustomerSignUpDto>(
    {
      login: '',
      password: '',
      region: '',
      name: '',
      inn: '',
      phone: '',
      email: '',
      address: '',
      other: '',
      code: '',
      delegate: '',
      workerId: -1,
    }
  );

  const signUp = async () => {
    const apiResult: IApiResult = await apiSignUp(customerSignUpDto);
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Исполнитель создан',
        severity: AlertSeverityEnum.info,
      });
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: 'Такой логин существует',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSignUp = async () => {
    if (customerSignUpDto.login === '' || customerSignUpDto.password === '') {
      setAlertProps({
        message: 'Введите логин и пароль',
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    await signUp();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '25%' }}>
        <Stack spacing={2}>
          <Typography
            style={{
              fontFamily: 'SF Compact Rounded',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: MAIN_TITLE_FONTSIZE_STRING,
              letterSpacing: 1,
              color: '#000000',
            }}
            component="text"
          >
            Регистрация филиала
          </Typography>
          <Divider />
          <MyTextFiled
            value={customerSignUpDto.name}
            onChange={(e) =>
              setCustomerSignUpDto({
                ...customerSignUpDto,
                name: e.target.value,
              })
            }
            label="Наименование"
            size="small"
          />
          <MyTextFiled
            value={customerSignUpDto.region}
            onChange={(e) =>
              setCustomerSignUpDto({
                ...customerSignUpDto,
                region: e.target.value,
              })
            }
            label="Регион"
            size="small"
          />
          <MyTextFiled
            value={customerSignUpDto.inn}
            onChange={(e) =>
              setCustomerSignUpDto({
                ...customerSignUpDto,
                inn: e.target.value,
              })
            }
            label="ИНН"
            size="small"
          />
          <Stack width="100%" direction="row" spacing={2}>
            <MyTextFiled
              value={customerSignUpDto.delegate}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  delegate: e.target.value,
                })
              }
              label="Представитель"
              size="small"
            />
            <MyTextFiled
              value={customerSignUpDto.code}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  code: e.target.value,
                })
              }
              label="Шифр"
              size="small"
            />
          </Stack>
          <Stack width="100%" direction="row" spacing={2}>
            <MyTextFiled
              value={customerSignUpDto.phone}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  phone: e.target.value,
                })
              }
              label="Телефон"
              size="small"
            />
            <MyTextFiled
              value={customerSignUpDto.email}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  email: e.target.value,
                })
              }
              label="E-mail"
              size="small"
            />
            <MyTextFiled
              value={customerSignUpDto.address}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  address: e.target.value,
                })
              }
              label="Адрес"
              size="small"
            />
          </Stack>
          <Stack width="100%" direction="row" spacing={2}>
            <MyTextFiled
              value={customerSignUpDto.login}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  login: e.target.value,
                })
              }
              label="Логин"
              size="small"
            />
            <MyPasswordTextField
              value={customerSignUpDto.password}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  password: e.target.value,
                })
              }
              label="Пароль"
              size="small"
            />
          </Stack>
          <MyButton
            text="Зарегистрировать"
            background={MAIN_COLOR_STRING}
            onClick={handleSignUp}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
