import React from 'react';
import { CustomerDiv } from '../component/customer/CustomerDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const CustomerPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<CustomerDiv />}
    // elementPult={<PultDiv />}
  />
);
