import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { DocumentDeleteDocumentDto } from '../../_dto/_document/DocumentDeleteDocumentDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteDocument = async (documentDeleteDocumentDto: DocumentDeleteDocumentDto) => {
  const params: IApiParams = {
    url: '/document/deleteDocument.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'DELETE', documentDeleteDocumentDto);
};