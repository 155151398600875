import React from 'react';
import { AccountantDiv } from '../component/accountant/AccountantDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const AccountantPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<AccountantDiv />}
  />
);
