import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ReestrAddDto } from '../../_dto/_reestr/ReestrAddDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddReestr = async (dto: ReestrAddDto) => {
  const params: IApiParams = {
    url: '/reestr/addReestr.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
