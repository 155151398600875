export const MAIN_COLOR_STRING = '#00695c';
export const MAIN_TITLE_FONTSIZE_STRING = '20px';
export const SECONDARY_COLOR_STRING = '#00695c';
export const ACCESS_TOKEN_NAME = 'access';
export const REFRESH_TOKEN_NAME = 'refresh';
export const REGION_NAME = 'region';
export const VIEW_NAME = 'view';
export const FULLNAME_NAME = 'name';
export const HASH_NAME = 'hash';
export const ROOT_CUSTOMER_VALUE = '-1';
export const ROOT_WORKER_VALUE = '1';
export const ROOT_BUHGALTER_VALUE = '2';
export const ROOT_REGIONGAZ_VALUE = '3';
export const ROOT_ADMIN_VALUE = '4';
export const JSON_CONTENT_TYPE = 'application/json';
export const FORM_DATA_CONTENT_TYPE = 'form-data';
export const GRID_VIEW = 'grid';
export const LIST_VIEW = 'list';
export const WARNING_DATA_CONTENT_TYPE = 'warning-data';
export const API_REQUEST_TIMEOUT_MILLISECONDS = 300_000;
export const API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS = -10;
export const API_STATUS_PROBLEM =
  'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже';
export const DOCUMENT_CONTRACT = 0;
export const DOCUMENT_FORM = 1;
export const DOCUMENT_WARNING = 2;
export const DOCUMENT_CHAT = 3;
export const ACCEPTED_INPUT_FORM_FILE_TYPES = '.doc,.docx,.pdf';
export const ACCEPTED_INPUT_WARNING_FILE_TYPES = '.doc,.docx,.pdf';
export const MAX_UPLOAD_FILES_COUNT = 50;
export const MAX_UPLOAD_FILES_SIZE = 1000000000;
export const EXPRESS_WORK_KOEFF = 1.5;
