import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../_constant/constants';
import { IApiParams } from '../_interface/IApiParams';
import { getApi } from './getApi';

export const apiGetCount = async () => {
  const params: IApiParams = {
    url: '/getCountInfo.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
