import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  EXPRESS_WORK_KOEFF,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { ReestrAddDto } from '../../../_dto/_reestr/ReestrAddDto';
import { apiAddReestr } from '../../../services/reestr/apiAddReestr';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { WorkTypeEnum } from '../../../_enum/WorkTypeEnum';
import { WorkDto } from '../../../_dto/_work/WorkDto';
import { apiGetWork } from '../../../services/work/apiGetWork';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { WorkStartTypeEnum } from '../../../_enum/WorkStartTypeEnum';
import { PeriodDateTypeEnum } from '../../../_enum/PeriodDateTypeEnum';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogReestrCreate: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const getParamArray = (type: number, data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].workType === type) {
        arrres.push(data[i].subject);
      }
    }
    return arrres;
  };
  const [workDtos, setWorkDtos] = useState<WorkDto[]>([]);
  const getWork = async () => {
    const apiResult: IApiResult = await apiGetWork();
    if (apiResult.isSuccess) {
      setWorkDtos(apiResult.data.work);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [reestrAddDto, setReestrAddDto] = useState<ReestrAddDto>({
    workType: -1,
    subject: '',
    cost: -1,
    period: -1,
    periodBegin: -1,
    dateType: -1,
    contractNum: '',
    contractDate: '',
    organization: '',
    region: '',
    objectName: '',
    address: '',
    note: '',
    koeff: 1,
  });

  const handleClose = () => {
    onClose();
    setReestrAddDto({
      workType: -1,
      subject: '',
      cost: -1,
      period: -1,
      periodBegin: -1,
      dateType: -1,
      contractNum: '',
      contractDate: '',
      organization: '',
      region: '',
      objectName: '',
      address: '',
      note: '',
      koeff: 1,
    });
    setCheckHelper(false);
  };

  const addReestr = async () => {
    const apiResult: IApiResult = await apiAddReestr(reestrAddDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const setOrganization = (inputName: string) => {
    setReestrAddDto({
      ...reestrAddDto,
      organization: inputName.replaceAll('"', '_'),
    });
  };

  const setAddress = (inputName: string) => {
    setReestrAddDto({
      ...reestrAddDto,
      address: inputName.replaceAll('"', '_'),
    });
  };

  const setOther = (inputName: string) => {
    setReestrAddDto({
      ...reestrAddDto,
      note: inputName.replaceAll('"', '_'),
    });
  };

  const setObject = (inputName: string) => {
    setReestrAddDto({
      ...reestrAddDto,
      objectName: inputName.replaceAll('"', '_'),
    });
  };

  const setContractNum = (inputName: string) => {
    setReestrAddDto({
      ...reestrAddDto,
      contractNum: inputName.replaceAll('"', '_'),
    });
  };

  const handleAddReestr = async () => {
    if (
      reestrAddDto.objectName === '' ||
      reestrAddDto.organization === '' ||
      reestrAddDto.region === '' ||
      reestrAddDto.contractNum === '' ||
      reestrAddDto.contractDate === '' ||
      reestrAddDto.address === '' ||
      reestrAddDto.workType === -1 ||
      reestrAddDto.subject === '' ||
      reestrAddDto.cost === -1 ||
      reestrAddDto.period === -1 ||
      reestrAddDto.periodBegin === -1 ||
      reestrAddDto.dateType === -1
    ) {
      setCheckHelper(true);
      return;
    }
    await addReestr();
  };

  const handleChangeExpressWork = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 1;
    if (event.target.checked) {
      check = EXPRESS_WORK_KOEFF;
    }
    setReestrAddDto({
      ...reestrAddDto,
      koeff: check,
    });
  };

  useEffect(() => {
    if (open) {
      getWork();
    }
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Новый договор
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={reestrAddDto.objectName.replaceAll('_', '"')}
                onChange={(e) => setObject(e.target.value)}
                label="Объект"
                error={checkHelper}
                size="small"
              />
              <MyTextFiled
                value={reestrAddDto.organization.replaceAll('_', '"')}
                onChange={(e) => setOrganization(e.target.value)}
                label="Организация"
                error={checkHelper}
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <MyAutoComplete
                value={reestrAddDto.region}
                onChange={(e, v) =>
                  setReestrAddDto({
                    ...reestrAddDto,
                    region: v,
                  })
                }
                arrayList={Object.values(SpisokRegionovEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                error={checkHelper}
                label="Регион"
              />
              <MyTextFiled
                value={reestrAddDto.address.replaceAll('_', '"')}
                onChange={(e) => setAddress(e.target.value)}
                label="Адрес"
                error={checkHelper}
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack width="100%" direction="row" spacing={1}>
                <MyTextFiled
                  value={reestrAddDto.contractNum.replaceAll('_', '"')}
                  onChange={(e) => setContractNum(e.target.value)}
                  label="Номер договора"
                  error={checkHelper}
                  size="small"
                />
                <MyDateTextField
                  value={reestrAddDto.contractDate}
                  onChange={(e) =>
                    setReestrAddDto({
                      ...reestrAddDto,
                      contractDate: e.target.value,
                    })
                  }
                  width="40%"
                  error={checkHelper}
                  label="Дата договора"
                  size="small"
                />
              </Stack>
              <MyAutoComplete
                value={
                  reestrAddDto.workType === -1
                    ? ''
                    : WorkTypeEnum[reestrAddDto.workType]
                }
                onChange={(e, v) =>
                  setReestrAddDto({
                    ...reestrAddDto,
                    workType: Object.values(WorkTypeEnum).indexOf(v),
                  })
                }
                arrayList={Object.values(WorkTypeEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                error={checkHelper}
                label="Тип работы"
              />
            </Stack>
            <MyAutoComplete
              value={reestrAddDto.subject}
              onChange={(e, v) =>
                setReestrAddDto({
                  ...reestrAddDto,
                  subject: v,
                })
              }
              arrayList={getParamArray(reestrAddDto.workType, workDtos)}
              size="small"
              error={checkHelper}
              label="Предмет"
            />
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={reestrAddDto.cost !== -1 ? `${reestrAddDto.cost}` : ''}
                onChange={(e) =>
                  setReestrAddDto({
                    ...reestrAddDto,
                    cost: e.target.value,
                  })
                }
                error={checkHelper}
                label="Стоимость, руб"
                type="number"
                size="small"
              />
              <MyTextFiled
                value={
                  reestrAddDto.period !== -1 ? `${reestrAddDto.period}` : ''
                }
                onChange={(e) =>
                  setReestrAddDto({
                    ...reestrAddDto,
                    period: e.target.value,
                  })
                }
                error={checkHelper}
                label="Срок работы, день"
                type="number"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <MyAutoComplete
                value={
                  reestrAddDto.periodBegin === -1
                    ? ''
                    : WorkStartTypeEnum[reestrAddDto.periodBegin]
                }
                onChange={(e, v) =>
                  setReestrAddDto({
                    ...reestrAddDto,
                    periodBegin: Object.values(WorkStartTypeEnum).indexOf(v),
                  })
                }
                arrayList={Object.values(WorkStartTypeEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                error={checkHelper}
                label="Отсчет начала работы"
              />
              <MyAutoComplete
                value={
                  reestrAddDto.dateType === -1
                    ? ''
                    : PeriodDateTypeEnum[reestrAddDto.dateType]
                }
                onChange={(e, v) =>
                  setReestrAddDto({
                    ...reestrAddDto,
                    dateType: Object.values(PeriodDateTypeEnum).indexOf(v),
                  })
                }
                arrayList={Object.values(PeriodDateTypeEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                error={checkHelper}
                label="В каких днях рассчитывается действие"
              />
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: MAIN_COLOR_STRING }}
                  onChange={handleChangeExpressWork}
                />
              }
              label="Срочная работа"
            />
            <MyTextFiled
              value={reestrAddDto.note.replaceAll('_', '"')}
              onChange={(e) => setOther(e.target.value)}
              label="Примечание"
              size="small"
            />
            <MyButton
              text="Создать"
              background={MAIN_COLOR_STRING}
              onClick={handleAddReestr}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
