import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ACCOUNTANT_ROUTE,
  CONTRACT_ROUTE,
  CUSTOMER_ROUTE,
  FINISH_ROUTE,
  FORM_ROUTE,
  MAIN_ROUTE,
  ORGANIZATION_ROUTE,
  REESTR_ROUTE,
  REGIONGAZ_ROUTE,
  REQUEST_ROUTE,
  SIGN_IN_ROUTE,
  STATS_ROUTE,
  TASK_ROUTE,
  WARNING_ROUTE,
  WORKER_ROUTE,
  WORK_ROUTE,
} from '../../_constant/routes';
import { MyButton } from '../UI/MyButton';
import {
  FULLNAME_NAME,
  GRID_VIEW,
  LIST_VIEW,
  ROOT_ADMIN_VALUE,
  ROOT_BUHGALTER_VALUE,
  ROOT_CUSTOMER_VALUE,
  ROOT_REGIONGAZ_VALUE,
  ROOT_WORKER_VALUE,
  VIEW_NAME,
} from '../../_constant/constants';
import { getRoot } from '../../utils/utils';

export const MainNavigationBar = () => {
  const root = getRoot();
  const viewType = localStorage.getItem(VIEW_NAME);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  const handleClickGridView = () => {
    localStorage.setItem(VIEW_NAME, GRID_VIEW);
    window.location.reload();
  };
  const handleClickListView = () => {
    localStorage.setItem(VIEW_NAME, LIST_VIEW);
    window.location.reload();
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  const logout = () => {
    navigate(SIGN_IN_ROUTE);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={handleClick}
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(MAIN_ROUTE)}
            >
              CRM-Метролог
            </Typography>
            <Stack marginLeft="20px" direction="row" sx={{ flexGrow: 1 }}>
              {location.pathname === MAIN_ROUTE && (
                <Stack alignItems="center" justifyContent="center">
                  <IconButton
                    size={viewType !== LIST_VIEW ? 'large' : 'small'}
                    onClick={handleClickGridView}
                  >
                    <GridOnIcon
                      fontSize={viewType !== LIST_VIEW ? 'large' : 'small'}
                    />
                  </IconButton>
                </Stack>
              )}
              {location.pathname === MAIN_ROUTE && (
                <Stack alignItems="center" justifyContent="center">
                  <IconButton
                    size={viewType === LIST_VIEW ? 'large' : 'small'}
                    onClick={handleClickListView}
                  >
                    <GridOffIcon
                      fontSize={viewType === LIST_VIEW ? 'large' : 'small'}
                    />
                  </IconButton>
                </Stack>
              )}
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  width: '500px',
                  fontWeight: 400,
                  fontSize: '18px',
                  color: '#FFFFFF',
                }}
                component="text"
              >
                {`Пользователь: ${localStorage.getItem(FULLNAME_NAME)}`}
              </Typography>
              <MyButton
                text="Выйти"
                variant="text"
                background="inherit"
                onClick={logout}
              />
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(MAIN_ROUTE)}>Главная</MenuItem>
        <Divider />
        {root !== ROOT_BUHGALTER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <MenuItem onClick={() => navigate(REQUEST_ROUTE)}>
            {root === ROOT_CUSTOMER_VALUE ? 'Заявки' : 'Заявки на рассмотрении'}
          </MenuItem>
        )}
        {root !== ROOT_BUHGALTER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <MenuItem onClick={() => navigate(CONTRACT_ROUTE)}>
            Заявки в работе
          </MenuItem>
        )}
        {root !== ROOT_BUHGALTER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <MenuItem onClick={() => navigate(FINISH_ROUTE)}>
            Завершенные заявки
          </MenuItem>
        )}
        {root !== ROOT_CUSTOMER_VALUE && root !== ROOT_WORKER_VALUE && (
          <MenuItem onClick={() => navigate(REESTR_ROUTE)}>
            Реестр работ
          </MenuItem>
        )}
        {root === '-100' && (
          <MenuItem onClick={() => navigate(ORGANIZATION_ROUTE)}>
            Заказчики
          </MenuItem>
        )}
        <Divider />
        {root !== ROOT_CUSTOMER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <div>
            <MenuItem onClick={() => navigate(CUSTOMER_ROUTE)}>
              Филиалы
            </MenuItem>
            {root === ROOT_ADMIN_VALUE && (
              <MenuItem onClick={() => navigate(WORKER_ROUTE)}>
                Исполнители
              </MenuItem>
            )}
            {root === ROOT_ADMIN_VALUE && (
              <MenuItem onClick={() => navigate(ACCOUNTANT_ROUTE)}>
                Бухгалтерия
              </MenuItem>
            )}
            {root === ROOT_ADMIN_VALUE && (
              <MenuItem onClick={() => navigate(REGIONGAZ_ROUTE)}>
                Регионгаз
              </MenuItem>
            )}
            {root !== ROOT_BUHGALTER_VALUE && <Divider />}
            {root !== ROOT_BUHGALTER_VALUE && (
              <MenuItem onClick={() => navigate(WARNING_ROUTE)}>Важно</MenuItem>
            )}
            {root !== ROOT_BUHGALTER_VALUE && (
              <MenuItem onClick={() => navigate(FORM_ROUTE)}>Формы</MenuItem>
            )}
            {root !== ROOT_BUHGALTER_VALUE && (
              <MenuItem onClick={() => navigate(TASK_ROUTE)}>Задачи</MenuItem>
            )}
            <Divider />
            {root === ROOT_ADMIN_VALUE && (
              <MenuItem onClick={() => navigate(WORK_ROUTE)}>
                Прайс-лист
              </MenuItem>
            )}
            {root === ROOT_ADMIN_VALUE && (
              <MenuItem onClick={() => navigate(STATS_ROUTE)}>
                Статистика
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
    </Box>
  );
};
