import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { FormAddFormDto } from '../../_dto/_form/FormAddFormDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddForm = async (formAddFormDto: FormAddFormDto) => {
  const params: IApiParams = {
    url: '/form/addForm.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'POST', formAddFormDto);
};