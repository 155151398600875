import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetReestrEnd = async (id?: number) => {
  const params: IApiParams = {
    url:
      id === undefined
        ? `/reestr/getReestrEnd.php`
        : `/reestr/getReestrEnd.php?reestrId=${id}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
