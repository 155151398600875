import React, { FunctionComponent } from 'react';
import { Card, Divider, Icon, Stack, Typography } from '@mui/material';
import classes from './MainDiv.module.css';
import {
  LIST_VIEW,
  MAIN_COLOR_STRING,
  VIEW_NAME,
} from '../../_constant/constants';

interface Props {
  color: string;
  text: string;
  image: any;
  cnt?: number;
  cntFilial?: number;
  cntReestr?: number;
  regionName?: any;
  // eslint-disable-next-line no-unused-vars
  onClick: () => void;
}

export const MainDivComponent: FunctionComponent<Props> = ({
  color,
  text,
  image,
  cnt,
  cntFilial,
  cntReestr,
  onClick,
  regionName,
}) => {
  const viewType = localStorage.getItem(VIEW_NAME);
  return (
    <div className={classes.elementComponent}>
      <Card
        onClick={onClick}
        style={{
          height: viewType !== LIST_VIEW ? '180px' : '40px',
          width: '100%',
          borderRadius: viewType !== LIST_VIEW ? '20px' : '5px',
          backgroundColor: '#FFFFFF',
          cursor: 'pointer',
        }}
      >
        <Stack
          height={viewType !== LIST_VIEW ? '140px' : '40px'}
          padding={viewType !== LIST_VIEW ? '20px' : '10px'}
          direction="column"
        >
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              {viewType === LIST_VIEW && (
                <Icon
                  fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  sx={{ color: { color } }}
                >
                  {image}
                </Icon>
              )}
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '20px',
                  letterSpacing: '0.3px',
                  color: '#000000',
                }}
                component="text"
              >
                {text}
              </Typography>
            </Stack>
            {viewType === LIST_VIEW && regionName !== undefined && (
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  letterSpacing: '0.3px',
                  color: MAIN_COLOR_STRING,
                }}
                component="text"
              >
                {`Регион ${regionName}`}
              </Typography>
            )}
            {viewType === LIST_VIEW && cnt !== undefined && (
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  letterSpacing: '0.3px',
                  color: MAIN_COLOR_STRING,
                }}
                component="text"
              >
                {`Количество записей ${cnt}`}
              </Typography>
            )}
            {viewType === LIST_VIEW &&
              cntFilial !== undefined &&
              cntReestr !== undefined && (
                <Typography
                  style={{
                    fontFamily: 'SF Compact Rounded',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    letterSpacing: '0.3px',
                    color: MAIN_COLOR_STRING,
                  }}
                  component="text"
                >
                  {`Количество записей ${cntFilial} / ${cntReestr} (филиал / прямой договор)`}
                </Typography>
              )}
            {viewType !== LIST_VIEW && (
              <Icon
                fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                sx={{ color: { color } }}
              >
                {image}
              </Icon>
            )}
          </Stack>
          {viewType !== LIST_VIEW && <Divider />}
          {viewType !== LIST_VIEW && (
            <Stack padding="10px" height="100%" alignItems="end">
              {regionName !== undefined && (
                <Typography
                  style={{
                    fontFamily: 'SF Compact Rounded',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '18px',
                    letterSpacing: '0.3px',
                    color: MAIN_COLOR_STRING,
                  }}
                  component="text"
                >
                  {`Регион - ${regionName}`}
                </Typography>
              )}
              {cnt !== undefined && (
                <Stack width="100%">
                  <Typography
                    style={{
                      fontFamily: 'SF Compact Rounded',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '14px',
                      width: '100%',
                      letterSpacing: '0.3px',
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Количество записей
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: 'SF Compact Rounded',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '28px',
                      width: '100%',
                      letterSpacing: '0.3px',
                      color: MAIN_COLOR_STRING,
                    }}
                    component="text"
                  >
                    {`${cnt}`}
                  </Typography>
                </Stack>
              )}
              {cntFilial !== undefined && cntReestr !== undefined && (
                <Stack width="100%">
                  <Typography
                    style={{
                      fontFamily: 'SF Compact Rounded',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '14px',
                      width: '100%',
                      letterSpacing: '0.3px',
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Количество записей
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: 'SF Compact Rounded',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '28px',
                        letterSpacing: '0.3px',
                        color: MAIN_COLOR_STRING,
                      }}
                      component="text"
                    >
                      {`${cntFilial}`}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'SF Compact Rounded',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        letterSpacing: '0.3px',
                        color: '#000000',
                      }}
                      component="text"
                    >
                      - филиал
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: 'SF Compact Rounded',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '28px',
                        letterSpacing: '0.3px',
                        color: MAIN_COLOR_STRING,
                      }}
                      component="text"
                    >
                      {`${cntReestr}`}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'SF Compact Rounded',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        letterSpacing: '0.3px',
                        color: '#000000',
                      }}
                      component="text"
                    >
                      - прямой договор
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Card>
    </div>
  );
};

MainDivComponent.defaultProps = {
  cnt: undefined,
  cntFilial: undefined,
  cntReestr: undefined,
  regionName: undefined,
};
