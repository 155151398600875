import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Stack } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CalculateIcon from '@mui/icons-material/Calculate';
import StorageIcon from '@mui/icons-material/Storage';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HailIcon from '@mui/icons-material/Hail';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/Warning';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import classes from './MainDiv.module.css';
import { MainDivComponent } from './MainDivComponent';
import {
  ACCOUNTANT_ROUTE,
  CONTRACT_ROUTE,
  CUSTOMER_ROUTE,
  FINISH_ROUTE,
  FORM_ROUTE,
  ORGANIZATION_ROUTE,
  REESTR_ROUTE,
  REGIONGAZ_ROUTE,
  REQUEST_ROUTE,
  SIGN_IN_ROUTE,
  STATS_ROUTE,
  TASK_ROUTE,
  WARNING_ROUTE,
  WORKER_ROUTE,
  WORK_ROUTE,
} from '../../_constant/routes';
import { TableCountDto } from '../../_dto/_TableCountDto';
import { IApiResult } from '../../_interface/IApiResult';
import { apiGetCount } from '../../services/apiGetCount';
import { IAlertProps } from '../../_interface/IAlertProps';
import {
  getDefaultAlertProps,
  getRoot,
  getWeekendCount,
} from '../../utils/utils';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  LIST_VIEW,
  REGION_NAME,
  ROOT_ADMIN_VALUE,
  ROOT_BUHGALTER_VALUE,
  ROOT_CUSTOMER_VALUE,
  ROOT_REGIONGAZ_VALUE,
  ROOT_WORKER_VALUE,
  VIEW_NAME,
} from '../../_constant/constants';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { MyAlert } from '../UI/MyAlert';

export const MainDiv = () => {
  const root = getRoot();
  const viewType = localStorage.getItem(VIEW_NAME);
  const navigate = useNavigate();
  const [getCountDto, setGetCountDto] = useState<TableCountDto>();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getCount = async () => {
    const apiResult: IApiResult = await apiGetCount();
    if (apiResult.isSuccess) {
      setGetCountDto(apiResult.data);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  return (
    <Stack>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <div
        className={
          viewType !== LIST_VIEW ? classes.container : classes.containerList
        }
      >
        {root !== ROOT_BUHGALTER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <MainDivComponent
            color="#f44336"
            text={
              root === ROOT_CUSTOMER_VALUE ? 'Заявки' : 'Заявки на рассмотрении'
            }
            image={
              <RequestPageIcon
                fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
              />
            }
            cnt={
              root === ROOT_CUSTOMER_VALUE ? getCountDto?.request : undefined
            }
            cntFilial={
              root !== ROOT_CUSTOMER_VALUE ? getCountDto?.request : undefined
            }
            cntReestr={
              root !== ROOT_CUSTOMER_VALUE
                ? getCountDto?.requestReestr
                : undefined
            }
            onClick={() => navigate(REQUEST_ROUTE)}
          />
        )}
        {root !== ROOT_BUHGALTER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <MainDivComponent
            color="#9c27b0"
            text="Заявки в работе"
            image={
              <ArticleIcon
                fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
              />
            }
            onClick={() => navigate(CONTRACT_ROUTE)}
            cnt={
              root === ROOT_CUSTOMER_VALUE ? getCountDto?.contract : undefined
            }
            cntFilial={
              root !== ROOT_CUSTOMER_VALUE ? getCountDto?.contract : undefined
            }
            cntReestr={
              root !== ROOT_CUSTOMER_VALUE
                ? getCountDto?.contractReestr
                : undefined
            }
          />
        )}
        {root !== ROOT_BUHGALTER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
          <MainDivComponent
            color="#6200ea"
            text="Завершенные заявки"
            image={
              <AssignmentTurnedInIcon
                fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
              />
            }
            onClick={() => navigate(FINISH_ROUTE)}
            cnt={root === ROOT_CUSTOMER_VALUE ? getCountDto?.finish : undefined}
            cntFilial={
              root !== ROOT_CUSTOMER_VALUE ? getCountDto?.finish : undefined
            }
            cntReestr={
              root !== ROOT_CUSTOMER_VALUE
                ? getCountDto?.finishReestr
                : undefined
            }
          />
        )}
        {root !== ROOT_CUSTOMER_VALUE && root !== ROOT_WORKER_VALUE && (
          <MainDivComponent
            color="#00897b"
            text="Реестр работ"
            image={
              <StorageIcon
                fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
              />
            }
            cntFilial={
              root !== ROOT_REGIONGAZ_VALUE ? getCountDto?.finish : undefined
            }
            regionName={
              root === ROOT_REGIONGAZ_VALUE
                ? localStorage.getItem(REGION_NAME)
                : undefined
            }
            cntReestr={
              root !== ROOT_REGIONGAZ_VALUE
                ? getCountDto?.finishReestrAll
                : undefined
            }
            onClick={() => navigate(REESTR_ROUTE)}
          />
        )}
        {root === '-100' && (
          <MainDivComponent
            color="#3f51b5"
            image={
              <HailIcon
                fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
              />
            }
            onClick={() => navigate(ORGANIZATION_ROUTE)}
            text="Заказчики"
            cnt={getCountDto?.organization}
          />
        )}
      </div>
      <Divider />
      {root !== ROOT_CUSTOMER_VALUE && root !== ROOT_REGIONGAZ_VALUE && (
        <div>
          <div
            className={
              viewType !== LIST_VIEW ? classes.container : classes.containerList
            }
          >
            <MainDivComponent
              color="#009688"
              image={
                <DirectionsRunIcon
                  fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                />
              }
              onClick={() => navigate(CUSTOMER_ROUTE)}
              text="Филиалы"
              cnt={getCountDto?.customer}
            />
            {root === ROOT_ADMIN_VALUE && (
              <MainDivComponent
                color="#4caf50"
                image={
                  <AirlineSeatReclineExtraIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(WORKER_ROUTE)}
                text="Исполнители"
                cnt={getCountDto?.worker}
              />
            )}
            {root === ROOT_ADMIN_VALUE && (
              <MainDivComponent
                color="#26c6da"
                image={
                  <CalculateIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(ACCOUNTANT_ROUTE)}
                text="Бухгалтерия"
                cnt={getCountDto?.accountant}
              />
            )}
            {root === ROOT_ADMIN_VALUE && (
              <MainDivComponent
                color="#e65100"
                image={
                  <WhatshotIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(REGIONGAZ_ROUTE)}
                text="Регионгаз"
                cnt={getCountDto?.regiongaz}
              />
            )}
          </div>
          {root !== ROOT_BUHGALTER_VALUE && <Divider />}
          <div
            className={
              viewType !== LIST_VIEW ? classes.container : classes.containerList
            }
          >
            {root !== ROOT_BUHGALTER_VALUE && (
              <MainDivComponent
                color="#ff9800"
                image={
                  <WarningIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(WARNING_ROUTE)}
                text="Важно"
                cnt={getCountDto?.warning}
              />
            )}
            {root !== ROOT_BUHGALTER_VALUE && (
              <MainDivComponent
                color="#795548"
                image={
                  <ClearAllIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(FORM_ROUTE)}
                text="Формы"
                cnt={getCountDto?.form}
              />
            )}
            {root !== ROOT_BUHGALTER_VALUE && (
              <MainDivComponent
                color="#2196f3"
                image={
                  <TaskIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(TASK_ROUTE)}
                text="Задачи"
                cnt={getCountDto?.task}
              />
            )}
          </div>
          <Divider />
          {root === ROOT_ADMIN_VALUE && (
            <div
              className={
                viewType !== LIST_VIEW
                  ? classes.container
                  : classes.containerList
              }
            >
              <MainDivComponent
                color="#ffc107"
                image={
                  <PriceChangeIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(WORK_ROUTE)}
                text="Прайс-лист"
              />
              <MainDivComponent
                color="#e91e63"
                image={
                  <DonutSmallIcon
                    fontSize={viewType !== LIST_VIEW ? 'large' : 'medium'}
                  />
                }
                onClick={() => navigate(STATS_ROUTE)}
                text="Статистика"
              />
            </div>
          )}
        </div>
      )}
    </Stack>
  );
};
