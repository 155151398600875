import axios, { Method } from 'axios';
import { getDefaultApiResult } from '../utils/utils';
import {
  API_REQUEST_TIMEOUT_MILLISECONDS,
  API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS,
} from '../_constant/constants';
import { IApiParams } from '../_interface/IApiParams';
import { IApiResult } from '../_interface/IApiResult';
import { API_URL } from '../_setting/AppSettings';
import { apiRefreshToken } from './apiRefreshToken';

let isAlreadyFetchingAccessToken = false;

export const getApiFile = async (
  params: IApiParams,
  method: Method,
  data?: any,
  onDownloadProgress?: (e: any) => void,
  cancelToken?: any
) => {
  const apiResult: IApiResult = getDefaultApiResult();
  const instance = axios.create({
    baseURL: API_URL,
    headers: params.headers,
    timeout: API_REQUEST_TIMEOUT_MILLISECONDS,
    responseType: 'blob',
  });
  instance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      const { config } = error.response;
      if (error.response.status === 401 && !isAlreadyFetchingAccessToken) {
        for (let i = 0; i < 3; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const refreshTokenResult: IApiResult = await apiRefreshToken();
          isAlreadyFetchingAccessToken = true;
          if (refreshTokenResult.isSuccess) {
            config.headers.Authorization = `Bearer ${refreshTokenResult.data.access_token}`;
            break;
          }
        }
        return instance.request(config);
      }
      return Promise.reject(error);
    }
  );
  try {
    const response = await instance.request({
      method,
      url: params.url,
      data,
      onDownloadProgress,
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
    apiResult.isSuccess = true;
    apiResult.status = response.status;
    apiResult.data = response.data;
  } catch (error: any) {
    if (error.response === undefined) {
      apiResult.status = API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS;
    } else {
      apiResult.status = await error.response.status;
    }
  }
  return apiResult;
};
