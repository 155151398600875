import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { WorkerGetDto } from '../../../_dto/_worker/WorkerGetDto';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { MyAlert } from '../../UI/MyAlert';
import { MyButton } from '../../UI/MyButton';
import { ContractGetContractDto } from '../../../_dto/_contract/ContractGetContractDto';
import { ContractChangeWorkerDto } from '../../../_dto/_contract/ContractChangeWorkerDto';
import { apiChangeWorker } from '../../../services/contract/apiChangeWorker';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { apiGetWorker } from '../../../services/apiGetWorker';

interface Props {
  open: boolean;
  onClose: () => void;
  dto: ContractGetContractDto;
}

export const DialogChangeWorker: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [changeWorkerDto, setChangeWorkerDto] =
    useState<ContractChangeWorkerDto>({
      contractId: dto.id,
      workerId: dto.workerId,
    });

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const changeWorker = async () => {
    const apiResult: IApiResult = await apiChangeWorker(changeWorkerDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (changeWorkerDto.workerId === undefined) {
      setCheckHelper(true);
      return;
    }
    await changeWorker();
  };

  const [getWorkerDtos, setGetWorkerDtos] = useState<WorkerGetDto[]>([]);
  const getWorker = async () => {
    const apiResult: IApiResult = await apiGetWorker();
    if (apiResult.isSuccess) {
      setGetWorkerDtos(apiResult.data.worker);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].name);
    }
    return arrres;
  };

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  useEffect(() => {
    if (open) {
      getWorker();
      setChangeWorkerDto({
        contractId: dto.id,
        workerId: dto.workerId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%' },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Выбор исполнителя
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <MyAutoComplete
              value={
                changeWorkerDto.workerId !== -1
                  ? getName(getWorkerDtos)[
                      Object.values(getNameId(getWorkerDtos)).indexOf(
                        changeWorkerDto.workerId
                      )
                    ]
                  : ''
              }
              onChange={(e, v) =>
                setChangeWorkerDto({
                  ...changeWorkerDto,
                  workerId:
                    getNameId(getWorkerDtos)[
                      Object.values(getName(getWorkerDtos)).indexOf(v)
                    ],
                })
              }
              maxHeight="60px"
              arrayList={getName(getWorkerDtos)}
              size="small"
              error={checkHelper}
              label="Исполнитель"
            />
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleSave}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
