import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ReestrAddPdfDto } from '../../_dto/_reestr/ReestrAddPdfDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddPdf = async (dto: ReestrAddPdfDto) => {
  const params: IApiParams = {
    url: '/reestr/addReestrPdf.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
