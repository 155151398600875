import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ContractChangeBloc } from '../../_dto/_contract/ContractChangeBloc';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiChangeBloc = async (dto: ContractChangeBloc) => {
  const params: IApiParams = {
    url: '/contract/changeBloc.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', dto);
};
