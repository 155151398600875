import React from 'react';
import { FormDiv } from '../component/form/FormDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const FormPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<FormDiv />}
  />
);
