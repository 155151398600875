export enum SpisokRegionovEnum {
  'Адыгея',
  'Алтай',
  'Алтайский край',
  'Амурская область',
  'Архангельская область',
  'Астраханская область',
  'Башкортостан',
  'Белгородская область',
  'Брянская область',
  'Бурятия',
  'Владимирская область',
  'Волгоградская область',
  'Вологодская область',
  'Воронежская область',
  'Дагестан',
  'Еврейская автономная область',
  'Забайкальский край',
  'Ивановская область',
  'Ингушетия',
  'Иркутская область',
  'Кабардино-Балкария',
  'Калининградская область',
  'Калмыкия',
  'Калужская область',
  'Камчатский край',
  'Карачаево-Черкесия',
  'Карелия',
  'Кемеровская область',
  'Кировская область',
  'Коми',
  'Костромская область',
  'Краснодарский край',
  'Красноярский край',
  'Крым',
  'Курганская область',
  'Курская область',
  'Ленинградская область',
  'Липецкая область',
  'Магаданская область',
  'Марий Эл',
  'Мордовия',
  'Москва',
  'Московская область',
  'Мурманская область',
  'Ненецкий автономный округ',
  'Нижегородская область',
  'Новгородская область',
  'Новосибирская область',
  'Омская область',
  'Оренбургская область',
  'Орловская область',
  'Пензенская область',
  'Пермский край',
  'Приморский край',
  'Псковская область',
  'Ростовская область',
  'Рязанская область',
  'Самарская область',
  'Санкт-Петербург',
  'Саратовская область',
  'Саха (Якутия)',
  'Сахалинская область',
  'Свердловская область',
  'Севастополь',
  'Северная Осетия - Алания',
  'Смоленская область',
  'Ставропольский край',
  'Тамбовская область',
  'Татарстан',
  'Тверская область',
  'Томская область',
  'Тульская область',
  'Тыва',
  'Тюменская область',
  'Удмуртия',
  'Ульяновская область',
  'Хабаровский край',
  'Хакасия',
  'Ханты-Мансийский автономный округ – Югра',
  'Челябинская область',
  'Чечня',
  'Чувашия',
  'Чукотский автономный округ',
  'Ямало-Ненецкий автономный округ',
  'Ярославская область',
}
