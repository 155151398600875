import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { AccountantGetDto } from '../../../_dto/_accountant/AccountantGetDto';
import { apiUpdateAccountant } from '../../../services/accountant/apiUpdateAccountant';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { AccountantUpdateDto } from '../../../_dto/_accountant/AccountantWorkerDto';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: AccountantGetDto;
}

export const DialogAccountantMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };

  const [accountantSignUp, setAccountantSignUp] = useState<AccountantUpdateDto>(
    {
      login: dto?.login ?? '',
      name: dto?.name ?? '',
      position: dto?.position ?? '',
      phone: dto?.phone ?? '',
      email: dto?.email ?? '',
      birthDate: dto?.birthDate ?? '',
      employmentDate: dto?.employmentDate ?? '',
    }
  );

  const handleClose = () => {
    onClose();
    setIsEdit(false);
    setCheckHelper(false);
  };

  const updateAccountant = async () => {
    const apiResult: IApiResult = await apiUpdateAccountant(accountantSignUp);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (accountantSignUp.name === '') {
      setCheckHelper(true);
      return;
    }
    await updateAccountant();
  };

  useEffect(() => {
    if (open) {
      setAccountantSignUp({
        login: dto?.login ?? '',
        name: dto?.name ?? '',
        position: dto?.position ?? '',
        phone: dto?.phone ?? '',
        email: dto?.email ?? '',
        birthDate: dto?.birthDate ?? '',
        employmentDate: dto?.employmentDate ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {!isEdit && (
                <IconButton size="medium" onClick={handleEdit}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={accountantSignUp.name}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    name: e.target.value,
                  })
                }
                disabled={!isEdit}
                error={checkHelper}
                label="Полное имя"
                size="small"
              />
              <MyTextFiled
                value={accountantSignUp.position}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    position: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Должность"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={accountantSignUp.phone}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    phone: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Телефон"
                size="small"
              />
              <MyTextFiled
                value={accountantSignUp.email}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    email: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="E-mail"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={accountantSignUp.login}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    login: e.target.value,
                  })
                }
                disabled
                label="Логин"
                size="small"
              />
              <MyDateTextField
                value={accountantSignUp.birthDate}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    birthDate: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Дата рождения"
                size="small"
              />

              <MyDateTextField
                value={accountantSignUp.employmentDate}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    employmentDate: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Дата устройства на работу"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={2}>
              <MyTextFiled
                value={`${dto?.createdDate}`}
                disabled
                label="Дата создания"
                size="small"
              />
              <MyTextFiled
                value={`${dto?.modificationDate}`}
                disabled
                label="Дата модификации"
                size="small"
              />
            </Stack>
            {isEdit && (
              <Stack direction="row" spacing={1}>
                <MyButton
                  text="Отмена"
                  background={MAIN_COLOR_STRING}
                  onClick={() => setIsEdit(false)}
                />
                <MyButton
                  text="Сохранить"
                  background={MAIN_COLOR_STRING}
                  onClick={handleSave}
                />
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogAccountantMoreInfo.defaultProps = {
  dto: undefined,
};
