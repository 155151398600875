import React, { FunctionComponent, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { WorkAddDto } from '../../../_dto/_work/WorkAddDto';
import { apiAddWork } from '../../../services/work/apiAddWork';
import { WorkTypeEnum } from '../../../_enum/WorkTypeEnum';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogWorkCreate: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [workAddDto, setWorkAddDto] = useState<WorkAddDto>({
    workType: -1,
    subject: '',
    period: -1,
    expressPeriod: -1,
  });

  const handleClose = () => {
    onClose();
    setWorkAddDto({
      workType: -1,
      subject: '',
      period: -1,
      expressPeriod: -1,
    });
    setCheckHelper(false);
  };

  const workAdd = async () => {
    const apiResult: IApiResult = await apiAddWork(workAddDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleAdd = async () => {
    if (workAddDto.workType === -1 || workAddDto.subject === '') {
      setCheckHelper(true);
      return;
    }
    await workAdd();
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Новая работа
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <MyAutoComplete
              value={
                workAddDto.workType === -1
                  ? ''
                  : WorkTypeEnum[workAddDto.workType]
              }
              onChange={(e, v) =>
                setWorkAddDto({
                  ...workAddDto,
                  workType: Object.values(WorkTypeEnum).indexOf(v),
                })
              }
              arrayList={Object.values(WorkTypeEnum).filter(
                (value) => typeof value === 'string'
              )}
              size="small"
              error={checkHelper}
              label="Тип работы"
            />
            <MyTextFiled
              value={workAddDto.subject}
              onChange={(e) =>
                setWorkAddDto({
                  ...workAddDto,
                  subject: e.target.value,
                })
              }
              error={checkHelper}
              label="Предмет"
              size="small"
            />
            <Stack width="100%" direction="row" spacing={2}>
              <MyTextFiled
                value={`${workAddDto.period}`}
                onChange={(e) =>
                  setWorkAddDto({
                    ...workAddDto,
                    period: e.target.value,
                  })
                }
                type="number"
                label="Срок, день"
                size="small"
              />
              <MyTextFiled
                value={`${workAddDto.expressPeriod}`}
                onChange={(e) =>
                  setWorkAddDto({
                    ...workAddDto,
                    expressPeriod: e.target.value,
                  })
                }
                type="number"
                label="Срок (срочная), день"
                size="small"
              />
            </Stack>
            <MyButton
              text="Добавить"
              background={MAIN_COLOR_STRING}
              onClick={handleAdd}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
