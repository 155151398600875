import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getDefaultAlertProps, getRoot } from '../../utils/utils';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_REGIONGAZ_VALUE,
} from '../../_constant/constants';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { IApiResult } from '../../_interface/IApiResult';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { ReestrDto } from '../../_dto/_reestr/ReestrDto';
import { apiGetReestr } from '../../services/reestr/apiGetReestr';
import { ReestrDeleteDto } from '../../_dto/_reestr/ReestrDeleteDto';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { apiDeleteReestr } from '../../services/reestr/apiDeleteReestr';
import { WorkTypeEnum } from '../../_enum/WorkTypeEnum';
import { DialogReestrMoreInfo } from '../dialog/reestr/DialogReestrMoreInfo';
import { DialogReestrCreate } from '../dialog/reestr/DialogReestrCreate';
import { DialogAddPdf } from '../dialog/reestr/DialogAddPdf';
import { ReestrPaymentDto } from '../../_dto/_reestr/ReestrPaymentDto';
import { apiPaymentReestr } from '../../services/reestr/apiPaymentReestr';
import { ContractGetContractDto } from '../../_dto/_contract/ContractGetContractDto';
import { apiGetContract } from '../../services/contract/apiGetContract.';
import { DialogMoreInfo } from '../dialog/request/DialogMoreInfo';
import { ContractPaymentDto } from '../../_dto/_contract/ContractPaymentDto';
import { apiPaymentContract } from '../../services/contract/apiPaymentContract';
import { DialogChangeRegNum } from '../dialog/regiongaz/DialogChangeRegNum';
import { ContractStageEnum } from '../../_enum/ContractStageEnum';
import { DialogConfirmationCustom } from '../dialog/DialogConfirmationCustom';
import { apiPaymentReestrDoc } from '../../services/reestr/apiPaymentReestrDoc';
import { apiPaymentContractDoc } from '../../services/contract/apiPaymentDocContract';
import { SIGN_IN_ROUTE } from '../../_constant/routes';

export const ReestrDiv = () => {
  const root = getRoot();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogMain, setOpenDialogMain] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogAddPdf, setOpenDialogAddPdf] = useState(false);
  const [openDialogChangeNum, setOpenDialogChangeNum] = useState(false);
  const [isFilial, setIsFilial] = useState(false);
  const [reestrDto, setReestrDto] = useState<ReestrDto>({
    id: -1,
    regNum: '',
    workType: -1,
    workerId: -1,
    subject: '',
    cost: -1,
    customer: '',
    customerContract: '',
    customerId: -1,
    customerContractId: -1,
    isCrmMetrolog: -1,
    contractNum: '',
    createdDate: '',
    modificationDate: '',
    organization: '',
    region: '',
    isPaymentCreate: -1,
    regionNum: '',
    reportType: -1,
    reportNum: '',
    reportDate: '',
    objectName: '',
    address: '',
    regDate: '',
    workStage: -1,
    sendNum: '',
    sendDate: '',
    note: '',
    period: -1,
    periodBegin: -1,
    contractDate: '',
    dateType: -1,
    workStart: '',
    workEnd: '',
    koeff: 1,
    isOpenRegion: 0,
    isPaymentDocCreate: -1,
  });
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [reestrDtos, setReestrDtos] = useState<ReestrDto[]>([]);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogConfirmationPayment, setOpenDialogConfirmationPayment] =
    useState(false);
  const [
    openDialogConfirmationPaymentMain,
    setOpenDialogConfirmationPaymentMain,
  ] = useState(false);
  const [
    openDialogConfirmationPaymentDoc,
    setOpenDialogConfirmationPaymentDoc,
  ] = useState(false);
  const [
    openDialogConfirmationPaymentMainDoc,
    setOpenDialogConfirmationPaymentMainDoc,
  ] = useState(false);
  const [contractDto, setContractDto] = useState<ContractGetContractDto>({
    id: -1,
    customerId: -1,
    workerId: -1,
    contractNum: '',
    subject: '',
    koeff: 1,
    cost: '',
    objectName: '',
    workType: -1,
    createdDate: '',
    modificationDate: '',
    contractStage: -1,
    workStart: '',
    workEnd: '',
    note: '',
    isMain: -1,
    organization: '',
    hasDocument0: -1,
    hasDocument1: -1,
    hasContract: -1,
    subjectCnt: -1,
    workCnt: -1,
    customerName: '',
    workPeriod: '',
    addToContract: -1,
    customerContractId: -1,
    customerContractName: '',
    messageToCustomer: -1,
    messageToWorker: -1,
    hopeDate: '',
    isPaymentCreate: -1,
    regNum: '',
    regionNum: '',
    address: '',
    region: '',
    regDate: '',
    isOpenRegion: 0,
    isPaymentDocCreate: -1,
  });
  const [contractDtos, setContractDtos] = useState<ContractGetContractDto[]>(
    []
  );
  const getContract = async () => {
    const apiResult: IApiResult = await apiGetContract('1');
    if (apiResult.isSuccess) {
      setContractDtos(apiResult.data.contract);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };
  const getReestr = async () => {
    const apiResult: IApiResult = await apiGetReestr();
    if (apiResult.isSuccess) {
      setReestrDtos(apiResult.data.reestr);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [valueTabNew, setValueTabNew] = useState(0);

  useEffect(() => {
    if (
      !openDialog &&
      !openDialogCreate &&
      !openDialogChangeNum &&
      !openDialogMain
    ) {
      if (valueTabNew === 0) {
        getContract();
      }
      if (valueTabNew === 1) {
        getReestr();
      }
    }
  }, [
    openDialog,
    openDialogCreate,
    openDialogChangeNum,
    openDialogMain,
    valueTabNew,
  ]);

  const deleteReestr = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const deleteDto: ReestrDeleteDto = {
      reestrId: reestrDto.id,
    };
    const apiResult: IApiResult = await apiDeleteReestr(deleteDto);
    if (apiResult.isSuccess) {
      await getReestr();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: ReestrDto) => {
    setReestrDto(dto);
    setOpenDialogConfirmation(true);
  };

  const createPayment = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationPayment(false);
      return;
    }
    const paymentDto: ReestrPaymentDto = {
      reestrId: reestrDto.id,
      stage: reestrDto.isPaymentCreate === 0 ? 1 : 0,
    };
    const apiResult: IApiResult = await apiPaymentReestr(paymentDto);
    if (apiResult.isSuccess) {
      await getReestr();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationPayment(false);
  };

  const confirmСreatePayment = (dto: ReestrDto) => {
    setReestrDto(dto);
    setOpenDialogConfirmationPayment(true);
  };

  const createPaymentDoc = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationPaymentDoc(false);
      return;
    }
    const paymentDto: ReestrPaymentDto = {
      reestrId: reestrDto.id,
      stage: reestrDto.isPaymentDocCreate === 0 ? 1 : 0,
    };
    const apiResult: IApiResult = await apiPaymentReestrDoc(paymentDto);
    if (apiResult.isSuccess) {
      await getReestr();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationPaymentDoc(false);
  };

  const confirmСreatePaymentDoc = (dto: ReestrDto) => {
    setReestrDto(dto);
    setOpenDialogConfirmationPaymentDoc(true);
  };

  const createPaymentMain = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationPaymentMain(false);
      return;
    }
    const paymentDto: ContractPaymentDto = {
      contractId: contractDto.id,
      stage: contractDto.isPaymentCreate === 0 ? 1 : 0,
    };
    const apiResult: IApiResult = await apiPaymentContract(paymentDto);
    if (apiResult.isSuccess) {
      await getContract();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationPaymentMain(false);
  };

  const confirmСreatePaymentMain = (dto: ContractGetContractDto) => {
    setContractDto(dto);
    setOpenDialogConfirmationPaymentMain(true);
  };

  const createPaymentMainDoc = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationPaymentMainDoc(false);
      return;
    }
    const paymentDto: ContractPaymentDto = {
      contractId: contractDto.id,
      stage: contractDto.isPaymentDocCreate === 0 ? 1 : 0,
    };
    const apiResult: IApiResult = await apiPaymentContractDoc(paymentDto);
    if (apiResult.isSuccess) {
      await getContract();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationPaymentMainDoc(false);
  };

  const confirmСreatePaymentMainDoc = (dto: ContractGetContractDto) => {
    setContractDto(dto);
    setOpenDialogConfirmationPaymentMainDoc(true);
  };

  const getMoreInformationMain = (dto: ContractGetContractDto) => {
    setContractDto(dto);
    setOpenDialogMain(true);
  };

  const getMoreInformation = (dto: ReestrDto) => {
    setReestrDto(dto);
    setOpenDialog(true);
  };

  const changeRegNumFilial = (dto: ContractGetContractDto) => {
    setContractDto(dto);
    setIsFilial(true);
    setOpenDialogChangeNum(true);
  };

  const changeRegNumReestr = (dto: ReestrDto) => {
    setReestrDto(dto);
    setIsFilial(false);
    setOpenDialogChangeNum(true);
  };

  const copyFilial = (dto: ContractGetContractDto) => {
    const textCopy = ` Объект - ${dto.objectName.replaceAll(
      '_',
      '"'
    )} \n Организация - ${dto.organization.replaceAll(
      '_',
      '"'
    )} \n Адрес - ${dto.address.replaceAll('_', '"')}`;
    navigator.clipboard.writeText(textCopy);
  };

  const copyReestr = (dto: ReestrDto) => {
    const textCopy = ` Объект - ${dto.objectName.replaceAll(
      '_',
      '"'
    )} \n Организация - ${dto.organization.replaceAll(
      '_',
      '"'
    )} \n Адрес - ${dto.address.replaceAll('_', '"')}`;
    navigator.clipboard.writeText(textCopy);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columnsMain: GridColDef[] = [
    {
      field: 'isPaymentCreate',
      width: 70,
      headerName: '',
      editable: false,
      hide: root === ROOT_REGIONGAZ_VALUE,
      disableColumnMenu: true,
      renderCell: (row) => (
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CurrencyRubleIcon
            fontSize="medium"
            sx={{ color: row.row.isPaymentCreate === 1 ? 'purple' : 'grey' }}
            onClick={() => confirmСreatePaymentMain(row.row)}
          />
          <CreditScoreIcon
            fontSize="medium"
            sx={{ color: row.row.isPaymentDocCreate === 1 ? 'orange' : 'grey' }}
            onClick={() => confirmСreatePaymentMainDoc(row.row)}
          />
        </Stack>
      ),
    },
    {
      field: 'regNum',
      renderHeader: () => <strong>Регистрационный номер</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'regionNum',
      renderHeader: () => <strong>Номер Газпром МРГ</strong>,
      width: 250,
      hide: root !== ROOT_REGIONGAZ_VALUE,
      editable: false,
    },
    {
      field: 'workType',
      renderHeader: () => <strong>Тип работы</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => WorkTypeEnum[row.row.workType],
    },
    {
      field: 'subject',
      minWidth: 350,
      flex: 1,
      renderHeader: () => <strong>Предмет</strong>,
      editable: false,
    },
    {
      field: 'objectName',
      renderHeader: () => <strong>Объект</strong>,
      flex: 1,
      minWidth: 300,
      editable: false,
      renderCell: (row) => row.row.objectName.replaceAll('_', '"'),
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 70,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          {root === ROOT_REGIONGAZ_VALUE && (
            <Stack direction="row">
              {(row.row.isDownloadAct === 1 ||
                row.row.isDownloadExpertise === 1 ||
                row.row.isDownloadMethodology === 1) && (
                <IconButton
                  title="Просмотр информации"
                  size="small"
                  onClick={() => getMoreInformationMain(row.row)}
                >
                  <FindInPageIcon
                    fontSize="small"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
              {row.row.isOwnRegNum === 1 && (
                <IconButton
                  title="Внутренний номер"
                  size="small"
                  onClick={() => changeRegNumFilial(row.row)}
                >
                  <EditIcon fontSize="small" sx={{ color: 'orange' }} />
                </IconButton>
              )}
            </Stack>
          )}
          {root !== ROOT_REGIONGAZ_VALUE && (
            <Stack direction="row">
              <IconButton
                title="Просмотр информации"
                size="small"
                onClick={() => getMoreInformationMain(row.row)}
              >
                <FindInPageIcon
                  fontSize="small"
                  sx={{ color: MAIN_COLOR_STRING }}
                />
              </IconButton>
              <IconButton
                title="Скопировать"
                size="small"
                onClick={() => copyFilial(row.row)}
              >
                <ContentCopyIcon fontSize="small" sx={{ color: 'purple' }} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'isPaymentCreate',
      width: 70,
      headerName: '',
      editable: false,
      hide: root === ROOT_REGIONGAZ_VALUE,
      disableColumnMenu: true,
      renderCell: (row) => (
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CurrencyRubleIcon
            fontSize="medium"
            sx={{ color: row.row.isPaymentCreate === 1 ? 'purple' : 'grey' }}
            onClick={() => confirmСreatePayment(row.row)}
          />
          <CreditScoreIcon
            fontSize="medium"
            sx={{ color: row.row.isPaymentDocCreate === 1 ? 'orange' : 'grey' }}
            onClick={() => confirmСreatePaymentDoc(row.row)}
          />
        </Stack>
      ),
    },
    {
      field: 'regNum',
      renderHeader: () => <strong>Регистрационный номер</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'regionNum',
      renderHeader: () => <strong>Номер Газпром МРГ</strong>,
      width: 250,
      hide: root !== ROOT_REGIONGAZ_VALUE,
      editable: false,
    },
    {
      field: 'workType',
      renderHeader: () => <strong>Тип работы</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => WorkTypeEnum[row.row.workType],
    },
    {
      field: 'subject',
      minWidth: 350,
      flex: 1,
      renderHeader: () => <strong>Предмет</strong>,
      editable: false,
    },
    {
      field: 'objectName',
      flex: 1,
      minWidth: 300,
      renderHeader: () => <strong>Объект</strong>,
      editable: false,
      renderCell: (row) => row.row.objectName.replaceAll('_', '"'),
    },
    {
      field: 'workStage',
      minWidth: 200,
      hide: root === ROOT_REGIONGAZ_VALUE,
      renderHeader: () => <strong>Статус</strong>,
      editable: false,
      renderCell: (row) => ContractStageEnum[row.row.workStage],
    },
    {
      field: 'id',
      type: 'actions',
      width: root === ROOT_REGIONGAZ_VALUE ? 70 : 105,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          {root === ROOT_REGIONGAZ_VALUE && (
            <Stack direction="row">
              {(row.row.isDownloadAct === 1 ||
                row.row.isDownloadExpertise === 1 ||
                row.row.isDownloadMethodology === 1) && (
                <IconButton
                  title="Просмотр информации"
                  size="small"
                  onClick={() => getMoreInformation(row.row)}
                >
                  <FindInPageIcon
                    fontSize="small"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
              {row.row.isOwnRegNum === 1 && (
                <IconButton
                  title="Внутренний номер"
                  size="small"
                  onClick={() => changeRegNumReestr(row.row)}
                >
                  <EditIcon fontSize="small" sx={{ color: 'orange' }} />
                </IconButton>
              )}
            </Stack>
          )}
          {root !== ROOT_REGIONGAZ_VALUE && (
            <Stack direction="row">
              <IconButton
                title="Просмотр информации"
                size="small"
                onClick={() => getMoreInformation(row.row)}
              >
                <FindInPageIcon
                  fontSize="small"
                  sx={{ color: MAIN_COLOR_STRING }}
                />
              </IconButton>
              <IconButton
                title="Скопировать"
                size="small"
                onClick={() => copyReestr(row.row)}
              >
                <ContentCopyIcon fontSize="small" sx={{ color: 'purple' }} />
              </IconButton>
              <IconButton
                disabled={row.row.workStage === 3}
                title="Удалить"
                size="small"
                onClick={() => confirmDelete(row.row)}
              >
                <DeleteForeverIcon
                  fontSize="small"
                  sx={{
                    color:
                      row.row.workStage === 2 || row.row.workStage === 3
                        ? 'grey'
                        : 'red',
                  }}
                />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Реестр работ
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={valueTabNew}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Филиалы" />
                <Tab label="Прямые договора" />
              </Tabs>
            </Box>
          </Stack>
          <Divider />
          <Stack>
            {valueTabNew === 0 && (
              <Stack spacing={2}>
                <div>
                  <Box sx={{ width: '100%' }}>
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '10px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columnsMain}
                        rows={contractDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </div>
                <DialogMoreInfo
                  open={openDialogMain}
                  dto={contractDto}
                  onClose={() => setOpenDialogMain(false)}
                />
              </Stack>
            )}
            {valueTabNew === 1 && (
              <Stack spacing={2}>
                <Stack
                  alignItems="center"
                  justifyContent="flex-end"
                  direction="row"
                  spacing={1}
                >
                  {root !== ROOT_REGIONGAZ_VALUE && (
                    <MyButton
                      text="Создать"
                      width="100px"
                      height="30px"
                      background={MAIN_COLOR_STRING}
                      onClick={() => setOpenDialogCreate(true)}
                    />
                  )}
                </Stack>
                <div>
                  <Box sx={{ width: '100%' }}>
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '10px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columns}
                        rows={reestrDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </div>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
      <DialogReestrCreate
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogReestrMoreInfo
        open={openDialog}
        dto={reestrDto}
        onClose={() => setOpenDialog(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteReestr}
      />
      <DialogChangeRegNum
        open={openDialogChangeNum}
        isFilial={isFilial}
        dtoFilial={contractDto}
        dtoReestr={reestrDto}
        onClose={() => setOpenDialogChangeNum(false)}
      />
      <DialogAddPdf
        open={openDialogAddPdf}
        name="Загрузка работы"
        dto={reestrDto}
        onClose={() => setOpenDialogAddPdf(false)}
      />
      <DialogConfirmationCustom
        open={openDialogConfirmationPayment}
        message={
          reestrDto.isPaymentCreate === 0
            ? 'Вы уверены, что деньги поступили?'
            : 'Отменить информацию, что деньги поступили?'
        }
        onClose={createPayment}
      />
      <DialogConfirmationCustom
        open={openDialogConfirmationPaymentMain}
        message={
          contractDto.isPaymentCreate === 0
            ? 'Вы уверены, что деньги поступили?'
            : 'Отменить информацию, что деньги поступили?'
        }
        onClose={createPaymentMain}
      />
      <DialogConfirmationCustom
        open={openDialogConfirmationPaymentDoc}
        message={
          reestrDto.isPaymentDocCreate === 0
            ? 'Счет сформирован?'
            : 'Отменить счет?'
        }
        onClose={createPaymentDoc}
      />
      <DialogConfirmationCustom
        open={openDialogConfirmationPaymentMainDoc}
        message={
          contractDto.isPaymentDocCreate === 0
            ? 'Счет сформирован?'
            : 'Отменить счет?'
        }
        onClose={createPaymentMainDoc}
      />
    </Stack>
  );
};
