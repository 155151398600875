import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { WarningAddWarningDto } from '../../_dto/_warning/WarningAddWarningDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddWarning = async (warningAddWarningDto: WarningAddWarningDto) => {
  const params: IApiParams = {
    url: '/warning/addWarning.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'POST', warningAddWarningDto);
};