import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { RegionGazChangePasswordDto } from '../../_dto/_regiongaz/RegionGazChangePasswordDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiChangePasswordRegionGaz = async (
  dto: RegionGazChangePasswordDto
) => {
  const params: IApiParams = {
    url: '/regiongaz/changePassword.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', dto);
};
