export const SIGN_IN_ROUTE = '/';
export const SIGN_UP_ROUTE = '/signup';
export const MAIN_ROUTE = '/main';
export const REQUEST_ROUTE = '/request';
export const CREATE_REQUEST_ROUTE = '/request/create';
export const CONTRACT_ROUTE = '/contract';
export const FINISH_ROUTE = '/end';
export const CREATE_CONTRACT_ROUTE = '/contract/create';
export const CUSTOMER_ROUTE = '/customer';
export const CREATE_CUSTOMER_ROUTE = '/customer/create';
export const CUSTOMER_EDIT_ROUTE = '/customer/edit';
export const WORKER_ROUTE = '/worker';
export const CREATE_WORKER_ROUTE = '/worker/create';
export const WORKER_EDIT_ROUTE = '/worker/edit';
export const ORGANIZATION_ROUTE = '/organization';
export const CREATE_ORGANIZATION_ROUTE = '/organization/create';
export const ORGANIZATION_EDIT_ROUTE = '/organization/edit';
export const CONTACT_BOOK_ROUTE = '/contact-book';
export const TASK_ROUTE = '/task';
export const CREATE_TASK_ROUTE = '/task/create';
export const WARNING_ROUTE = '/warning';
export const CREATE_WARNING_ROUTE = '/warning/create';
export const FORM_ROUTE = '/form';
export const CREATE_FORM_ROUTE = '/form/create';
export const STATS_ROUTE = '/statistics';
export const WORK_ROUTE = '/work-settings';
export const REGIONGAZ_ROUTE = '/regiongaz';
export const ACCOUNTANT_ROUTE = '/accountant';
export const REESTR_ROUTE = '/reestr';
