import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { RequestDiv } from '../component/request/RequestDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const RequestPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<RequestDiv />}
  />
);
