import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import {
  ACCEPTED_INPUT_FORM_FILE_TYPES,
  API_STATUS_PROBLEM,
  DOCUMENT_CONTRACT,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  MAX_UPLOAD_FILES_COUNT,
  MAX_UPLOAD_FILES_SIZE,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { AddFile } from '../../add-file/AddFile';
import { MyTextFiled } from '../../UI/MyTextField';
import { IFileUpload } from '../../../_interface/IFileUpload';

import { apiUploadFile } from '../../../services/apiUloadFile';
import { ProgressBox } from '../../progress/ProgressBox';
import { MyButton } from '../../UI/MyButton';
import { DocumentAddDocumentDto } from '../../../_dto/_document/DocumentAddDocumentDto';
import { apiAddDocument } from '../../../services/document/apiAddDocument';
import { ContractGetContractDto } from '../../../_dto/_contract/ContractGetContractDto';
import { ContractDocumentType } from '../../../_enum/ContractDocumentType';
import { apiGetRegNum } from '../../../services/apiGetRegNum';
import { DialogConfirmationRegNum } from '../DialogConfirmationRegNum';

interface Props {
  open: boolean;
  name: string;
  type: number;
  dto: ContractGetContractDto;
  onClose: () => void;
}

export const DialogDocumentAdd: FunctionComponent<Props> = ({
  open,
  name,
  type,
  dto,
  onClose,
}) => {
  const root = getRoot();
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [fileUploads, setFileUploads] = useState<IFileUpload[]>([]);
  const [documenAddDto, setDocumentAddDto] = useState<DocumentAddDocumentDto>({
    contractId: dto.customerId,
    descr: '',
    name: '',
    type,
    size: '',
    crc: '',
    regNum: dto.regNum,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [shouldShowLoader, setShowLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [size, setSize] = useState('');
  const [crc] = useState('');
  const progressRef = useRef(0);
  const handleClose = () => {
    onClose();
    setFileUploads([]);
    setUploadProgress(0);
    progressRef.current = 0;
    setDocumentAddDto({
      contractId: -1,
      descr: '',
      name: '',
      type,
      size: '',
      crc: '',
      regNum: '',
    });
    setIsEdit(false);
    setCheckHelper(false);
  };

  const handleProgressUpdate = (event: any) => {
    const progress = (event.loaded / event.total) * 100;
    if (progress > progressRef.current) {
      progressRef.current = progress;
      setUploadProgress(progressRef.current);
    }
  };

  const cancelToken = useRef(axios.CancelToken.source());

  const addDocument = async (dtoDocument: DocumentAddDocumentDto) => {
    const apiResult: IApiResult = await apiAddDocument(dtoDocument);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getFileName = async (upload: IFileUpload[]) => {
    let result = '';
    if (upload.length !== 0) {
      setShowLoader(true);
      const apiFetches: any[] = [];
      upload.forEach((item) => {
        apiFetches.push(
          apiUploadFile(
            DOCUMENT_CONTRACT,
            item,
            handleProgressUpdate,
            cancelToken.current,
            documenAddDto.regNum
          )
        );
      });
      const apiResults: IApiResult[] = await Promise.all(apiFetches);
      for (let i = 0; i < apiResults.length; i += 1) {
        if (apiResults[i].isSuccess) {
          result = `${result}${apiResults[i].data.fileName};`;
        } else {
          result = '';
          setAlertProps({
            message: API_STATUS_PROBLEM,
            severity: AlertSeverityEnum.error,
          });
          return result;
        }
      }
      setShowLoader(false);
    }
    return result;
  };

  const onInputFileChange = (event: any) => {
    let sizeUploadFiles = 0;
    let sizeUploadAllFiles = 0;
    const dataArray = [];
    for (let i = 0; i < fileUploads.length; i += 1) {
      dataArray.push(fileUploads[i]);
    }
    if (
      event.target.files.length <=
      MAX_UPLOAD_FILES_COUNT - fileUploads.length
    ) {
      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i += 1) {
          if (event.target.files[i].size > sizeUploadFiles) {
            sizeUploadFiles = event.target.files[i].size;
          }
          sizeUploadAllFiles += event.target.files[i].size;
          const formData = new FormData();
          formData.append('File', event.target.files[i]);
          const dataArrayItem: IFileUpload = {
            data: formData,
            name: event.target.files[i].name,
          };
          dataArray.push(dataArrayItem);
        }
      }
      if (sizeUploadFiles > MAX_UPLOAD_FILES_SIZE) {
        setAlertProps({
          message: 'Превышен размер файла',
          severity: AlertSeverityEnum.info,
        });
        return;
      }
      setFileUploads(dataArray);
      setSize(`${sizeUploadAllFiles}`);
    } else {
      setAlertProps({
        message: 'Превышено количество файла',
        severity: AlertSeverityEnum.info,
      });
    }
  };

  const handleClear = () => {
    setFileUploads([]);
  };

  const setDescr = (inputName: string) => {
    setDocumentAddDto({
      ...documenAddDto,
      descr: inputName.replaceAll('"', ''),
    });
  };

  const handleAdd = async () => {
    if (type === ContractDocumentType.RESULT && documenAddDto.regNum === '') {
      setCheckHelper(true);
      return;
    }
    if (documenAddDto.descr === '') {
      setCheckHelper(true);
      return;
    }
    if (fileUploads.length === 0) {
      setAlertProps({
        message: 'Вложите файлы',
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    const fileName = await getFileName(fileUploads);
    if (fileName !== '') {
      const addFromDtoNew: DocumentAddDocumentDto = {
        contractId: dto.id,
        descr: documenAddDto.descr,
        name: fileName,
        type,
        size,
        crc,
        regNum: documenAddDto.regNum,
      };
      await addDocument(addFromDtoNew);
    }
  };

  const cancelCreationOfTweet = () => {
    cancelToken.current.cancel();
    setShowLoader(false);
  };

  useEffect(() => {
    if (open) {
      setDocumentAddDto({
        contractId: dto.customerId,
        descr: '',
        name: '',
        type,
        size: '',
        crc: '',
        regNum: dto.regNum,
      });
      cancelToken.current = axios.CancelToken.source();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={shouldShowLoader}
          onClick={cancelCreationOfTweet}
        >
          <Stack>
            {fileUploads.length > 0 ? (
              <Stack alignItems="center" spacing={1}>
                <ProgressBox progress={uploadProgress} />
                <Typography variant="caption" component="div" color="inherit">
                  Если нажать на экран, то произойдет отмена загрузки
                </Typography>
              </Stack>
            ) : (
              <CircularProgress size={50} color="inherit" />
            )}
          </Stack>
        </Backdrop>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                {name}
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <MyTextFiled
                value={documenAddDto.descr}
                onChange={(e) => setDescr(e.target.value)}
                multiLine
                error={checkHelper}
                label="Описание"
                size="small"
              />
              <Stack>
                <AddFile
                  name="File"
                  singleFileOnly={false}
                  icon={<AttachFileIcon sx={{ color: MAIN_COLOR_STRING }} />}
                  accept={ACCEPTED_INPUT_FORM_FILE_TYPES}
                  onChange={onInputFileChange}
                />
              </Stack>
            </Stack>
            {type === ContractDocumentType.RESULT && (
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <MyTextFiled
                    value={documenAddDto.regNum}
                    onChange={(e) =>
                      setDocumentAddDto({
                        ...documenAddDto,
                        regNum: e.target.value,
                      })
                    }
                    disabled={!isEdit}
                    error={checkHelper}
                    multiLine
                    label="Регистрационный номер"
                    size="small"
                  />
                </Stack>
                <MyTextFiled
                  value={dto.regDate}
                  disabled={!isEdit}
                  multiLine
                  label="Дата регистрации"
                  size="small"
                />
              </Stack>
            )}
            <MyButton
              text="Добавить"
              background={MAIN_COLOR_STRING}
              onClick={handleAdd}
            />
          </Stack>
          <br />
          {fileUploads.length !== 0 && (
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  style={{
                    fontFamily: 'SF Compact Rounded',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    color: '#000000',
                  }}
                  component="text"
                >
                  Вложенные файлы
                </Typography>
                <IconButton size="small" onClick={handleClear}>
                  <CleaningServicesIcon
                    fontSize="small"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              </Stack>

              {fileUploads.map((item) => (
                <Typography
                  style={{
                    fontFamily: 'SF Compact Rounded',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: '#000000',
                  }}
                  component="text"
                >
                  {item.name}
                </Typography>
              ))}
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
