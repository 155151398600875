import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { MyAlert } from '../../UI/MyAlert';
import { MyButton } from '../../UI/MyButton';
import { MyTextFiled } from '../../UI/MyTextField';
import { CustomerGetCustomerRespDto } from '../../../_dto/_customer/CustomerGetCustomerRespDto';
import { CustomerTelegramDto } from '../../../_dto/_customer/CustomerTelegramDto';
import { apiTelegramId } from '../../../services/customer/apiTelegramAdd';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: CustomerGetCustomerRespDto;
}

export const DialogAddTelegram: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [addIdTelegramCustomer, setAddIdTelegramBot] =
    useState<CustomerTelegramDto>({
      id: dto?.id ?? -1,
      telegramId: dto?.telegramId ?? '',
    });

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const addTelegramId = async () => {
    const apiResult: IApiResult = await apiTelegramId(addIdTelegramCustomer);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (addIdTelegramCustomer.telegramId === '') {
      setCheckHelper(true);
      return;
    }
    await addTelegramId();
  };

  useEffect(() => {
    if (open) {
      setAddIdTelegramBot({
        id: dto?.id ?? -1,
        telegramId: dto?.telegramId ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Обновление ID телеграма
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <MyTextFiled
              value={addIdTelegramCustomer.telegramId}
              onChange={(e) =>
                setAddIdTelegramBot({
                  ...addIdTelegramCustomer,
                  telegramId: e.target.value,
                })
              }
              error={checkHelper}
              label="ID телеграм"
              size="smalSl"
            />
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleSave}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogAddTelegram.defaultProps = {
  dto: undefined,
};
