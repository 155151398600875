import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ContractUpdateRegionNumDto } from '../../_dto/_contract/ContractUpdateRegionNumDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiChangeRegionNumReestr = async (
  dto: ContractUpdateRegionNumDto
) => {
  const params: IApiParams = {
    url: '/reestr/changeRegionNum.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', dto);
};
