import {
  Backdrop,
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
} from '../../_constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { getDefaultAlertProps, getRoot } from '../../utils/utils';
import { IAlertProps } from '../../_interface/IAlertProps';
import { WarningGetWarningDto } from '../../_dto/_warning/WarningGetWarningDto';
import { DialogWarningAdd } from '../dialog/warning/DialogWarningAdd';
import { IApiResult } from '../../_interface/IApiResult';
import { apiGetWarning } from '../../services/warning/apiGetWarning';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { WarningDeleteWarningDto } from '../../_dto/_warning/WarningDeleteWarningDto';
import { apiDeleteDocument } from '../../services/warning/apiDeleteWarning';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { apiDownloadFile } from '../../services/apiDownloadFile';
import { ProgressBox } from '../progress/ProgressBox';

export const WarningDiv = () => {
  const root = getRoot();
  const [shouldShowLoader, setShowLoader] = useState(false);
  const [uploadText, setUploadText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const progressRef = useRef(0);
  const [warningId, setWarningId] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [getWarningDtos, setGetWarningDtos] = useState<WarningGetWarningDto[]>(
    []
  );

  const getWarning = async () => {
    const apiResult: IApiResult = await apiGetWarning();
    if (apiResult.isSuccess) {
      setGetWarningDtos(apiResult.data.warning);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (!openDialog) {
      getWarning();
    }
  }, [openDialog]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const deleteWarning = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const warningDeleteDto: WarningDeleteWarningDto = {
      warningId,
    };
    const apiResult: IApiResult = await apiDeleteDocument(warningDeleteDto);
    if (apiResult.isSuccess) {
      await getWarning();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (id: number) => {
    setWarningId(id);
    setOpenDialogConfirmation(true);
  };

  const handleProgressUpdate = (event: any) => {
    const progress = (event.loaded / event.total) * 100;
    if (progress > progressRef.current) {
      progressRef.current = progress;
      setUploadProgress(progressRef.current);
    }
  };
  const cancelToken = useRef(axios.CancelToken.source());
  const openFile = async (name: string) => {
    cancelToken.current = axios.CancelToken.source();
    setUploadProgress(0);
    progressRef.current = 0;
    setShowLoader(true);
    const nameArray = name.split(';');
    for (let i = 0; i < nameArray.length; i += 1) {
      if (nameArray[i].length > 4) {
        setUploadText(`${i + 1} из ${nameArray.length - 1}`);
        // eslint-disable-next-line no-await-in-loop
        await apiDownloadFile(
          `/warning/${nameArray[i]}`,
          nameArray[i],
          handleProgressUpdate,
          cancelToken.current
        );
      }
      progressRef.current = 0;
    }
    setShowLoader(false);
  };
  const cancelCreationOfTweet = () => {
    cancelToken.current.cancel();
    setShowLoader(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      renderHeader: () => <strong>Дата создания</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'warning',
      flex: 1,
      minWidth: 400,
      renderHeader: () => <strong>Описание</strong>,
      editable: false,
    },
    {
      field: '1',
      renderHeader: () => <strong>Файлов</strong>,
      width: 100,
      editable: false,
      renderCell: (row) => row.row.documentName.split(';').length - 1,
    },
    {
      field: '2',
      headerName: '',
      width: 100,
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="left">
          {row.row.documentName.includes('pdf') && (
            <PictureAsPdfIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
          {row.row.documentName.includes('doc') && (
            <ArticleIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
        </Stack>
      ),
    },
    {
      field: 'documentName',
      type: 'actions',
      width: root === ROOT_ADMIN_VALUE ? 70 : 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          {row.row.documentName !== '' && (
            <IconButton
              title="Скачать"
              size="small"
              onClick={() => openFile(row.row.documentName)}
            >
              <FileDownloadIcon
                fontSize="small"
                sx={{ color: MAIN_COLOR_STRING }}
              />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row.id)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme2) => theme2.zIndex.drawer + 1 }}
        open={shouldShowLoader}
        onClick={cancelCreationOfTweet}
      >
        <Stack alignItems="center" spacing={1}>
          <Typography variant="caption" component="div" color="inherit">
            {uploadText}
          </Typography>
          <ProgressBox progress={uploadProgress} />
          <Typography variant="caption" component="div" color="inherit">
            Если нажать на экран, то произойдет отмена загрузки
          </Typography>
        </Stack>
      </Backdrop>
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Важно
            </Typography>
            {root === ROOT_ADMIN_VALUE && (
              <MyButton
                text="Добавить"
                width="100px"
                height="30px"
                background={MAIN_COLOR_STRING}
                onClick={() => setOpenDialog(true)}
              />
            )}
          </Stack>
          <Divider />
          <div>
            <Box sx={{ width: '100%' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={getWarningDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </Stack>
      </Card>
      <DialogWarningAdd
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteWarning}
      />
    </Stack>
  );
};
