import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
  ROOT_CUSTOMER_VALUE,
  ROOT_WORKER_VALUE,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { ChatSendMessageDto } from '../../../_dto/_chat/ChatSendMessageDto';
import { ChatGetMessageDto } from '../../../_dto/_chat/ChatGetMessageDto';
import { apiGetMessage } from '../../../services/chat/apiGetMessage';
import { apiSendMessage } from '../../../services/chat/apiSendMessage';
import { apiDeleteMessage } from '../../../services/chat/apiDeleteMessage';
import { MyButton } from '../../UI/MyButton';

interface Props {
  open: boolean;
  idContract: number;
  onClose: () => void;
}

export const DialogChat: FunctionComponent<Props> = ({
  open,
  idContract,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const root = getRoot();
  const [messageDto, setMessageDto] = useState<ChatGetMessageDto[]>([]);
  const [sendMessageDto, setSendMessageDto] = useState<ChatSendMessageDto>({
    contractId: idContract,
    message: '',
  });
  const [checkHelper, setCheckHelper] = useState(false);
  const handleClose = () => {
    onClose();
    setSendMessageDto({ ...sendMessageDto, message: '' });
    setMessageDto([]);
    setCheckHelper(false);
  };

  const getMessage = async () => {
    const apiResult: IApiResult = await apiGetMessage(idContract);
    if (apiResult.isSuccess) {
      setMessageDto(apiResult.data.chat);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const sendMessage = async () => {
    const apiResult: IApiResult = await apiSendMessage(sendMessageDto);
    if (apiResult.isSuccess) {
      getMessage();
      setSendMessageDto({ ...sendMessageDto, message: '' });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteMessage = async (id: number) => {
    const deleteMessageDto = {
      messageId: id,
    };
    const apiResult: IApiResult = await apiDeleteMessage(deleteMessageDto);
    if (apiResult.isSuccess) {
      getMessage();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSendMessage = async () => {
    if (sendMessageDto.message === '') {
      setCheckHelper(true);
      return;
    }
    setCheckHelper(false);
    await sendMessage();
  };

  const handleDeleteMessage = async (id: number) => {
    await deleteMessage(id);
  };

  useEffect(() => {
    if (open) {
      setSendMessageDto({
        contractId: idContract,
        message: '',
      });
      getMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Чат
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <div
                style={{
                  maxHeight: '500px',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                }}
              >
                <List
                  sx={{
                    width: '100%',
                  }}
                >
                  {messageDto.map((item) => (
                    <ListItem>
                      <Stack width="100%">
                        <Stack alignItems="center" direction="row">
                          <Typography
                            style={{
                              fontFamily: 'SF Compact Rounded',
                              fontStyle: 'normal',
                              width: '100%',
                              fontWeight: 300,
                              fontSize: '12px',
                              letterSpacing: 1,
                              color: '#000000',
                              textAlign:
                                item.isCustomer === 0 ? 'right' : 'left',
                            }}
                            component="text"
                          >
                            {item.datetime}
                          </Typography>
                          {root === ROOT_WORKER_VALUE && item.isCustomer === 0 && (
                            <IconButton
                              title="Удалить"
                              size="small"
                              onClick={() => handleDeleteMessage(item.id)}
                            >
                              <DeleteForeverIcon
                                fontSize="small"
                                sx={{
                                  color: 'red',
                                }}
                              />
                            </IconButton>
                          )}
                          {root === ROOT_CUSTOMER_VALUE &&
                            item.isCustomer === 1 && (
                              <IconButton
                                title="Удалить"
                                size="small"
                                onClick={() => handleDeleteMessage(item.id)}
                              >
                                <DeleteForeverIcon
                                  fontSize="small"
                                  sx={{
                                    color: 'red',
                                  }}
                                />
                              </IconButton>
                            )}
                        </Stack>

                        <Typography
                          style={{
                            fontFamily: 'SF Compact Rounded',
                            fontStyle: 'normal',
                            width: '100%',
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#000000',
                            textAlign: item.isCustomer === 0 ? 'right' : 'left',
                          }}
                          component="text"
                        >
                          {item.message}
                        </Typography>
                        <Divider />
                      </Stack>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Stack>
            {root !== ROOT_ADMIN_VALUE && (
              <MyTextFiled
                value={sendMessageDto.message}
                onChange={(e) =>
                  setSendMessageDto({
                    ...sendMessageDto,
                    message: e.target.value,
                  })
                }
                multiLine
                error={checkHelper}
                label="Сообщение"
                size="small"
              />
            )}
            <Stack direction="row" spacing={2}>
              <MyButton
                text="Обновить"
                background={MAIN_COLOR_STRING}
                onClick={getMessage}
              />
              {root !== ROOT_ADMIN_VALUE && (
                <MyButton
                  text="Отправить"
                  background={MAIN_COLOR_STRING}
                  onClick={handleSendMessage}
                />
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
