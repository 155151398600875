import {
  Box,
  Card,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiGetStats } from '../../services/apiGetStats';
import { apiGetWorker } from '../../services/apiGetWorker';
import { apiGetCustomer } from '../../services/customer/apiGetCustomer';
import { getDefaultAlertProps } from '../../utils/utils';
import {
  API_STATUS_PROBLEM,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../_constant/constants';
import { CustomerGetCustomerRespDto } from '../../_dto/_customer/CustomerGetCustomerRespDto';
import { StatsDto } from '../../_dto/_StatsDto';
import { WorkerGetDto } from '../../_dto/_worker/WorkerGetDto';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { IApiResult } from '../../_interface/IApiResult';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { MyAlert } from '../UI/MyAlert';
import { StatsDivComponent } from './StatsDivComponent';

export const StatsDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [valueTabNew, setValueTabNew] = React.useState(0);
  const [workerId, setWorkerId] = useState();
  const [customerId, setCustomerId] = useState();
  const [getWorkerDtos, setGetWorkerDtos] = useState<WorkerGetDto[]>([]);
  const [getCustomerDtos, setGetCustomerDtos] = useState<
    CustomerGetCustomerRespDto[]
  >([]);
  const [statsDto, setStatsDto] = useState<StatsDto[]>([]);
  const [statsDto2, setStatsDto2] = useState<StatsDto[]>([]);

  const getCustomer = async () => {
    const apiResult: IApiResult = await apiGetCustomer();
    if (apiResult.isSuccess) {
      setGetCustomerDtos(apiResult.data.customer);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getCustomerStats = async () => {
    const apiResult: IApiResult = await apiGetStats(customerId);
    if (apiResult.isSuccess) {
      setStatsDto(apiResult.data.stats);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getWorker = async () => {
    const apiResult: IApiResult = await apiGetWorker();
    if (apiResult.isSuccess) {
      setGetWorkerDtos(apiResult.data.worker);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getWorkerStats = async () => {
    const apiResult: IApiResult = await apiGetStats(undefined, workerId);
    if (apiResult.isSuccess) {
      setStatsDto2(apiResult.data.stats);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].name);
    }
    return arrres;
  };

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };

  useEffect(() => {
    if (valueTabNew === 0) {
      getCustomer();
    }
    if (valueTabNew === 1) {
      getWorker();
    }
  }, [valueTabNew]);

  useEffect(() => {
    if (customerId !== undefined) {
      getCustomerStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (workerId !== undefined) {
      getWorkerStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerId]);

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Статистика
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={valueTabNew}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Филиал" />
                <Tab label="Исполнитель" />
              </Tabs>
            </Box>
          </Stack>
          <Divider />
          <Stack>
            {valueTabNew === 0 && (
              <Stack spacing={2}>
                <MyAutoComplete
                  value={
                    customerId !== -1 &&
                    customerId !== null &&
                    customerId !== undefined
                      ? getName(getCustomerDtos)[
                          Object.values(getNameId(getCustomerDtos)).indexOf(
                            customerId
                          )
                        ]
                      : ' '
                  }
                  onChange={(e, v) =>
                    setCustomerId(
                      getNameId(getCustomerDtos)[
                        Object.values(getName(getCustomerDtos)).indexOf(v)
                      ]
                    )
                  }
                  width="40%"
                  arrayList={getName(getCustomerDtos)}
                  size="small"
                  label="Филиал"
                />
                {customerId && <StatsDivComponent title="" dtos={statsDto} />}
              </Stack>
            )}
            {valueTabNew === 1 && (
              <Stack spacing={2}>
                <MyAutoComplete
                  value={
                    workerId !== -1 &&
                    workerId !== null &&
                    workerId !== undefined
                      ? getName(getWorkerDtos)[
                          Object.values(getNameId(getWorkerDtos)).indexOf(
                            workerId
                          )
                        ]
                      : ' '
                  }
                  onChange={(e, v) =>
                    setWorkerId(
                      getNameId(getWorkerDtos)[
                        Object.values(getName(getWorkerDtos)).indexOf(v)
                      ]
                    )
                  }
                  width="40%"
                  arrayList={getName(getWorkerDtos)}
                  size="small"
                  label="Исполнитель"
                />
                {workerId && <StatsDivComponent title="" dtos={statsDto2} />}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
