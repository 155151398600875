import {
  ACCESS_TOKEN_NAME,
  FULLNAME_NAME,
  HASH_NAME,
  ROOT_ADMIN_VALUE,
  ROOT_BUHGALTER_VALUE,
  ROOT_CUSTOMER_VALUE,
  ROOT_REGIONGAZ_VALUE,
  ROOT_WORKER_VALUE,
} from '../_constant/constants';
import { AlertSeverityEnum } from '../_enum/AlertSeverityEnum';
import { IAlertProps } from '../_interface/IAlertProps';
import { IApiResult } from '../_interface/IApiResult';

export const isMobileView = () => {
  const result = window.innerWidth < 820;
  return result;
};

export const getDefaultAlertProps = () => {
  const alertApp: IAlertProps = {
    message: '',
    severity: AlertSeverityEnum.error,
  };
  return alertApp;
};

export const getDefaultApiResult = () => {
  const apiResult: IApiResult = {
    isSuccess: false,
    status: -1,
    data: null,
    alert: getDefaultAlertProps(),
  };
  return apiResult;
};

export const changeFormatDateWithDiff = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('.');
    if (arrayDate.length === 3) {
      result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const changeFormatDateWithPoint = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('-');
    if (arrayDate.length === 3) {
      result = `${arrayDate[0]}.${arrayDate[1]}.${arrayDate[2]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const setToken = (tokenType: string) =>
  `${localStorage.getItem(tokenType)}`;

export const getSimvol = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFileSize = (size: number) => {
  let converSize = 0;
  if (size <= 100000) {
    converSize = Math.floor(size / 100) / 10;
    return `${converSize} KB`;
  }
  converSize = Math.floor(size / 100000) / 10;
  return `${converSize} MB`;
};

export const getWeekendCount = (date: string) => {
  let weekendDayCount = 0;
  const fromDate = new Date(date);
  const toDate = new Date();
  while (fromDate < toDate) {
    if (fromDate.getDay() === 0 || fromDate.getDay() === 6) {
      weekendDayCount += 1;
    }
    fromDate.setDate(fromDate.getDate() + 1);
  }
  return weekendDayCount;
};

export const getContracState = (
  date: string,
  period: string,
  stage: number
) => {
  if (date === '' || period === '') {
    return '';
  }
  if (stage === 3) {
    return 'stage-green';
  }
  const weekendCount = getWeekendCount(date);
  const nowDate = new Date().getTime();
  const workStart = Date.parse(date);
  const workPeriod = parseInt(period, 10);
  const leftDay = (nowDate - workStart) / 1000 / 3600 / 24 - weekendCount;
  const leftPrecent = (leftDay / workPeriod) * 100;
  if (leftPrecent < 60 && leftPrecent > 40) {
    return 'stage-orange';
  }
  if (leftPrecent > 60) {
    return 'stage-red';
  }
  return '';
};

export const getRegNum = (workType: number) => {
  if (workType === 0) {
    return '2006/1-12Ф-21-ГИ';
  }
  if (workType === 1) {
    return '1205/3-21Ф-21-ГР-RA.RU.561423-2021';
  }
  if (workType === 2) {
    return '1203/3-20Ф-21-ГИ-RA.RU.561423-2021';
  }
  return '';
};

export const getRoot = () => {
  let result = '-100';
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const md5 = require('md5');
  const param = localStorage.getItem(HASH_NAME);
  const param2 = localStorage.getItem(FULLNAME_NAME);
  const param3 = localStorage.getItem(ACCESS_TOKEN_NAME);
  const choose0 = md5(`${param2}${ROOT_CUSTOMER_VALUE}crm-metrology${param3}`);
  const choose1 = md5(`${param2}${ROOT_WORKER_VALUE}crm-metrology${param3}`);
  const choose2 = md5(`${param2}${ROOT_BUHGALTER_VALUE}crm-metrology${param3}`);
  const choose3 = md5(`${param2}${ROOT_REGIONGAZ_VALUE}crm-metrology${param3}`);
  const choose4 = md5(`${param2}${ROOT_ADMIN_VALUE}crm-metrology${param3}`);
  if (choose0 === param) {
    result = ROOT_CUSTOMER_VALUE;
  }
  if (choose1 === param) {
    result = ROOT_WORKER_VALUE;
  }
  if (choose2 === param) {
    result = ROOT_BUHGALTER_VALUE;
  }
  if (choose3 === param) {
    result = ROOT_REGIONGAZ_VALUE;
  }
  if (choose4 === param) {
    result = ROOT_ADMIN_VALUE;
  }
  return result;
};
