import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { CustomerDeleteDto } from '../../_dto/_customer/CustomerDeleteDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiDelete = async (customerDeleteDto: CustomerDeleteDto) => {
  const params: IApiParams = {
    url: '/customer/delete.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'DELETE', customerDeleteDto);
};