import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
} from '../../_constant/constants';
import { IApiResult } from '../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { RegionGazGetDto } from '../../_dto/_regiongaz/RegionGazGetDto';
import { apiGetRegionGaz } from '../../services/regiongaz/apiGetRegionGaz';
import { RegionGazDeleteDto } from '../../_dto/_regiongaz/RegionGazDeleteDto';
import { apiDeleteRegionGaz } from '../../services/regiongaz/apiDeleteRegionGaz';
import { DialogCreateRegionGaz } from '../dialog/regiongaz/DialogCreateRegionGaz';
import { DialogRegionGazMoreInfo } from '../dialog/regiongaz/DialogRegionGazMoreInfo';
import { DialogChangePasswordRegionGaz } from '../dialog/regiongaz/DialogChangePasswordRegionGaz';

export const RegionGazDiv = () => {
  const root = getRoot();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [regionGazDto, setRegionGazDto] = useState<RegionGazGetDto>({
    id: -1,
    login: '',
    region: '',
    name: '',
    root: -1,
    isDownloadAct: -1,
    isDownloadExpertise: -1,
    isDownloadMethodology: -1,
    isOwnRegNum: -1,
    isOpenFilial: -1,
    isOpenReestr: -1,
  });
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [regionGazDtos, setRegionGazDtos] = useState<RegionGazGetDto[]>([]);

  const getRegionGaz = async () => {
    const apiResult: IApiResult = await apiGetRegionGaz();
    if (apiResult.isSuccess) {
      setRegionGazDtos(apiResult.data.regiongaz);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (!openDialog && !openDialogCreate) {
      getRegionGaz();
    }
  }, [openDialog, openDialogCreate]);

  const deleteRegionGaz = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const dto: RegionGazDeleteDto = {
      id: regionGazDto.id,
    };
    const apiResult: IApiResult = await apiDeleteRegionGaz(dto);
    if (apiResult.isSuccess) {
      getRegionGaz();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: RegionGazGetDto) => {
    setRegionGazDto(dto);
    setOpenDialogConfirmation(true);
  };

  const getMoreInformation = (dto: RegionGazGetDto) => {
    setRegionGazDto(dto);
    setOpenDialog(true);
  };

  const changePassword = (dto: RegionGazGetDto) => {
    setRegionGazDto(dto);
    setOpenDialogPassword(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      renderHeader: () => <strong>Регионгаз</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => row.row.name.replaceAll('_', '"'),
    },
    {
      field: 'region',
      flex: 1,
      minWidth: 350,
      renderHeader: () => <strong>Регион</strong>,
      editable: false,
    },
    {
      field: 'id',
      type: 'actions',
      width: root === ROOT_ADMIN_VALUE ? 140 : 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <PersonSearchIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Смена пароля"
              size="small"
              onClick={() => changePassword(row.row)}
            >
              <SyncLockIcon fontSize="small" sx={{ color: 'purple' }} />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Регионгаз
            </Typography>
            {root === ROOT_ADMIN_VALUE && (
              <MyButton
                text="Создать"
                width="100px"
                height="30px"
                background={MAIN_COLOR_STRING}
                onClick={() => setOpenDialogCreate(true)}
              />
            )}
          </Stack>
          <Divider />
          <div>
            <Box sx={{ width: '100%' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={regionGazDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </Stack>
      </Card>
      <DialogCreateRegionGaz
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogRegionGazMoreInfo
        open={openDialog}
        dto={regionGazDto}
        onClose={() => setOpenDialog(false)}
      />
      <DialogChangePasswordRegionGaz
        open={openDialogPassword}
        dto={regionGazDto}
        onClose={() => setOpenDialogPassword(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteRegionGaz}
      />
    </Stack>
  );
};
