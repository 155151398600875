import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ContractDeleteContractDto } from '../../_dto/_contract/ContractDeleteContractDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteContract = async (
  contractDeleteContractDto: ContractDeleteContractDto
) => {
  const params: IApiParams = {
    url: '/contract/deleteContract.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'DELETE', contractDeleteContractDto);
};
