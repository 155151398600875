/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { changeFormatDateWithDiff } from '../../utils/utils';

interface Props {
  label: string;
  width?: string;
  disabled?: boolean;
  size?: any;
  value?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyDateTextField: FunctionComponent<Props> = ({
  label,
  width,
  disabled,
  size,
  value,
  error,
  helperText,
  onChange,
}) => (
  <CssTextField
    id="date"
    label={label}
    value={changeFormatDateWithDiff(value)}
    variant="filled"
    type="date"
    onChange={onChange}
    error={error && value === ''}
    disabled={disabled}
    style={{
      width,
      fontFamily: 'SF Compact Display',
    }}
    helperText={error && value === '' ? 'Введите значение' : helperText}
    size={size}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

MyDateTextField.defaultProps = {
  value: '',
  width: '100%',
  size: 'large',
  disabled: false,
  error: false,
  helperText: '',
  onChange: undefined,
};
