import { IconButton, Stack } from '@mui/material';
import React, { FunctionComponent } from 'react';
import classes from './AddFile.module.css';

interface Props {
  name: string;
  accept: string;
  icon: any;
  onChange: (e: any) => void;
  singleFileOnly: boolean;
}

export const AddFile: FunctionComponent<Props> = ({
  name,
  accept,
  icon,
  onChange,
  singleFileOnly,
}) => {
  const onImageChange = (event: any) => {
    onChange(event);
  };
  return (
    <IconButton
      size="small"
      sx={{ color: '#128DFF' }}
      className={classes.personalImage}
    >
      <label htmlFor="inputFile">
        <Stack direction="row">
          <input
            id="inputFile"
            name={name}
            type="file"
            accept={accept}
            onChange={onImageChange}
            multiple={!singleFileOnly}
          />
          {icon}
        </Stack>
      </label>
    </IconButton>
  );
};
