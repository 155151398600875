import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { TaskAddTaskDto } from '../../../_dto/_task/TaskAddTaskDto';
import { apiAddTask } from '../../../services/task/apiAddTask';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { MyButton } from '../../UI/MyButton';

interface Props {
  open: boolean;
  userId?: number;
  onClose: () => void;
}

export const DialogTaskAdd: FunctionComponent<Props> = ({
  open,
  userId,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [taskAddDto, setTaskAddDto] = useState<TaskAddTaskDto>({
    task: '',
    dateTo: '',
    userId,
  });
  const [checkHelper, setCheckHelper] = useState(false);
  const handleClose = () => {
    onClose();
    setTaskAddDto({
      task: '',
      dateTo: '',
      userId,
    });
    setCheckHelper(false);
  };

  const addTask = async () => {
    const apiResult: IApiResult = await apiAddTask(taskAddDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleAdd = async () => {
    if (taskAddDto.task === '') {
      setCheckHelper(true);
      return;
    }
    await addTask();
  };

  useEffect(() => {
    if (open) {
      setTaskAddDto({
        task: '',
        dateTo: '',
        userId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Новая задача
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack direction="column" spacing={2}>
            <MyTextFiled
              value={taskAddDto.task}
              onChange={(e) =>
                setTaskAddDto({
                  ...taskAddDto,
                  task: e.target.value,
                })
              }
              multiLine
              error={checkHelper}
              label="Задача"
              size="small"
            />
            <Stack>
              <MyDateTextField
                value={taskAddDto.dateTo}
                onChange={(e) =>
                  setTaskAddDto({
                    ...taskAddDto,
                    dateTo: e.target.value,
                  })
                }
                label="Сделать до"
                size="small"
              />
            </Stack>
            <MyButton
              text="Добавить"
              background={MAIN_COLOR_STRING}
              onClick={handleAdd}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogTaskAdd.defaultProps = {
  userId: undefined,
};
