import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { RequestUpdateDto } from '../../_dto/_request/RequestUpdateDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiUpdateRequest = async (requestUpdateDto: RequestUpdateDto) => {
  const params: IApiParams = {
    url: '/request/updateRequest.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', requestUpdateDto);
};
