import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../_constant/constants';
import { RegNumDeleteDto } from '../_dto/RegNumDeleteDto';
import { IApiParams } from '../_interface/IApiParams';
import { getApi } from './getApi';

export const apiDeleteRegNum = async (dto: RegNumDeleteDto) => {
  const params: IApiParams = {
    url: `/deleteRegNum.php`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'DELETE', dto);
};
