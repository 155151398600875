import FileDownload from 'js-file-download';
import {
  ACCESS_TOKEN_NAME,
  FORM_DATA_CONTENT_TYPE,
} from '../_constant/constants';
import { IApiParams } from '../_interface/IApiParams';
import { IApiResult } from '../_interface/IApiResult';
import { getApiFile } from './getApiFile';

export const apiDownloadFile = async (
  path: string,
  name: string,
  onDownloadProgress?: (e: any) => void,
  cancelToken?: any
) => {
  const params: IApiParams = {
    url: `/downloadFile.php?name=${path}`,
    headers: {
      'Content-Type': FORM_DATA_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  const apiResult: IApiResult = await getApiFile(
    params,
    'GET',
    undefined,
    onDownloadProgress,
    cancelToken
  );
  if (apiResult.isSuccess) {
    FileDownload(apiResult.data, name);
  }
};
