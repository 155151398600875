import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  open: boolean;
  message: string;
  onClose: (e: any) => void;
}

export const DialogConfirmationCustom: FunctionComponent<Props> = ({
  open,
  message,
  onClose,
}) => {
  const handleClose = (confirmation: boolean) => {
    onClose(confirmation);
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Требуется подтверждение действия
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose(false)}>
            Отмена
          </Button>
          <Button style={{ color: 'red' }} onClick={() => handleClose(true)}>
            Продолжить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
