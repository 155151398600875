import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ChatSendMessageDto } from '../../_dto/_chat/ChatSendMessageDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiSendMessage = async (
  chatSendMessageDto: ChatSendMessageDto
) => {
  const params: IApiParams = {
    url: '/chat/sendMessage.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', chatSendMessageDto);
};
