import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface Props {
  title: string;
  dtos: any;
}

export const StatsDivComponent: FunctionComponent<Props> = ({
  title,
  dtos,
}) => (
  <div>
    <Stack padding="20px">
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Typography
          style={{
            fontFamily: 'SF Compact Rounded',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            color: '#000000',
          }}
          variant="h6"
          component="text"
        >
          {title}
        </Typography>
      </Stack>
      <ResponsiveContainer width="95%" height={600}>
        <BarChart
          data={dtos}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <Tooltip />
          <Legend />
          <Bar name="Объекты в работе" dataKey="activeWork" fill="orange" />
          <Bar name="Закрытые объекты" dataKey="endWork" fill="green" />
          <Bar name="Всего объектов" dataKey="allWork" fill="blue" />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  </div>
);
