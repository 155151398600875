import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  EXPRESS_WORK_KOEFF,
  FULLNAME_NAME,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { RequestAddDto } from '../../../_dto/_request/RequestAddDto';
import { apiAddRequest } from '../../../services/request/apiAddRequest';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { WorkTypeEnum } from '../../../_enum/WorkTypeEnum';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';
import { WorkDto } from '../../../_dto/_work/WorkDto';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { CustomerContractGetDto } from '../../../_dto/_customer/CustomerContractGetDto';
import { apiGetCustomerContract } from '../../../services/customer/apiGetCustomerContract';
import { apiGetCostContract } from '../../../services/cost/apiGetCostContract';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogRequestCreate: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [documentDtos, setDocumentDtos] = useState<CustomerContractGetDto[]>(
    []
  );

  const getDocument = async () => {
    const apiResult: IApiResult = await apiGetCustomerContract();
    if (apiResult.isSuccess) {
      setDocumentDtos(apiResult.data.document);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [workPeriod, setWorkPeriod] = useState('');
  const getParamArray = (type: number, data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].workType === type) {
        arrres.push(data[i].subject);
      }
    }
    return arrres;
  };

  const getResultWork = (
    type: number,
    subject: string,
    expressNum: number,
    data: any
  ) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].workType === type && data[i].subject === subject) {
        if (expressNum === EXPRESS_WORK_KOEFF) {
          arrres.push(data[i].expressCost);
          arrres.push(data[i].expressPeriod);
        } else {
          arrres.push(data[i].cost);
          arrres.push(data[i].period);
        }
      }
    }
    if (arrres.length !== 2) {
      arrres.push(0);
      arrres.push(0);
    }
    return arrres;
  };

  const [requestAddDto, setRequestAddDto] = useState<RequestAddDto>({
    organization: '',
    subject: '',
    koeff: 1,
    cost: '',
    objectName: '',
    workType: -1,
    isMain: 1,
    region: '',
    address: '',
    other: '',
    subjectCnt: 1,
    workCnt: 0,
    workPeriod: '',
    customerName: localStorage.getItem(FULLNAME_NAME) ?? '',
    customerContractId: -1,
    customerContractName: '',
    hopeDate: '',
  });

  const [workDtos, setWorkDtos] = useState<WorkDto[]>([]);

  const handleClose = () => {
    onClose();
    setRequestAddDto({
      organization: '',
      subject: '',
      koeff: 1,
      cost: '',
      objectName: '',
      workType: -1,
      isMain: 1,
      region: '',
      address: '',
      other: '',
      subjectCnt: 0,
      workCnt: 0,
      workPeriod: '',
      customerName: localStorage.getItem(FULLNAME_NAME) ?? '',
      customerContractId: -1,
      customerContractName: '',
      hopeDate: '',
    });
    setWorkPeriod('');
    setCheckHelper(false);
  };

  const getCostContract = async () => {
    const apiResult: IApiResult = await apiGetCostContract(
      requestAddDto.customerContractId
    );
    if (apiResult.isSuccess) {
      setWorkDtos(apiResult.data.cost);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].descr);
    }
    return arrres;
  };

  const addRequest = async () => {
    const apiResult: IApiResult = await apiAddRequest(requestAddDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleChangeExpressWork = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 1;
    if (event.target.checked) {
      check = EXPRESS_WORK_KOEFF;
    }
    const arrtest = getResultWork(
      requestAddDto.workType,
      requestAddDto.subject,
      check,
      workDtos
    );
    if (event.target.checked) {
      setRequestAddDto({
        ...requestAddDto,
        koeff: EXPRESS_WORK_KOEFF,
        cost: arrtest[0],
        workPeriod: arrtest[1],
      });
    } else {
      setRequestAddDto({
        ...requestAddDto,
        koeff: 1,
        cost: arrtest[0],
        workPeriod: arrtest[1],
      });
    }
    setWorkPeriod(arrtest[1]);
  };

  const setOrganization = (inputName: string) => {
    setRequestAddDto({
      ...requestAddDto,
      organization: inputName.replaceAll('"', '_'),
    });
  };

  const setAddress = (inputName: string) => {
    setRequestAddDto({
      ...requestAddDto,
      address: inputName.replaceAll('"', '_'),
    });
  };

  const setOther = (inputName: string) => {
    setRequestAddDto({
      ...requestAddDto,
      other: inputName.replaceAll('"', '_'),
    });
  };

  const setSubjectdop = (inputName: string) => {
    if (inputName !== ' ' && inputName !== '' && inputName !== null) {
      const arrtest = getResultWork(
        requestAddDto.workType,
        inputName,
        requestAddDto.koeff,
        workDtos
      );

      setRequestAddDto({
        ...requestAddDto,
        subject: inputName,
        cost: arrtest[0],
        workCnt: 1,
        workPeriod: arrtest[1],
      });

      setWorkPeriod(arrtest[1]);
    }
  };

  const setObject = (inputName: string) => {
    setRequestAddDto({
      ...requestAddDto,
      objectName: inputName.replaceAll('"', '_'),
    });
  };

  const handleAddRequest = async () => {
    if (
      requestAddDto.organization === '' ||
      requestAddDto.subject === '' ||
      requestAddDto.objectName === '' ||
      requestAddDto.workType === -1 ||
      requestAddDto.region === '' ||
      requestAddDto.address === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await addRequest();
  };

  useEffect(() => {
    if (open) {
      getDocument();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setRequestAddDto({
        ...requestAddDto,
        workType: -1,
        subject: '',
        cost: '',
        workPeriod: '',
        workCnt: 0,
      });
      setWorkPeriod('');
      getCostContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAddDto.customerContractId]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Новая заявка
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={requestAddDto.objectName.replaceAll('_', '"')}
                onChange={(e) => setObject(e.target.value)}
                label="Объект"
                error={checkHelper}
                size="small"
              />
              <MyTextFiled
                value={requestAddDto.organization.replaceAll('_', '"')}
                onChange={(e) => setOrganization(e.target.value)}
                error={checkHelper}
                label="Организация"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <MyAutoComplete
                value={requestAddDto.region}
                onChange={(e, v) =>
                  setRequestAddDto({
                    ...requestAddDto,
                    region: v,
                  })
                }
                arrayList={Object.values(SpisokRegionovEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                error={checkHelper}
                label="Регион"
              />
              <MyTextFiled
                value={requestAddDto.address.replaceAll('_', '"')}
                onChange={(e) => setAddress(e.target.value)}
                label="Адрес"
                error={checkHelper}
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <MyAutoComplete
                value={
                  requestAddDto.customerContractId !== -1
                    ? getName(documentDtos)[
                        Object.values(getNameId(documentDtos)).indexOf(
                          requestAddDto.customerContractId
                        )
                      ]
                    : ''
                }
                onChange={(e, v) =>
                  setRequestAddDto({
                    ...requestAddDto,
                    customerContractId:
                      getNameId(documentDtos)[
                        Object.values(getName(documentDtos)).indexOf(v)
                      ],
                    customerContractName: v,
                  })
                }
                arrayList={getName(documentDtos)}
                size="small"
                error={checkHelper}
                label="Договор"
              />
              <MyAutoComplete
                value={
                  requestAddDto.workType === -1
                    ? ''
                    : WorkTypeEnum[requestAddDto.workType]
                }
                onChange={(e, v) =>
                  setRequestAddDto({
                    ...requestAddDto,
                    workType: Object.values(WorkTypeEnum).indexOf(v),
                  })
                }
                arrayList={Object.values(WorkTypeEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                error={checkHelper}
                label="Тип работы"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <MyAutoComplete
                value={requestAddDto.subject}
                onChange={(e, v) => setSubjectdop(v)}
                arrayList={getParamArray(requestAddDto.workType, workDtos)}
                size="small"
                error={checkHelper}
                label="Предмет"
              />
              <MyDateTextField
                value={requestAddDto.hopeDate}
                onChange={(e) =>
                  setRequestAddDto({
                    ...requestAddDto,
                    hopeDate: e.target.value,
                  })
                }
                width="30%"
                label="Желаемая дата регистрации"
                size="small"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={
                  requestAddDto.cost !== ''
                    ? `${
                        parseFloat(requestAddDto.cost) * requestAddDto.workCnt
                      }`
                    : requestAddDto.cost
                }
                // eslint-disable-next-line react/jsx-boolean-value
                disabled={true}
                label="Стоимость, руб"
                size="small"
              />
              <MyTextFiled
                value={
                  requestAddDto.workCnt < 2
                    ? workPeriod
                    : 'Уточнить по телефону'
                }
                // eslint-disable-next-line react/jsx-boolean-value
                disabled={true}
                label="Срок работы, день"
                size="small"
              />
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: MAIN_COLOR_STRING }}
                  onChange={handleChangeExpressWork}
                />
              }
              label="Срочная работа"
            />
            <MyTextFiled
              value={requestAddDto.other.replaceAll('_', '"')}
              onChange={(e) => setOther(e.target.value)}
              label="Примечание"
              size="small"
            />
            <MyButton
              text="Создать"
              background={MAIN_COLOR_STRING}
              onClick={handleAddRequest}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
