import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../_constant/constants';
import { IApiParams } from '../_interface/IApiParams';
import { getApi } from './getApi';

export const apiGetStats = async (customerId?: number, workerId?: number) => {
  let url = `/getStats.php`;
  if (customerId !== undefined) {
    url = `/getStats.php?customerId=${customerId}`;
  }
  if (workerId !== undefined) {
    url = `/getStats.php?workerId=${workerId}`;
  }
  const params: IApiParams = {
    url,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
