import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { RegionGazDiv } from '../component/regiongaz/RegionGazDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const RegionGazPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<RegionGazDiv />}
  />
);
