import React, { useState } from 'react';
import { Card, Divider, Stack, Typography } from '@mui/material';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { MyButton } from '../../UI/MyButton';
import { MyPasswordTextField } from '../../UI/MyPasswordTextField';
import { MyTextFiled } from '../../UI/MyTextField';
import { IApiResult } from '../../../_interface/IApiResult';
import { apiSignUp } from '../../../services/worker/apiSignUp';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { WorkerSignUpDto } from '../../../_dto/_worker/WorkerSignUpDto';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { MyAlert } from '../../UI/MyAlert';
import { MyDateTextField } from '../../UI/MyDateTextField';

export const SignUpDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [workerSignUpDto, setWorkerSignUpDto] = useState<WorkerSignUpDto>({
    login: '',
    password: '',
    name: '',
    position: '',
    phone: '',
    email: '',
    root: 1,
    birthDate: '',
    employmentDate: '',
    code: '',
  });

  const signUp = async () => {
    const apiResult: IApiResult = await apiSignUp(workerSignUpDto);
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Исполнитель создан',
        severity: AlertSeverityEnum.info,
      });
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: 'Такой логин существует',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSignUp = async () => {
    if (workerSignUpDto.login === '' || workerSignUpDto.password === '') {
      setAlertProps({
        message: 'Введите логин и пароль',
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    await signUp();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '25%' }}>
        <Stack spacing={2}>
          <Typography
            style={{
              fontFamily: 'SF Compact Rounded',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: MAIN_TITLE_FONTSIZE_STRING,
              letterSpacing: 1,
              color: '#000000',
            }}
            component="text"
          >
            Регистрация исполнителя
          </Typography>
          <Divider />
          <MyTextFiled
            value={workerSignUpDto.name}
            onChange={(e) =>
              setWorkerSignUpDto({ ...workerSignUpDto, name: e.target.value })
            }
            label="Полное имя"
            size="small"
          />
          <MyTextFiled
            value={workerSignUpDto.position}
            onChange={(e) =>
              setWorkerSignUpDto({
                ...workerSignUpDto,
                position: e.target.value,
              })
            }
            label="Должность"
            size="small"
          />
          <MyTextFiled
            value={workerSignUpDto.code}
            onChange={(e) =>
              setWorkerSignUpDto({
                ...workerSignUpDto,
                code: e.target.value,
              })
            }
            label="Шифр"
            size="small"
          />
          <Stack width="100%" direction="row" spacing={2}>
            <MyTextFiled
              value={workerSignUpDto.phone}
              onChange={(e) =>
                setWorkerSignUpDto({
                  ...workerSignUpDto,
                  phone: e.target.value,
                })
              }
              label="Телефон"
              size="small"
            />
            <MyTextFiled
              value={workerSignUpDto.email}
              onChange={(e) =>
                setWorkerSignUpDto({
                  ...workerSignUpDto,
                  email: e.target.value,
                })
              }
              label="E-mail"
              size="small"
            />
          </Stack>
          <Stack width="100%" direction="row" spacing={2}>
            <MyTextFiled
              value={workerSignUpDto.login}
              onChange={(e) =>
                setWorkerSignUpDto({
                  ...workerSignUpDto,
                  login: e.target.value,
                })
              }
              label="Логин"
              size="small"
            />
            <MyPasswordTextField
              value={workerSignUpDto.password}
              onChange={(e) =>
                setWorkerSignUpDto({
                  ...workerSignUpDto,
                  password: e.target.value,
                })
              }
              label="Пароль"
              size="small"
            />
          </Stack>
          <Stack width="100%" direction="row" spacing={2}>
            <MyDateTextField
              value={workerSignUpDto.birthDate}
              onChange={(e) =>
                setWorkerSignUpDto({
                  ...workerSignUpDto,
                  birthDate: e.target.value,
                })
              }
              label="Дата рождения"
              size="small"
            />
            <MyDateTextField
              value={workerSignUpDto.employmentDate}
              onChange={(e) =>
                setWorkerSignUpDto({
                  ...workerSignUpDto,
                  employmentDate: e.target.value,
                })
              }
              label="Дата устройства на работу"
              size="small"
            />
          </Stack>
          <MyButton
            text="Зарегистрировать"
            background={MAIN_COLOR_STRING}
            onClick={handleSignUp}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
