import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
  Backdrop,
  Box,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CustomerGetCustomerRespDto } from '../../../_dto/_customer/CustomerGetCustomerRespDto';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { apiUpdateCustomer } from '../../../services/customer/apiUpdateCustomer';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { CustomerUpdateCustomerDto } from '../../../_dto/_customer/CustomerUpdateCustomerDto';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { MyButton } from '../../UI/MyButton';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';
import { WorkerGetDto } from '../../../_dto/_worker/WorkerGetDto';
import { apiGetWorker } from '../../../services/apiGetWorker';
import { CustomerContractGetDto } from '../../../_dto/_customer/CustomerContractGetDto';
import { apiGetCustomerContract } from '../../../services/customer/apiGetCustomerContract';
import { DocumentDeleteDocumentDto } from '../../../_dto/_document/DocumentDeleteDocumentDto';
import { apiDeleteContractDocument } from '../../../services/customer/apiDeleteContractDocument';
import { DialogConfirmation } from '../DialogConfirmation';
import { apiDownloadFile } from '../../../services/apiDownloadFile';
import { ProgressBox } from '../../progress/ProgressBox';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: CustomerGetCustomerRespDto;
}

export const DialogMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const root = getRoot();
  const [shouldShowLoader, setShowLoader] = useState(false);
  const [uploadText, setUploadText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const progressRef = useRef(0);
  const [valueTabNew, setValueTabNew] = React.useState(0);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [documentDto, setDocumentDto] = useState<CustomerContractGetDto>({
    id: -1,
    customerId: -1,
    descr: '',
    name: '',
    datetime: '',
    size: '',
    crc: '',
  });
  const [documentDtos, setDocumentDtos] = useState<CustomerContractGetDto[]>(
    []
  );
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };
  const [getWorkerDtos, setGetWorkerDtos] = useState<WorkerGetDto[]>([]);
  const getWorker = async () => {
    const apiResult: IApiResult = await apiGetWorker();
    if (apiResult.isSuccess) {
      setGetWorkerDtos(apiResult.data.worker);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].name);
    }
    return arrres;
  };

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  const getDocument = async () => {
    const apiResult: IApiResult = await apiGetCustomerContract(dto?.id);
    if (apiResult.isSuccess) {
      setDocumentDtos(apiResult.data.document);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [customerSignUpDto, setCustomerSignUpDto] =
    useState<CustomerUpdateCustomerDto>({
      login: dto?.login ?? '',
      password: '',
      region: dto?.region ?? '',
      name: dto?.name ?? '',
      inn: dto?.inn ?? '',
      phone: dto?.phone ?? '',
      email: dto?.email ?? '',
      address: dto?.address ?? '',
      other: dto?.other ?? '',
      code: dto?.code ?? '',
      delegate: dto?.delegate ?? '',
      workerId: dto?.workerId ?? -1,
    });

  const handleClose = () => {
    onClose();
    setIsEdit(false);
    setValueTabNew(0);
    setCheckHelper(false);
    setUploadProgress(0);
    progressRef.current = 0;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };

  const updateWorker = async () => {
    const apiResult: IApiResult = await apiUpdateCustomer(customerSignUpDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const setCustomerName = (inputName: string) => {
    setCustomerSignUpDto({
      ...customerSignUpDto,
      name: inputName.replaceAll('"', '_'),
    });
  };

  const handleProgressUpdate = (event: any) => {
    const progress = (event.loaded / event.total) * 100;
    if (progress > progressRef.current) {
      progressRef.current = progress;
      setUploadProgress(progressRef.current);
    }
  };
  const cancelToken = useRef(axios.CancelToken.source());
  const openFile = async (name: string) => {
    cancelToken.current = axios.CancelToken.source();
    setUploadProgress(0);
    progressRef.current = 0;
    setShowLoader(true);
    const nameArray = name.split(';');
    for (let i = 0; i < nameArray.length; i += 1) {
      if (nameArray[i].length > 4) {
        setUploadText(`${i + 1} из ${nameArray.length - 1}`);
        // eslint-disable-next-line no-await-in-loop
        await apiDownloadFile(
          `/contract/${nameArray[i]}`,
          nameArray[i],
          handleProgressUpdate,
          cancelToken.current
        );
      }
      progressRef.current = 0;
    }
    setShowLoader(false);
  };
  const cancelCreationOfTweet = () => {
    cancelToken.current.cancel();
    setShowLoader(false);
  };

  const handleSave = async () => {
    if (
      customerSignUpDto.login === '' ||
      customerSignUpDto.workerId === -1 ||
      customerSignUpDto.name === '' ||
      customerSignUpDto.region === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await updateWorker();
  };

  const deleteCustomerContract = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const deleteDto: DocumentDeleteDocumentDto = {
      documentId: documentDto.id,
    };
    const apiResult: IApiResult = await apiDeleteContractDocument(deleteDto);
    if (apiResult.isSuccess) {
      await getDocument();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dtoContract: CustomerContractGetDto) => {
    setDocumentDto(dtoContract);
    setOpenDialogConfirmation(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'descr',
      renderHeader: () => <strong>Номер договора</strong>,
      flex: 1,
      minWidth: 250,
      editable: false,
      renderCell: (row) =>
        row.row.type === '2'
          ? `${row.row.descr} ${row.row.regNum}`
          : `${row.row.descr}`,
    },
    {
      field: 'datetime',
      renderHeader: () => <strong>Дата загрузки</strong>,
      width: 200,
      editable: false,
    },
    {
      field: 'size',
      renderHeader: () => <strong>Файлов</strong>,
      width: 100,
      editable: false,
      renderCell: (row) => row.row.name.split(';').length - 1,
    },
    {
      field: 'id',
      headerName: '',
      width: 100,
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="left">
          {row.row.name.includes('pdf') && (
            <PictureAsPdfIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
          {row.row.name.includes('doc') && (
            <ArticleIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
        </Stack>
      ),
    },
    {
      field: 'name',
      type: 'actions',
      width: root === ROOT_ADMIN_VALUE ? 70 : 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton title="Скачать" size="small">
            <FileDownloadIcon
              fontSize="small"
              sx={{ color: 'blue' }}
              onClick={() => openFile(row.row.name)}
            />
          </IconButton>
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  useEffect(() => {
    if (open) {
      if (valueTabNew === 0) {
        getWorker();
        setCustomerSignUpDto({
          login: dto?.login ?? '',
          password: '',
          region: dto?.region ?? '',
          name: dto?.name ?? '',
          inn: dto?.inn ?? '',
          phone: dto?.phone ?? '',
          email: dto?.email ?? '',
          address: dto?.address ?? '',
          other: dto?.other ?? '',
          code: dto?.code ?? '',
          delegate: dto?.delegate ?? '',
          workerId: dto?.workerId ?? -1,
        });
      }
      if (valueTabNew === 1) {
        getDocument();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, valueTabNew]);
  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme2) => theme2.zIndex.drawer + 1 }}
          open={shouldShowLoader}
          onClick={cancelCreationOfTweet}
        >
          <Stack alignItems="center" spacing={1}>
            <Typography variant="caption" component="div" color="inherit">
              {uploadText}
            </Typography>
            <ProgressBox progress={uploadProgress} />
            <Typography variant="caption" component="div" color="inherit">
              Если нажать на экран, то произойдет отмена загрузки
            </Typography>
          </Stack>
        </Backdrop>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {root === ROOT_ADMIN_VALUE && !isEdit && valueTabNew === 0 && (
                <IconButton size="medium" onClick={handleEdit}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
            </Stack>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={valueTabNew}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Филиал" />
                  <Tab disabled={isEdit} label="Договора" />
                </Tabs>
              </Box>
              <Stack>
                <IconButton size="medium" onClick={() => handleClose()}>
                  <CloseIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack>
            {valueTabNew === 0 && (
              <Stack spacing={2}>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyTextFiled
                    value={customerSignUpDto.name.replaceAll('_', '"')}
                    onChange={(e) => setCustomerName(e.target.value)}
                    label="Наименование"
                    disabled={!isEdit}
                    error={checkHelper}
                    size="small"
                  />
                  <MyTextFiled
                    value={customerSignUpDto.delegate}
                    onChange={(e) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        delegate: e.target.value,
                      })
                    }
                    label="Представитель"
                    disabled={!isEdit}
                    size="small"
                  />
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyAutoComplete
                    value={
                      customerSignUpDto.region !== ''
                        ? customerSignUpDto.region
                        : ''
                    }
                    onChange={(e, v) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        region: v !== null ? v : '',
                      })
                    }
                    arrayList={Object.values(SpisokRegionovEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    size="small"
                    error={checkHelper}
                    disabled={!isEdit}
                    label="Регион"
                  />
                  <MyTextFiled
                    value={customerSignUpDto.address}
                    onChange={(e) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        address: e.target.value,
                      })
                    }
                    label="Адрес"
                    disabled={!isEdit}
                    size="small"
                  />
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyTextFiled
                    value={customerSignUpDto.inn}
                    onChange={(e) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        inn: e.target.value,
                      })
                    }
                    label="ИНН"
                    disabled={!isEdit}
                    size="small"
                  />
                  <MyTextFiled
                    value={customerSignUpDto.phone}
                    onChange={(e) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        phone: e.target.value,
                      })
                    }
                    disabled={!isEdit}
                    label="Телефон"
                    size="small"
                  />
                  <MyTextFiled
                    value={customerSignUpDto.email}
                    onChange={(e) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        email: e.target.value,
                      })
                    }
                    disabled={!isEdit}
                    label="E-mail"
                    size="small"
                  />
                </Stack>
                <MyAutoComplete
                  value={
                    customerSignUpDto.workerId !== -1 &&
                    customerSignUpDto.workerId !== null &&
                    customerSignUpDto.workerId !== undefined
                      ? getName(getWorkerDtos)[
                          Object.values(getNameId(getWorkerDtos)).indexOf(
                            customerSignUpDto.workerId
                          )
                        ]
                      : ''
                  }
                  onChange={(e, v) =>
                    setCustomerSignUpDto({
                      ...customerSignUpDto,
                      workerId:
                        getNameId(getWorkerDtos)[
                          Object.values(getName(getWorkerDtos)).indexOf(v)
                        ],
                    })
                  }
                  disabled={!isEdit}
                  error={checkHelper}
                  arrayList={getName(getWorkerDtos)}
                  size="small"
                  label="Привязать исполнителя"
                />
                <MyTextFiled
                  value={customerSignUpDto.other}
                  onChange={(e) =>
                    setCustomerSignUpDto({
                      ...customerSignUpDto,
                      other: e.target.value,
                    })
                  }
                  disabled={!isEdit}
                  label="Примечание"
                  multiLine
                  size="small"
                />
                <Stack width="100%" direction="row" spacing={2}>
                  <MyTextFiled
                    value={customerSignUpDto.login}
                    onChange={(e) =>
                      setCustomerSignUpDto({
                        ...customerSignUpDto,
                        login: e.target.value,
                      })
                    }
                    disabled
                    label="Логин"
                    size="small"
                  />
                  <MyTextFiled
                    value={`${dto?.createdDate}`}
                    disabled
                    label="Дата создания"
                    size="small"
                  />
                  <MyTextFiled
                    value={`${dto?.modificationDate}`}
                    disabled
                    label="Дата модификации"
                    size="small"
                  />
                </Stack>
                {isEdit && (
                  <Stack direction="row" spacing={1}>
                    <MyButton
                      text="Отмена"
                      background={MAIN_COLOR_STRING}
                      onClick={() => setIsEdit(false)}
                    />
                    <MyButton
                      text="Сохранить"
                      background={MAIN_COLOR_STRING}
                      onClick={handleSave}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            {valueTabNew === 1 && (
              <Stack spacing={2}>
                <Box sx={{ width: '100%' }}>
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                          {
                            py: 1,
                          },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                          {
                            py: '5px',
                          },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                          {
                            py: '10px',
                          },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                          {
                            outline: 'none !important',
                          },
                      }}
                      getRowHeight={() => 'auto'}
                      columns={columns}
                      rows={documentDtos}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...other}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </ThemeProvider>
                </Box>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteCustomerContract}
      />
    </div>
  );
};

DialogMoreInfo.defaultProps = {
  dto: undefined,
};
