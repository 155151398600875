import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { WarningDiv } from '../component/warning/WarningDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const WarningPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<WarningDiv />}
  />
);
