import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
} from '../../_constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { getDefaultAlertProps, getRoot } from '../../utils/utils';
import { IAlertProps } from '../../_interface/IAlertProps';
import { IApiResult } from '../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { TaskGetTaskDto } from '../../_dto/_task/TaskGetTaskDto';
import { apiGetTask } from '../../services/task/apiGetTask';
import { TaskDeleteTaskDto } from '../../_dto/_task/TaskDeleteTaskDto';
import { apiDeleteTask } from '../../services/task/apiDeleteTask';
import { DialogTaskAdd } from '../dialog/task/DialogTaskAdd';
import { WorkerGetDto } from '../../_dto/_worker/WorkerGetDto';
import { apiGetWorker } from '../../services/apiGetWorker';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { SIGN_IN_ROUTE } from '../../_constant/routes';

export const TaskDiv = () => {
  const root = getRoot();
  const navigate = useNavigate();
  const [taskId, setTaskId] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [userId, setUserId] = useState();
  const [getTaskDtos, setGetTaskDtos] = useState<TaskGetTaskDto[]>([]);

  const getTask = async () => {
    const apiResult: IApiResult = await apiGetTask(userId);
    if (apiResult.isSuccess) {
      setGetTaskDtos(apiResult.data.task);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [getWorkerDtos, setGetWorkerDtos] = useState<WorkerGetDto[]>([]);
  const getWorker = async () => {
    const apiResult: IApiResult = await apiGetWorker();
    if (apiResult.isSuccess) {
      setGetWorkerDtos(apiResult.data.worker);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getName = (data: any) => {
    const arrres = [];
    arrres.push('Всем');
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].name);
    }
    return arrres;
  };

  const getNameId = (data: any) => {
    const arrres = [];
    arrres.push(-100);
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  useEffect(() => {
    if (root === ROOT_ADMIN_VALUE) {
      getWorker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!openDialog) {
      getTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog, userId]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const deleteTask = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const taskDeleteDto: TaskDeleteTaskDto = {
      taskId,
    };
    const apiResult: IApiResult = await apiDeleteTask(taskDeleteDto);
    if (apiResult.isSuccess) {
      await getTask();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (id: number) => {
    setTaskId(id);
    setOpenDialogConfirmation(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      renderHeader: () => <strong>Дата создания</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'task',
      flex: 1,
      minWidth: 400,
      renderHeader: () => <strong>Задача</strong>,
      editable: false,
      renderCell: (row) =>
        row.row.isAdmin === 0 ? (
          row.row.task
        ) : (
          <strong style={{ color: 'red' }}>{row.row.task}</strong>
        ),
    },
    {
      field: 'dateTo',
      minWidth: 250,
      renderHeader: () => <strong>Сделать до</strong>,
      editable: false,
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDelete(row.row.id)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Задачи
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              spacing={2}
            >
              {root === ROOT_ADMIN_VALUE && (
                <MyAutoComplete
                  value={
                    userId !== undefined
                      ? getName(getWorkerDtos)[
                          Object.values(getNameId(getWorkerDtos)).indexOf(
                            userId
                          )
                        ]
                      : ' '
                  }
                  onChange={(e, v) =>
                    setUserId(
                      getNameId(getWorkerDtos)[
                        Object.values(getName(getWorkerDtos)).indexOf(v)
                      ]
                    )
                  }
                  width="400px"
                  arrayList={getName(getWorkerDtos)}
                  size="small"
                  label="Исполнитель"
                />
              )}
              <MyButton
                text="Добавить"
                width="100px"
                height="30px"
                background={MAIN_COLOR_STRING}
                onClick={() => setOpenDialog(true)}
              />
            </Stack>
          </Stack>
          <Divider />
          <div>
            <Box sx={{ width: '100%' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={getTaskDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </Stack>
      </Card>
      <DialogTaskAdd
        open={openDialog}
        userId={userId}
        onClose={() => setOpenDialog(false)}
      />
      <DialogConfirmation open={openDialogConfirmation} onClose={deleteTask} />
    </Stack>
  );
};
