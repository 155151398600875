import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { ReestrDiv } from '../component/reestr/ReestrDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const ReestrPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<ReestrDiv />}
  />
);
