import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountantPage } from '../../page/AccountantPage';
import { ContractEndPage } from '../../page/ContractEndPage';
import { ContractPage } from '../../page/ContractPage';
import { CustomerPage } from '../../page/CustomerPage';
import { FormPage } from '../../page/FormPage';
import { MainPage } from '../../page/MainPage';
import { ReestrPage } from '../../page/ReestrPage';
import { RegionGazPage } from '../../page/RegionGazPage';
import { RequestPage } from '../../page/RequestPage';
import { SignInPage } from '../../page/SignInPage';
import { SignUpPage } from '../../page/SignUpPage';
import { SignUpPageCustomer } from '../../page/SignUpPageCustomer';
import { StatsPage } from '../../page/StatsPage';
import { TaskPage } from '../../page/TaskPage';
import { WarningPage } from '../../page/WarningPage';
import { WorkerPage } from '../../page/WorkerPage';
import { WorkPage } from '../../page/WorkPage';
import {
  ACCOUNTANT_ROUTE,
  CONTRACT_ROUTE,
  CREATE_CUSTOMER_ROUTE,
  CREATE_WORKER_ROUTE,
  CUSTOMER_ROUTE,
  FINISH_ROUTE,
  FORM_ROUTE,
  MAIN_ROUTE,
  REESTR_ROUTE,
  REGIONGAZ_ROUTE,
  REQUEST_ROUTE,
  SIGN_IN_ROUTE,
  STATS_ROUTE,
  TASK_ROUTE,
  WARNING_ROUTE,
  WORKER_ROUTE,
  WORK_ROUTE,
} from '../../_constant/routes';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={CREATE_WORKER_ROUTE} element={<SignUpPage />} />
      <Route path={CREATE_CUSTOMER_ROUTE} element={<SignUpPageCustomer />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={REQUEST_ROUTE} element={<RequestPage />} />
      <Route path={CONTRACT_ROUTE} element={<ContractPage />} />
      <Route path={CUSTOMER_ROUTE} element={<CustomerPage />} />
      <Route path={REGIONGAZ_ROUTE} element={<RegionGazPage />} />
      <Route path={WORKER_ROUTE} element={<WorkerPage />} />
      <Route path={ACCOUNTANT_ROUTE} element={<AccountantPage />} />
      <Route path={TASK_ROUTE} element={<TaskPage />} />
      <Route path={WARNING_ROUTE} element={<WarningPage />} />
      <Route path={FORM_ROUTE} element={<FormPage />} />
      <Route path={STATS_ROUTE} element={<StatsPage />} />
      <Route path={WORK_ROUTE} element={<WorkPage />} />
      <Route path={REESTR_ROUTE} element={<ReestrPage />} />
      <Route path={FINISH_ROUTE} element={<ContractEndPage />} />
    </Routes>
  </div>
);
