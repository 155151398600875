import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Stack, Typography } from '@mui/material';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  FULLNAME_NAME,
  HASH_NAME,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  REFRESH_TOKEN_NAME,
  REGION_NAME,
} from '../../_constant/constants';
import { MyButton } from '../UI/MyButton';
import { MyPasswordTextField } from '../UI/MyPasswordTextField';
import { MyTextFiled } from '../UI/MyTextField';
import { MAIN_ROUTE } from '../../_constant/routes';
import { IApiResult } from '../../_interface/IApiResult';
import { apiSignIn } from '../../services/apiSignIn';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { UserAuthDto } from '../../_dto/UserAuthDto';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { MyAlert } from '../UI/MyAlert';

export const SignInDiv = () => {
  localStorage.clear();
  const navigate = useNavigate();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);

  const [userAuthDto, setUserAuthDto] = useState<UserAuthDto>({
    login: '',
    password: '',
  });

  const signIn = async () => {
    const apiResult: IApiResult = await apiSignIn(userAuthDto);
    if (apiResult.isSuccess) {
      setUserAuthDto(apiResult.data);
      localStorage.setItem(ACCESS_TOKEN_NAME, apiResult.data.access.token);
      localStorage.setItem(REFRESH_TOKEN_NAME, apiResult.data.refresh.token);
      localStorage.setItem(HASH_NAME, apiResult.data.hash);
      localStorage.setItem(FULLNAME_NAME, apiResult.data.name);
      localStorage.setItem(REGION_NAME, apiResult.data.region);
      navigate(MAIN_ROUTE);
    } else if (apiResult.status === 404) {
      setAlertProps({
        message: 'Неверный логин и/или пароль',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSignIn = async () => {
    if (userAuthDto.login === '' || userAuthDto.password === '') {
      setCheckHelper(true);
      return;
    }
    await signIn();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '15%' }}>
        <Stack width="100%" spacing={2}>
          <Typography
            style={{
              fontFamily: 'SF Compact Rounded',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: MAIN_TITLE_FONTSIZE_STRING,
              letterSpacing: 1,
              color: '#000000',
            }}
            component="text"
          >
            CRM-Метролог
          </Typography>
          <Divider />
          <MyTextFiled
            value={userAuthDto.login}
            size="small"
            onChange={(e) =>
              setUserAuthDto({ ...userAuthDto, login: e.target.value })
            }
            error={checkHelper}
            label="Логин"
          />
          <MyPasswordTextField
            value={userAuthDto.password}
            size="small"
            onChange={(e) =>
              setUserAuthDto({ ...userAuthDto, password: e.target.value })
            }
            error={checkHelper}
            label="Пароль"
          />
          <MyButton
            text="Войти"
            background={MAIN_COLOR_STRING}
            onClick={handleSignIn}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
