import React, { FunctionComponent } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
  progress: number;
}

export const ProgressBox: FunctionComponent<Props> = ({ progress }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      color="inherit"
      size={50}
      variant="determinate"
      value={progress}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="caption" component="div" color="inherit">
        {`${Math.round(progress)}%`}
      </Typography>
    </Box>
  </Box>
);
