import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { WorkDiv } from '../component/work/WorkDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const WorkPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<WorkDiv />}
  />
);
