import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { TaskDiv } from '../component/task/TaskDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const TaskPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<TaskDiv />}
  />
);
