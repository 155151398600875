import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { WorkerSignUpDto } from '../../_dto/_worker/WorkerSignUpDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiSignUp = async (workerSignUpDto: WorkerSignUpDto) => {
  const params: IApiParams = {
    url: '/worker/signUp.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', workerSignUpDto);
};
