import React from 'react';
import { SignUpDiv } from '../component/sign-up/worker/SignUpDiv';
import { SignUpPageLayout } from '../layout/SignUpPageLayout';
import { SignNavigationBar } from '../component/navigation-bar/SignNavigationBar';

export const SignUpPage = () => (
  <SignUpPageLayout
    navigationBar={<SignNavigationBar />}
    centerDesktop={<SignUpDiv />}
  />
);
