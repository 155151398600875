import React, { FunctionComponent } from 'react';
import { isMobileView } from '../utils/utils';
import classes from './MainPageLayout.module.css';

interface Props {
  navigationBar?: React.ReactNode;
  elementCentr?: React.ReactNode;
}

export const MainPageLayout: FunctionComponent<Props> = ({
  navigationBar,
  elementCentr,
}) => (
  <div
    style={{ userSelect: 'none' }}
    className={!isMobileView() ? classes.myDiv : classes.myDivSiteStub}
  >
    {!isMobileView() && (
      <div className={classes.container}>
        <div className={classes.elementBar}>{navigationBar}</div>
        <div className={classes.elementCentr}>{elementCentr}</div>
      </div>
    )}
  </div>
);

MainPageLayout.defaultProps = {
  navigationBar: undefined,
  elementCentr: undefined,
};
