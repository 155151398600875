import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { DocumentAddDocumentDto } from '../../_dto/_document/DocumentAddDocumentDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiAddDocument = async (documentSendMessageDto: DocumentAddDocumentDto) => {
  const params: IApiParams = {
    url: '/document/addDocument.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'POST', documentSendMessageDto);
};