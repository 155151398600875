/* eslint-disable no-unsafe-optional-chaining */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Checkbox,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import {
  API_STATUS_PROBLEM,
  EXPRESS_WORK_KOEFF,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
  ROOT_CUSTOMER_VALUE,
  ROOT_REGIONGAZ_VALUE,
  ROOT_WORKER_VALUE,
} from '../../../_constant/constants';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { WorkTypeEnum } from '../../../_enum/WorkTypeEnum';
import { WorkDto } from '../../../_dto/_work/WorkDto';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { CustomerGetCustomerRespDto } from '../../../_dto/_customer/CustomerGetCustomerRespDto';
import { apiGetCustomer } from '../../../services/customer/apiGetCustomer';
import { ContractGetContractDto } from '../../../_dto/_contract/ContractGetContractDto';
import { DocumentGetDocumentDto } from '../../../_dto/_document/DocumentGetDocumentDto';
import { apiGetDocument } from '../../../services/document/apiGetDocument';
import { ContractDocumentTypeName } from '../../../_enum/ContractDocumentTypeName';
import { apiUpdateRequest } from '../../../services/request/apiUpdateRequest';
import { RequestUpdateDto } from '../../../_dto/_request/RequestUpdateDto';
import { CustomerContractGetDto } from '../../../_dto/_customer/CustomerContractGetDto';
import { apiGetCustomerContract } from '../../../services/customer/apiGetCustomerContract';
import { apiGetCostContract } from '../../../services/cost/apiGetCostContract';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { DialogConfirmation } from '../DialogConfirmation';
import { DocumentDeleteDocumentDto } from '../../../_dto/_document/DocumentDeleteDocumentDto';
import { apiDeleteDocument } from '../../../services/document/apiDeleteDocument';
import { apiGetRegNum } from '../../../services/apiGetRegNum';
import { DialogConfirmationRegNum } from '../DialogConfirmationRegNum';
import { apiDownloadFile } from '../../../services/apiDownloadFile';
import { ProgressBox } from '../../progress/ProgressBox';
import { apiDeleteRegNum } from '../../../services/apiDeleteRegNum';
import { RegNumDeleteDto } from '../../../_dto/RegNumDeleteDto';
import { DialogConfirmationCustom } from '../DialogConfirmationCustom';

interface Props {
  open: boolean;
  onClose: () => void;
  dto: ContractGetContractDto;
}

export const DialogMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const root = getRoot();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [shouldShowLoader, setShowLoader] = useState(false);
  const [uploadText, setUploadText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const progressRef = useRef(0);
  const [deleteRegNumDto, setDeleteRegNumDto] = useState<RegNumDeleteDto>({
    regNum: '',
    contractId: -1,
  });
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogConfirmationRegNum, setOpenDialogConfirmationRegNum] =
    useState(false);
  const [
    openDialogConfirmationRegNumDelete,
    setOpenDialogConfirmationRegNumDelete,
  ] = useState(false);
  const [checkHelper, setCheckHelper] = useState(false);
  const [contractUpdateDto, setContractUpdateDto] = useState<RequestUpdateDto>({
    requestId: dto?.id ?? -1,
    organization: dto?.organization ?? '',
    address: dto?.address ?? '',
    other: dto?.note ?? '',
    region: dto?.region ?? '',
    objectName: dto?.objectName ?? '',
    subject: dto?.subject ?? '',
    workType: dto?.workType ?? -1,
    koeff: dto?.koeff ?? -1,
    cost: dto?.cost ?? '',
    workCnt: dto?.workCnt ?? -1,
    customerContractId: dto?.customerContractId ?? -1,
    customerContractName: dto?.customerContractName ?? '',
    hopeDate: dto?.hopeDate ?? '',
    workPeriod: dto?.workPeriod ?? '',
  });

  const [regParams, setRegParams] = useState({
    regNum: dto.regNum,
    regDate: dto.regDate,
  });

  const [documentDto, setDocumentDto] = useState<DocumentGetDocumentDto>({
    id: -1,
    userId: -1,
    isCustomer: -1,
    descr: '',
    name: '',
    type: '',
    datetime: '',
    size: '',
    crc: '',
    regNum: '',
  });

  const [documentDtos, setDocumentDtos] = useState<DocumentGetDocumentDto[]>(
    []
  );

  const [documentContractDtos, setDocumentContractDtos] = useState<
    CustomerContractGetDto[]
  >([]);
  const getDocumentContract = async () => {
    const apiResult: IApiResult = await apiGetCustomerContract(dto.customerId);
    if (apiResult.isSuccess) {
      setDocumentContractDtos(apiResult.data.document);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [workDtos, setWorkDtos] = useState<WorkDto[]>([]);

  const getCostContract = async () => {
    const apiResult: IApiResult = await apiGetCostContract(
      contractUpdateDto.customerContractId
    );
    if (apiResult.isSuccess) {
      setWorkDtos(apiResult.data.cost);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [isEditObject, setIsEditObject] = useState(false);
  const handleEditObject = () => {
    setIsEditObject(true);
  };

  const getDocument = async () => {
    const apiResult: IApiResult = await apiGetDocument(dto.id);
    if (apiResult.isSuccess) {
      setDocumentDtos(apiResult.data.document);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getParamArray = (type: number, data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].workType === type) {
        arrres.push(data[i].subject);
      }
    }
    return arrres;
  };

  const [customerDto, setCustomerDto] = useState<CustomerGetCustomerRespDto>({
    id: -1,
    login: '',
    region: '',
    name: '',
    inn: '',
    phone: '',
    email: '',
    address: '',
    other: '',
    lockUser: -1,
    completedContract: -1,
    activeContract: -1,
    conclusionContract: -1,
    createdDate: '',
    modificationDate: '',
    code: '',
    delegate: '',
    workerId: -1,
    telegramId: '',
  });

  const [valueTabNew, setValueTabNew] = React.useState(0);
  const [workPeriod, setWorkPeriod] = useState('');

  const getCustomer = async () => {
    const apiResult: IApiResult = await apiGetCustomer(dto.customerId);
    if (apiResult.isSuccess) {
      setCustomerDto(apiResult.data.customer);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleClose = () => {
    onClose();
    setIsEditObject(false);
    setDocumentDtos([]);
    setContractUpdateDto({
      requestId: -1,
      organization: '',
      address: '',
      other: '',
      region: '',
      objectName: '',
      subject: '',
      workType: -1,
      koeff: -1,
      cost: '',
      workCnt: -1,
      customerContractId: -1,
      customerContractName: '',
      hopeDate: '',
      workPeriod: '',
    });
    setRegParams({
      regNum: '',
      regDate: '',
    });
    setDeleteRegNumDto({
      regNum: '',
      contractId: -1,
    });
    setValueTabNew(0);
    setWorkPeriod('');
    setCheckHelper(false);
    setUploadProgress(0);
    progressRef.current = 0;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };

  const updateRequest = async () => {
    const apiResult: IApiResult = await apiUpdateRequest(contractUpdateDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSaveRequest = async () => {
    if (
      contractUpdateDto.organization === '' ||
      contractUpdateDto.subject === '' ||
      contractUpdateDto.objectName === '' ||
      contractUpdateDto.workType === -1 ||
      contractUpdateDto.region === '' ||
      contractUpdateDto.address === ''
    ) {
      setCheckHelper(true);
      return;
    }

    await updateRequest();
  };

  const handleProgressUpdate = (event: any) => {
    const progress = (event.loaded / event.total) * 100;
    if (progress > progressRef.current) {
      progressRef.current = progress;
      setUploadProgress(progressRef.current);
    }
  };
  const cancelToken = useRef(axios.CancelToken.source());
  const openFile = async (name: string) => {
    cancelToken.current = axios.CancelToken.source();
    setUploadProgress(0);
    progressRef.current = 0;
    setShowLoader(true);
    const nameArray = name.split(';');
    for (let i = 0; i < nameArray.length; i += 1) {
      if (nameArray[i].length > 4) {
        setUploadText(`${i + 1} из ${nameArray.length - 1}`);
        // eslint-disable-next-line no-await-in-loop
        await apiDownloadFile(
          `/contract/${nameArray[i]}`,
          nameArray[i],
          handleProgressUpdate,
          cancelToken.current
        );
      }
      progressRef.current = 0;
    }
    setShowLoader(false);
  };
  const cancelCreationOfTweet = () => {
    cancelToken.current.cancel();
    setShowLoader(false);
  };

  useEffect(() => {
    if (open) {
      if (root === ROOT_REGIONGAZ_VALUE) {
        setValueTabNew(2);
      }
      setContractUpdateDto({
        requestId: dto?.id ?? -1,
        organization: dto?.organization ?? '',
        address: dto?.address ?? '',
        other: dto?.note ?? '',
        region: dto?.region ?? '',
        objectName: dto?.objectName ?? '',
        subject: dto?.subject ?? '',
        workType: dto?.workType ?? -1,
        koeff: dto?.koeff ?? -1,
        cost: dto?.cost ?? '',
        workCnt: dto?.workCnt ?? -1,
        customerContractId: dto?.customerContractId ?? -1,
        customerContractName: dto?.customerContractName ?? '',
        hopeDate: dto?.hopeDate ?? '',
        workPeriod: dto?.workPeriod ?? '',
      });
      setRegParams({
        regNum: dto.regNum,
        regDate: dto.regDate,
      });
      setDeleteRegNumDto({
        regNum: dto.regNum,
        contractId: dto.id,
      });
      setWorkPeriod(dto?.workPeriod);
      if (valueTabNew === 0) {
        getDocumentContract();
        // getCostContract();
      }
      if (valueTabNew === 1) {
        getCustomer();
      }
      if (valueTabNew === 2) {
        getDocument();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, valueTabNew]);

  useEffect(() => {
    if (open && valueTabNew === 0) {
      getCostContract();
    }
    if (isEditObject) {
      setWorkPeriod('');
      setContractUpdateDto({
        ...contractUpdateDto,
        workType: -1,
        subject: '',
        cost: '',
        workPeriod: '',
        workCnt: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueTabNew, contractUpdateDto.customerContractId]);

  useEffect(() => {
    if (isEditObject) {
      setWorkPeriod('');
      setContractUpdateDto({
        ...contractUpdateDto,
        subject: '',
        cost: '',
        workPeriod: '',
        workCnt: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractUpdateDto.workType]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].descr);
    }
    return arrres;
  };

  const getResultWork = (
    type: number,
    subject: string,
    expressNum: number,
    data: any
  ) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].workType === type && data[i].subject === subject) {
        if (expressNum === EXPRESS_WORK_KOEFF) {
          arrres.push(data[i].expressCost);
          arrres.push(data[i].expressPeriod);
        } else {
          arrres.push(data[i].cost);
          arrres.push(data[i].period);
        }
      }
    }
    if (arrres.length !== 2) {
      arrres.push(0);
      arrres.push(0);
    }
    return arrres;
  };

  const setOrganization = (inputName: string) => {
    setContractUpdateDto({
      ...contractUpdateDto,
      organization: inputName.replaceAll('"', '_'),
    });
  };

  const setAddress = (inputName: string) => {
    setContractUpdateDto({
      ...contractUpdateDto,
      address: inputName.replaceAll('"', '_'),
    });
  };

  const setOther = (inputName: string) => {
    setContractUpdateDto({
      ...contractUpdateDto,
      other: inputName.replaceAll('"', '_'),
    });
  };

  const setSubjectdop = (inputName: string) => {
    if (inputName !== ' ' && inputName !== '' && inputName !== null) {
      const arrtest = getResultWork(
        contractUpdateDto.workType,
        inputName,
        contractUpdateDto.koeff,
        workDtos
      );

      setContractUpdateDto({
        ...contractUpdateDto,
        subject: inputName,
        cost: arrtest[0],
        workCnt: 1,
        workPeriod: arrtest[1],
      });

      setWorkPeriod(arrtest[1]);
    }
  };

  const handleChangeExpressWork = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 1;
    if (event.target.checked) {
      check = EXPRESS_WORK_KOEFF;
    }
    const arrtest = getResultWork(
      contractUpdateDto.workType,
      contractUpdateDto.subject,
      check,
      workDtos
    );
    if (event.target.checked) {
      setContractUpdateDto({
        ...contractUpdateDto,
        koeff: EXPRESS_WORK_KOEFF,
        cost: arrtest[0],
        workPeriod: arrtest[1],
      });
    } else {
      setContractUpdateDto({
        ...contractUpdateDto,
        koeff: 1,
        cost: arrtest[0],
        workPeriod: arrtest[1],
      });
    }
    setWorkPeriod(arrtest[1]);
  };

  const setObject = (inputName: string) => {
    setContractUpdateDto({
      ...contractUpdateDto,
      objectName: inputName.replaceAll('"', '_'),
    });
  };

  const deleteDocument = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const deleteDto: DocumentDeleteDocumentDto = {
      documentId: documentDto.id,
    };
    const apiResult: IApiResult = await apiDeleteDocument(deleteDto);
    if (apiResult.isSuccess) {
      await getDocument();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dtoContract: DocumentGetDocumentDto) => {
    setDocumentDto(dtoContract);
    setOpenDialogConfirmation(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'descr',
      renderHeader: () => <strong>Описание</strong>,
      flex: 1,
      minWidth: 250,
      editable: false,
      renderCell: (row) =>
        row.row.type === '2'
          ? `${row.row.descr} ${row.row.regNum}`
          : `${row.row.descr}`,
    },
    {
      field: 'type',
      renderHeader: () => <strong>Тип</strong>,
      width: 200,
      editable: false,
      renderCell: (row) => ContractDocumentTypeName[row.row.type],
    },
    {
      field: 'datetime',
      renderHeader: () => <strong>Дата загрузки</strong>,
      width: 200,
      editable: false,
    },
    {
      field: 'size',
      renderHeader: () => <strong>Файлов</strong>,
      width: 100,
      editable: false,
      renderCell: (row) => row.row.name.split(';').length - 1,
    },
    {
      field: 'id',
      headerName: '',
      width: 100,
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="left">
          {row.row.name.includes('pdf') && (
            <PictureAsPdfIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
          {row.row.name.includes('doc') && (
            <ArticleIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
        </Stack>
      ),
    },
    {
      field: 'name',
      type: 'actions',
      width: 70,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            disabled={root === ROOT_REGIONGAZ_VALUE && row.row.type !== '2'}
            title="Скачать"
            size="small"
            onClick={() => openFile(row.row.name)}
          >
            <FileDownloadIcon
              fontSize="small"
              sx={{
                color:
                  root === ROOT_REGIONGAZ_VALUE && row.row.type !== '2'
                    ? 'grey'
                    : 'blue',
              }}
            />
          </IconButton>
          {((root === ROOT_CUSTOMER_VALUE &&
            dto.contractStage !== 2 &&
            dto.contractStage !== 3) ||
            root === ROOT_ADMIN_VALUE ||
            root === ROOT_WORKER_VALUE) && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const getRegNum = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationRegNum(false);
      return;
    }
    const apiResult: IApiResult = await apiGetRegNum(dto.workType, dto.id);
    if (apiResult.isSuccess) {
      setRegParams({ ...regParams, regNum: apiResult.data.regNum });
      setDeleteRegNumDto({
        ...deleteRegNumDto,
        regNum: apiResult.data.regNum,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationRegNum(false);
  };

  const confirmGetNewRegNum = () => {
    setOpenDialogConfirmationRegNum(true);
  };

  const deleteRegNum = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationRegNumDelete(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteRegNum(deleteRegNumDto);
    if (apiResult.isSuccess) {
      setRegParams({ ...regParams, regNum: '', regDate: '' });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationRegNumDelete(false);
  };

  const confirmDeleteRegNum = () => {
    setOpenDialogConfirmationRegNumDelete(true);
  };

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme2) => theme2.zIndex.drawer + 1 }}
          open={shouldShowLoader}
          onClick={cancelCreationOfTweet}
        >
          <Stack alignItems="center" spacing={1}>
            <Typography variant="caption" component="div" color="inherit">
              {uploadText}
            </Typography>
            <ProgressBox progress={uploadProgress} />
            <Typography variant="caption" component="div" color="inherit">
              Если нажать на экран, то произойдет отмена загрузки
            </Typography>
          </Stack>
        </Backdrop>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {!isEditObject &&
                valueTabNew === 0 &&
                ((root === ROOT_CUSTOMER_VALUE &&
                  dto.contractStage !== 2 &&
                  dto.contractStage !== 3) ||
                  root !== ROOT_CUSTOMER_VALUE) && (
                  <IconButton size="medium" onClick={handleEditObject}>
                    <EditIcon
                      fontSize="medium"
                      sx={{ color: MAIN_COLOR_STRING }}
                    />
                  </IconButton>
                )}
            </Stack>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={valueTabNew}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    disabled={root === ROOT_REGIONGAZ_VALUE}
                    label="Объект"
                  />
                  <Tab
                    label="Филиал"
                    disabled={
                      root === ROOT_REGIONGAZ_VALUE ||
                      root === ROOT_CUSTOMER_VALUE ||
                      isEditObject
                    }
                  />
                  <Tab disabled={isEditObject} label="Документы" />
                </Tabs>
              </Box>
              <Stack>
                <IconButton size="medium" onClick={() => handleClose()}>
                  <CloseIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack>
            {valueTabNew === 0 && (
              <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={contractUpdateDto.objectName.replaceAll('_', '"')}
                    onChange={(e) => setObject(e.target.value)}
                    disabled={!isEditObject}
                    label="Объект"
                    error={checkHelper}
                    size="small"
                  />
                  <MyTextFiled
                    value={contractUpdateDto.organization.replaceAll('_', '"')}
                    onChange={(e) => setOrganization(e.target.value)}
                    disabled={!isEditObject}
                    label="Организация"
                    error={checkHelper}
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyAutoComplete
                    value={
                      contractUpdateDto.region !== ''
                        ? contractUpdateDto.region
                        : ''
                    }
                    onChange={(e, v) =>
                      setContractUpdateDto({
                        ...contractUpdateDto,
                        region: v !== null ? v : '',
                      })
                    }
                    disabled={!isEditObject}
                    arrayList={Object.values(SpisokRegionovEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    size="small"
                    error={checkHelper}
                    label="Регион"
                  />
                  <MyTextFiled
                    value={contractUpdateDto.address.replaceAll('_', '"')}
                    onChange={(e) => setAddress(e.target.value)}
                    disabled={!isEditObject}
                    label="Адрес"
                    error={checkHelper}
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyAutoComplete
                    value={
                      contractUpdateDto.customerContractId !== -1
                        ? getName(documentContractDtos)[
                            Object.values(
                              getNameId(documentContractDtos)
                            ).indexOf(contractUpdateDto.customerContractId)
                          ]
                        : ''
                    }
                    onChange={(e, v) =>
                      setContractUpdateDto({
                        ...contractUpdateDto,
                        customerContractId:
                          getNameId(documentContractDtos)[
                            Object.values(
                              getName(documentContractDtos)
                            ).indexOf(v)
                          ],
                        customerContractName: v,
                      })
                    }
                    arrayList={getName(documentContractDtos)}
                    disabled={!isEditObject}
                    size="small"
                    error={checkHelper}
                    label="Договор"
                  />
                  <MyAutoComplete
                    value={
                      contractUpdateDto.workType === -1
                        ? ''
                        : WorkTypeEnum[contractUpdateDto.workType]
                    }
                    arrayList={Object.values(WorkTypeEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    onChange={(e, v) =>
                      setContractUpdateDto({
                        ...contractUpdateDto,
                        workType: Object.values(WorkTypeEnum).indexOf(v),
                      })
                    }
                    disabled={!isEditObject}
                    size="small"
                    error={checkHelper}
                    label="Тип работы"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyAutoComplete
                    value={
                      contractUpdateDto.subject !== '' &&
                      contractUpdateDto.subject !== null
                        ? contractUpdateDto.subject
                        : ''
                    }
                    onChange={(e, v) => setSubjectdop(v)}
                    arrayList={getParamArray(
                      contractUpdateDto.workType ?? 0,
                      workDtos
                    )}
                    size="small"
                    error={checkHelper}
                    disabled={!isEditObject}
                    label="Предмет"
                  />
                  <MyDateTextField
                    value={contractUpdateDto.hopeDate}
                    onChange={(e) =>
                      setContractUpdateDto({
                        ...contractUpdateDto,
                        hopeDate: e.target.value,
                      })
                    }
                    disabled={!isEditObject}
                    width="30%"
                    label="Желаемая дата регистрации"
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={
                      contractUpdateDto?.cost !== ''
                        ? `${
                            parseFloat(contractUpdateDto.cost) *
                            contractUpdateDto.workCnt
                          }`
                        : contractUpdateDto?.cost
                    }
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Стоимость, руб"
                    size="small"
                  />
                  <MyTextFiled
                    value={
                      contractUpdateDto.workCnt < 2
                        ? workPeriod
                        : 'Уточнить по телефону'
                    }
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Срок работы, день"
                    size="small"
                  />
                </Stack>
                <FormControlLabel
                  disabled={!isEditObject}
                  control={
                    <Checkbox
                      sx={{ color: MAIN_COLOR_STRING }}
                      onChange={handleChangeExpressWork}
                      checked={contractUpdateDto.koeff === EXPRESS_WORK_KOEFF}
                    />
                  }
                  label="Срочная работа"
                />
                <MyTextFiled
                  value={contractUpdateDto.other.replaceAll('_', '"')}
                  disabled={!isEditObject}
                  onChange={(e) => setOther(e.target.value)}
                  label="Примечание"
                  size="small"
                />

                <Stack direction="row" spacing={1}>
                  <Stack width="100%" direction="row" spacing={1}>
                    <MyTextFiled
                      value={regParams.regNum}
                      // eslint-disable-next-line react/jsx-boolean-value
                      disabled={true}
                      label="Регистрационный номер"
                      size="small"
                    />
                    {(root === ROOT_ADMIN_VALUE ||
                      root === ROOT_WORKER_VALUE) &&
                      regParams.regNum === '' && (
                        <Stack>
                          <IconButton
                            size="medium"
                            disabled={isEditObject}
                            title="Получить номер"
                            onClick={confirmGetNewRegNum}
                          >
                            <AppRegistrationIcon
                              fontSize="medium"
                              sx={{
                                color: isEditObject
                                  ? 'grey'
                                  : MAIN_COLOR_STRING,
                              }}
                            />
                          </IconButton>
                        </Stack>
                      )}
                    {root === ROOT_ADMIN_VALUE && regParams.regNum !== '' && (
                      <Stack>
                        <IconButton
                          size="medium"
                          disabled={isEditObject}
                          title="Удалить номер"
                          onClick={confirmDeleteRegNum}
                        >
                          <DeleteForeverIcon
                            fontSize="medium"
                            sx={{
                              color: isEditObject ? 'grey' : 'red',
                            }}
                          />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                  <MyTextFiled
                    value={dto.regDate}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата регистрации"
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={dto.workStart}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата начала работы"
                    size="small"
                  />
                  <MyTextFiled
                    value={dto.workEnd}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата конца работы"
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={dto.createdDate}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата создания"
                    size="small"
                  />
                  <MyTextFiled
                    value={dto.modificationDate}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата модификации"
                    size="small"
                  />
                </Stack>
                {isEditObject && (
                  <Stack direction="row" spacing={1}>
                    <MyButton
                      text="Отмена"
                      background={MAIN_COLOR_STRING}
                      onClick={() => setIsEditObject(false)}
                    />
                    <MyButton
                      text="Сохранить"
                      background={MAIN_COLOR_STRING}
                      onClick={handleSaveRequest}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            {valueTabNew === 1 && (
              <Stack spacing={2}>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyTextFiled
                    value={customerDto.name.replaceAll('_', '"')}
                    label="Наименование"
                    disabled
                    size="small"
                  />
                  <MyTextFiled
                    value={customerDto.delegate}
                    label="Представитель"
                    disabled
                    size="small"
                  />
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyAutoComplete
                    value={customerDto.region !== '' ? customerDto.region : ''}
                    arrayList={Object.values(SpisokRegionovEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    size="small"
                    disabled
                    label="Регион"
                  />
                  <MyTextFiled
                    value={customerDto.address}
                    label="Адрес"
                    disabled
                    size="small"
                  />
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyTextFiled
                    value={customerDto.inn}
                    label="ИНН"
                    disabled
                    size="small"
                  />
                  <MyTextFiled
                    value={customerDto.phone}
                    disabled
                    label="Телефон"
                    size="small"
                  />
                  <MyTextFiled
                    value={customerDto.email}
                    disabled
                    label="E-mail"
                    size="small"
                  />
                </Stack>
              </Stack>
            )}
            {valueTabNew === 2 && (
              <Stack spacing={2}>
                <Box sx={{ width: '100%' }}>
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                          {
                            py: 1,
                          },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                          {
                            py: '5px',
                          },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                          {
                            py: '10px',
                          },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                          {
                            outline: 'none !important',
                          },
                      }}
                      getRowHeight={() => 'auto'}
                      columns={columns}
                      rows={documentDtos}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...other}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </ThemeProvider>
                </Box>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteDocument}
      />
      <DialogConfirmationRegNum
        open={openDialogConfirmationRegNum}
        onClose={getRegNum}
      />
      <DialogConfirmationCustom
        open={openDialogConfirmationRegNumDelete}
        message="Вы хотите удалить регистрационный номер?"
        onClose={deleteRegNum}
      />
    </div>
  );
};
