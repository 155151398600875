import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { FormDeleteFormDto } from '../../_dto/_form/FormDeleteFormDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteDocument = async (formDeleteFormDto: FormDeleteFormDto) => {
  const params: IApiParams = {
    url: '/form/deleteForm.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'DELETE', formDeleteFormDto);
};