import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyButton } from '../../UI/MyButton';
import { ReestrDto } from '../../../_dto/_reestr/ReestrDto';
import { ContractGetContractDto } from '../../../_dto/_contract/ContractGetContractDto';
import { ContractUpdateRegionNumDto } from '../../../_dto/_contract/ContractUpdateRegionNumDto';
import { apiChangeRegionNum } from '../../../services/contract/apiChangeRegionNum';
import { apiChangeRegionNumReestr } from '../../../services/reestr/apiChangeRegionNum';
import { MyTextFiled } from '../../UI/MyTextField';

interface Props {
  open: boolean;
  isFilial: boolean;
  onClose: () => void;
  dtoReestr?: ReestrDto;
  dtoFilial?: ContractGetContractDto;
}

export const DialogChangeRegNum: FunctionComponent<Props> = ({
  open,
  isFilial,
  onClose,
  dtoReestr,
  dtoFilial,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [updateRegionNumDto, setUpdateRegionNumDto] =
    useState<ContractUpdateRegionNumDto>({
      id: -1,
      regionNum: '',
    });

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const changeFilial = async () => {
    const apiResult: IApiResult = await apiChangeRegionNum(updateRegionNumDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const changeReestr = async () => {
    const apiResult: IApiResult = await apiChangeRegionNumReestr(
      updateRegionNumDto
    );
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (updateRegionNumDto.regionNum === '') {
      // setCheckHelper(true);
      // return;
    }
    if (isFilial) {
      await changeFilial();
    }
    if (!isFilial) {
      await changeReestr();
    }
  };

  useEffect(() => {
    if (open) {
      if (isFilial && dtoFilial !== undefined) {
        setUpdateRegionNumDto({
          id: dtoFilial.id,
          regionNum: dtoFilial.regionNum,
        });
      }
      if (!isFilial && dtoReestr !== undefined) {
        setUpdateRegionNumDto({
          id: dtoReestr.id,
          regionNum: dtoReestr.regionNum,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Присвоить номер
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <MyTextFiled
              value={updateRegionNumDto.regionNum}
              onChange={(e) =>
                setUpdateRegionNumDto({
                  ...updateRegionNumDto,
                  regionNum: e.target.value,
                })
              }
              multiLine
              error={checkHelper}
              label="Регистрационный номер"
              size="small"
            />
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleSave}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogChangeRegNum.defaultProps = {
  dtoReestr: undefined,
  dtoFilial: undefined,
};
