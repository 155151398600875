import {
  ACCESS_TOKEN_NAME,
  FORM_DATA_CONTENT_TYPE,
} from '../_constant/constants';
import { IApiParams } from '../_interface/IApiParams';
import { IFileUpload } from '../_interface/IFileUpload';
import { getApi } from './getApi';

export const apiUploadFile = async (
  type: number,
  fileUpload: IFileUpload,
  onUploadProgress?: (e: any) => void,
  cancelToken?: any,
  regNum?: string
) => {
  const params: IApiParams = {
    url:
      regNum === undefined
        ? `/addFile.php?type=${type}`
        : `/addFile.php?type=${type}&regNum=${regNum}`,
    headers: {
      'Content-Type': FORM_DATA_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', fileUpload.data, onUploadProgress, cancelToken);
};
