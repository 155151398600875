import React, { FunctionComponent, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getSimvol } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyPasswordTextField } from '../../UI/MyPasswordTextField';
import { MyButton } from '../../UI/MyButton';
import { RegionGazSignUpDto } from '../../../_dto/_regiongaz/RegionGazSignUpDto';
import { apiSignUpRegionGaz } from '../../../services/regiongaz/apiSignUpRegionGaz';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCreateRegionGaz: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [regionGazSignUp, setRegionGazSignUp] = useState<RegionGazSignUpDto>({
    login: '',
    password: '',
    name: '',
    region: '',
    isDownloadAct: -1,
    isDownloadExpertise: -1,
    isDownloadMethodology: -1,
    isOwnRegNum: -1,
    isOpenFilial: -1,
    isOpenReestr: -1,
  });

  const handleClose = () => {
    onClose();
    setRegionGazSignUp({
      login: '',
      password: '',
      name: '',
      region: '',
      isDownloadAct: -1,
      isDownloadExpertise: -1,
      isDownloadMethodology: -1,
      isOwnRegNum: -1,
      isOpenFilial: -1,
      isOpenReestr: -1,
    });
    setCheckHelper(false);
  };

  const signUp = async () => {
    const apiResult: IApiResult = await apiSignUpRegionGaz(regionGazSignUp);
    if (apiResult.isSuccess) {
      handleClose();
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: 'Такой логин существует',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const generateLogin = () => {
    setRegionGazSignUp({
      ...regionGazSignUp,
      login: getSimvol(8),
      password: getSimvol(8),
    });
  };

  const handleChangeAct = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isDownloadAct: check,
    });
  };

  const handleChangeFilial = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isOpenFilial: check,
    });
  };

  const handleChangeReestr = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isOpenReestr: check,
    });
  };

  const handleChangeExpertise = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isDownloadExpertise: check,
    });
  };

  const handleChangeMethodology = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isDownloadMethodology: check,
    });
  };

  const handleChangeOwnRegNum = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isOwnRegNum: check,
    });
  };

  const handleSignUp = async () => {
    if (
      regionGazSignUp.login === '' ||
      regionGazSignUp.region === '' ||
      regionGazSignUp.name === '' ||
      regionGazSignUp.password === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await signUp();
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Регистрация
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={regionGazSignUp.name}
                onChange={(e) =>
                  setRegionGazSignUp({
                    ...regionGazSignUp,
                    name: e.target.value,
                  })
                }
                label="Наименование"
                error={checkHelper}
                size="small"
              />
              <MyAutoComplete
                value={
                  regionGazSignUp.region !== '' ? regionGazSignUp.region : ''
                }
                onChange={(e, v) =>
                  setRegionGazSignUp({
                    ...regionGazSignUp,
                    region: v !== null ? v : '',
                  })
                }
                arrayList={Object.values(SpisokRegionovEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                maxHeight="100px"
                error={checkHelper}
                label="Регион"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={regionGazSignUp.login}
                onChange={(e) =>
                  setRegionGazSignUp({
                    ...regionGazSignUp,
                    login: e.target.value,
                  })
                }
                label="Логин"
                error={checkHelper}
                size="small"
              />
              <Stack
                width="100%"
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <MyPasswordTextField
                  value={regionGazSignUp.password}
                  onChange={(e) =>
                    setRegionGazSignUp({
                      ...regionGazSignUp,
                      password: e.target.value,
                    })
                  }
                  label="Пароль"
                  error={checkHelper}
                  size="small"
                />
                <Stack>
                  <IconButton
                    title="Создать логин и пароль"
                    size="small"
                    onClick={() => generateLogin()}
                  >
                    <AutoFixHighIcon
                      fontSize="small"
                      sx={{ color: MAIN_COLOR_STRING }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeFilial}
                  />
                }
                label="Разрешение на просмотр филиальных работ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeReestr}
                  />
                }
                label="Разрешение на просмотр прямых договоров"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeAct}
                  />
                }
                label="Разрешение на скачивание акта"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeExpertise}
                  />
                }
                label="Разрешение на скачивание экспертизы"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeMethodology}
                  />
                }
                label="Разрешение на скачивание методики измерений"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeOwnRegNum}
                  />
                }
                label="Разрешение на ввод своего номера"
              />
            </Stack>
            <MyButton
              text="Зарегистрировать"
              background={MAIN_COLOR_STRING}
              onClick={handleSignUp}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
