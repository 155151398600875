import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { WorkerGetDto } from '../../../_dto/_worker/WorkerGetDto';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { apiUpdateWorker } from '../../../services/worker/apiUpdateWorker';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { WorkerUpdateWorkerDto } from '../../../_dto/_worker/WorkerUpdateWorkerDto';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { MyButton } from '../../UI/MyButton';
import { apiGetLinkCustomer } from '../../../services/worker/apiGetLinkCustomer';
import { WorkerLinkCustomerDto } from '../../../_dto/_worker/WorkerLinkCustomerDto';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: WorkerGetDto;
}

export const DialogMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [linkCustomerDto, setLinkCustomerDto] = useState<WorkerLinkCustomerDto>(
    {
      linkCustomer: '',
    }
  );
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };
  const [workerSignUpDto, setWorkerSignUpDto] = useState<WorkerUpdateWorkerDto>(
    {
      login: dto?.login ?? '',
      password: '',
      name: dto?.name ?? '',
      position: dto?.position ?? '',
      phone: dto?.phone ?? '',
      email: dto?.email ?? '',
      root: dto?.root ?? 0,
      birthDate: dto?.birthDate ?? '',
      employmentDate: dto?.employmentDate ?? '',
      code: dto?.code ?? '',
    }
  );

  const handleClose = () => {
    onClose();
    setIsEdit(false);
    setCheckHelper(false);
  };

  const getLinkCustomer = async () => {
    const apiResult: IApiResult = await apiGetLinkCustomer(dto?.id);
    if (apiResult.isSuccess) {
      setLinkCustomerDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const updateWorker = async () => {
    const apiResult: IApiResult = await apiUpdateWorker(workerSignUpDto);
    if (apiResult.isSuccess) {
      handleClose();
      setIsEdit(false);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (workerSignUpDto.name === '' || workerSignUpDto.code === '') {
      setCheckHelper(true);
      return;
    }
    await updateWorker();
  };

  useEffect(() => {
    if (open) {
      setWorkerSignUpDto({
        login: dto?.login ?? '',
        password: '',
        name: dto?.name ?? '',
        position: dto?.position ?? '',
        phone: dto?.phone ?? '',
        email: dto?.email ?? '',
        root: dto?.root ?? 0,
        birthDate: dto?.birthDate ?? '',
        employmentDate: dto?.employmentDate ?? '',
        code: dto?.code ?? '',
      });
      getLinkCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {!isEdit && (
                <IconButton size="medium" onClick={handleEdit}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={workerSignUpDto.name}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    name: e.target.value,
                  })
                }
                disabled={!isEdit}
                error={checkHelper}
                label="Полное имя"
                size="small"
              />
              <MyTextFiled
                value={workerSignUpDto.position}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    position: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Должность"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={workerSignUpDto.code}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    code: e.target.value,
                  })
                }
                disabled={!isEdit}
                error={checkHelper}
                label="Шифр"
                size="small"
              />
              <MyTextFiled
                value={workerSignUpDto.phone}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    phone: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Телефон"
                size="small"
              />
              <MyTextFiled
                value={workerSignUpDto.email}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    email: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="E-mail"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={2}>
              <MyTextFiled
                value={workerSignUpDto.login}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    login: e.target.value,
                  })
                }
                disabled
                label="Логин"
                size="small"
              />
              <MyDateTextField
                value={workerSignUpDto.birthDate}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    birthDate: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Дата рождения"
                size="small"
              />
              <MyDateTextField
                value={workerSignUpDto.employmentDate}
                onChange={(e) =>
                  setWorkerSignUpDto({
                    ...workerSignUpDto,
                    employmentDate: e.target.value,
                  })
                }
                disabled={!isEdit}
                label="Дата устройства на работу"
                size="small"
              />
            </Stack>
            <MyTextFiled
              value={linkCustomerDto.linkCustomer}
              disabled
              label="Привязанные филиалы"
              size="small"
            />
            <Stack width="100%" direction="row" spacing={2}>
              <MyTextFiled
                value={`${dto?.createdDate}`}
                disabled
                label="Дата создания"
                size="small"
              />
              <MyTextFiled
                value={`${dto?.modificationDate}`}
                disabled
                label="Дата модификации"
                size="small"
              />
            </Stack>
            {isEdit && (
              <Stack direction="row" spacing={1}>
                <MyButton
                  text="Отмена"
                  background={MAIN_COLOR_STRING}
                  onClick={() => setIsEdit(false)}
                />
                <MyButton
                  text="Сохранить"
                  background={MAIN_COLOR_STRING}
                  onClick={handleSave}
                />
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogMoreInfo.defaultProps = {
  dto: undefined,
};
