import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { ChatDeleteMessageDto } from '../../_dto/_chat/ChatDeleteMessageDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteMessage = async (chatDeleteMessageDto: ChatDeleteMessageDto) => {
  const params: IApiParams = {
    url: '/chat/deleteMessage.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'DELETE', chatDeleteMessageDto);
};