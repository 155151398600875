import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getSimvol } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { apiSignUp } from '../../../services/customer/apiSignUp';
import { MyPasswordTextField } from '../../UI/MyPasswordTextField';
import { MyButton } from '../../UI/MyButton';
import { CustomerSignUpDto } from '../../../_dto/_customer/CustomerSignUpDto';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';
import { WorkerGetDto } from '../../../_dto/_worker/WorkerGetDto';
import { apiGetWorker } from '../../../services/apiGetWorker';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCustomerCreate: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);
  const [getWorkerDtos, setGetWorkerDtos] = useState<WorkerGetDto[]>([]);
  const getWorker = async () => {
    const apiResult: IApiResult = await apiGetWorker();
    if (apiResult.isSuccess) {
      setGetWorkerDtos(apiResult.data.worker);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].name);
    }
    return arrres;
  };

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  const [customerSignUpDto, setCustomerSignUpDto] = useState<CustomerSignUpDto>(
    {
      login: '',
      password: '',
      region: '',
      name: '',
      inn: '',
      phone: '',
      email: '',
      address: '',
      other: '',
      code: '',
      delegate: '',
      workerId: -1,
    }
  );

  const setCustomerName = (inputName: string) => {
    setCustomerSignUpDto({
      ...customerSignUpDto,
      name: inputName.replaceAll('"', '_'),
    });
  };

  const handleClose = () => {
    onClose();
    setCustomerSignUpDto({
      login: '',
      password: '',
      region: '',
      name: '',
      inn: '',
      phone: '',
      email: '',
      address: '',
      other: '',
      code: '',
      delegate: '',
      workerId: -1,
    });
    setCheckHelper(false);
  };

  const signUp = async () => {
    const apiResult: IApiResult = await apiSignUp(customerSignUpDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: 'Такой логин существует',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const generateLogin = () => {
    setCustomerSignUpDto({
      ...customerSignUpDto,
      login: getSimvol(8),
      password: getSimvol(8),
    });
  };

  const handleSignUp = async () => {
    if (
      customerSignUpDto.login === '' ||
      customerSignUpDto.workerId === -1 ||
      customerSignUpDto.name === '' ||
      customerSignUpDto.password === '' ||
      customerSignUpDto.region === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await signUp();
  };

  useEffect(() => {
    if (open) {
      getWorker();
    }
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Регистрация
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={customerSignUpDto.name.replaceAll('_', '"')}
                onChange={(e) => setCustomerName(e.target.value)}
                label="Наименование"
                error={checkHelper}
                size="small"
              />
              <MyTextFiled
                value={customerSignUpDto.delegate}
                onChange={(e) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    delegate: e.target.value,
                  })
                }
                label="Представитель"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyAutoComplete
                value={
                  customerSignUpDto.region !== ''
                    ? customerSignUpDto.region
                    : ''
                }
                onChange={(e, v) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    region: v !== null ? v : '',
                  })
                }
                arrayList={Object.values(SpisokRegionovEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                label="Регион"
                error={checkHelper}
              />
              <MyTextFiled
                value={customerSignUpDto.address}
                onChange={(e) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    address: e.target.value,
                  })
                }
                label="Адрес"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={customerSignUpDto.inn}
                onChange={(e) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    inn: e.target.value,
                  })
                }
                label="ИНН"
                size="small"
              />
              <MyTextFiled
                value={customerSignUpDto.phone}
                onChange={(e) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    phone: e.target.value,
                  })
                }
                label="Телефон"
                size="small"
              />
              <MyTextFiled
                value={customerSignUpDto.email}
                onChange={(e) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    email: e.target.value,
                  })
                }
                label="E-mail"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={customerSignUpDto.login}
                onChange={(e) =>
                  setCustomerSignUpDto({
                    ...customerSignUpDto,
                    login: e.target.value,
                  })
                }
                label="Логин"
                error={checkHelper}
                size="small"
              />
              <Stack
                width="100%"
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <MyPasswordTextField
                  value={customerSignUpDto.password}
                  onChange={(e) =>
                    setCustomerSignUpDto({
                      ...customerSignUpDto,
                      password: e.target.value,
                    })
                  }
                  label="Пароль"
                  error={checkHelper}
                  size="small"
                />
                <Stack>
                  <IconButton
                    title="Создать логин и пароль"
                    size="small"
                    onClick={() => generateLogin()}
                  >
                    <AutoFixHighIcon
                      fontSize="small"
                      sx={{ color: MAIN_COLOR_STRING }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
            <MyAutoComplete
              value={
                customerSignUpDto.workerId !== -1 &&
                customerSignUpDto.workerId !== null &&
                customerSignUpDto.workerId !== undefined
                  ? getName(getWorkerDtos)[
                      Object.values(getNameId(getWorkerDtos)).indexOf(
                        customerSignUpDto.workerId
                      )
                    ]
                  : ''
              }
              onChange={(e, v) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  workerId:
                    getNameId(getWorkerDtos)[
                      Object.values(getName(getWorkerDtos)).indexOf(v)
                    ],
                })
              }
              arrayList={getName(getWorkerDtos)}
              size="small"
              error={checkHelper}
              label="Привязать исполнителя"
            />
            <MyTextFiled
              value={customerSignUpDto.other}
              onChange={(e) =>
                setCustomerSignUpDto({
                  ...customerSignUpDto,
                  other: e.target.value,
                })
              }
              label="Примечание"
              multiLine
              size="small"
            />
            <MyButton
              text="Зарегистрировать"
              background={MAIN_COLOR_STRING}
              onClick={handleSignUp}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
