import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { ContractChangeWorkerDto } from '../../_dto/_contract/ContractChangeWorkerDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiChangeWorker = async (contractChangeWorkerDto: ContractChangeWorkerDto) => {
  const params: IApiParams = {
    url: '/contract/changeWorker.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'PUT', contractChangeWorkerDto);
};