import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { WorkerDiv } from '../component/worker/WorkerDiv';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const WorkerPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<WorkerDiv />}
  />
);
