import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { CustomerTelegramDto } from '../../_dto/_customer/CustomerTelegramDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiTelegramId = async (dto: CustomerTelegramDto) => {
  const params: IApiParams = {
    url: '/customer/addIdTelegram.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', dto);
};
