import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getSimvol } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyPasswordTextField } from '../../UI/MyPasswordTextField';
import { MyButton } from '../../UI/MyButton';
import { AccountantGetDto } from '../../../_dto/_accountant/AccountantGetDto';
import { apiChangePasswordAccountant } from '../../../services/accountant/apiChangePasswordAccountant';
import { AccountantChangePasswordDto } from '../../../_dto/_accountant/AccountantChangePasswordDto';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: AccountantGetDto;
}

export const DialogChangePasswordAccountant: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [accountantChangePasswordDto, setAccountantChangePasswordDto] =
    useState<AccountantChangePasswordDto>({
      id: dto?.id ?? -1,
      password: '',
    });

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const changePassword = async () => {
    const apiResult: IApiResult = await apiChangePasswordAccountant(
      accountantChangePasswordDto
    );
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };
  const generateLogin = () => {
    setAccountantChangePasswordDto({
      ...accountantChangePasswordDto,
      password: getSimvol(8),
    });
  };

  const handleSave = async () => {
    if (accountantChangePasswordDto.password === '') {
      setCheckHelper(true);
      return;
    }
    await changePassword();
  };

  useEffect(() => {
    if (open) {
      setAccountantChangePasswordDto({
        id: dto?.id ?? -1,
        password: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Смена пароля
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" alignItems="center" direction="row" spacing={1}>
              <MyPasswordTextField
                value={accountantChangePasswordDto.password}
                onChange={(e) =>
                  setAccountantChangePasswordDto({
                    ...accountantChangePasswordDto,
                    password: e.target.value,
                  })
                }
                label="Пароль"
                error={checkHelper}
                size="small"
              />
              <Stack>
                <IconButton
                  title="Создать пароль"
                  size="small"
                  onClick={() => generateLogin()}
                >
                  <AutoFixHighIcon
                    fontSize="small"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <MyButton
              text="Сменить"
              background={MAIN_COLOR_STRING}
              onClick={handleSave}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogChangePasswordAccountant.defaultProps = {
  dto: undefined,
};
