import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../../_constant/constants';
import { WorkerChangePasswordDto } from '../../_dto/_worker/WorkerChangePasswordDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiChangePassword = async (workerChangePasswordDto: WorkerChangePasswordDto) => {
  const params: IApiParams = {
    url: '/worker/changePassword.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME), 
    },
  };
  return getApi(params, 'PUT', workerChangePasswordDto);
};