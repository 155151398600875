import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { RegionGazGetDto } from '../../../_dto/_regiongaz/RegionGazGetDto';
import { RegionGazUpdateDto } from '../../../_dto/_regiongaz/RegionGazUpdateDto';
import { apiUpdateRegionGaz } from '../../../services/regiongaz/apiUpdateRegionGaz';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: RegionGazGetDto;
}

export const DialogRegionGazMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };

  const [regionGazSignUp, setRegionGazSignUp] = useState<RegionGazUpdateDto>({
    login: dto?.login ?? '',
    name: dto?.name ?? '',
    region: dto?.region ?? '',
    isDownloadAct: -1,
    isDownloadExpertise: -1,
    isDownloadMethodology: -1,
    isOwnRegNum: -1,
    isOpenFilial: -1,
    isOpenReestr: -1,
  });

  const handleClose = () => {
    onClose();
    setIsEdit(false);
    setCheckHelper(false);
  };

  const updateRegionGaz = async () => {
    const apiResult: IApiResult = await apiUpdateRegionGaz(regionGazSignUp);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (regionGazSignUp.name === '' || regionGazSignUp.region === '') {
      setCheckHelper(true);
      return;
    }
    await updateRegionGaz();
  };

  const setRegionGazName = (inputName: string) => {
    setRegionGazSignUp({
      ...regionGazSignUp,
      name: inputName.replaceAll('"', '_'),
    });
  };

  const handleChangeAct = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isDownloadAct: check,
    });
  };

  const handleChangeFilial = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isOpenFilial: check,
    });
  };

  const handleChangeReestr = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isOpenReestr: check,
    });
  };

  const handleChangeExpertise = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isDownloadExpertise: check,
    });
  };

  const handleChangeMethodology = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isDownloadMethodology: check,
    });
  };

  const handleChangeOwnRegNum = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 0;
    if (event.target.checked) {
      check = 1;
    }
    setRegionGazSignUp({
      ...regionGazSignUp,
      isOwnRegNum: check,
    });
  };

  useEffect(() => {
    if (open) {
      setRegionGazSignUp({
        login: dto?.login ?? '',
        name: dto?.name ?? '',
        region: dto?.region ?? '',
        isDownloadAct: dto?.isDownloadAct ?? -1,
        isDownloadExpertise: dto?.isDownloadExpertise ?? -1,
        isDownloadMethodology: dto?.isDownloadMethodology ?? -1,
        isOwnRegNum: dto?.isOwnRegNum ?? -1,
        isOpenFilial: dto?.isOpenFilial ?? -1,
        isOpenReestr: dto?.isOpenReestr ?? -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {!isEdit && (
                <IconButton size="medium" onClick={handleEdit}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={regionGazSignUp.name}
                onChange={(e) => setRegionGazName(e.target.value)}
                disabled={!isEdit}
                label="Наименование"
                size="small"
                error={checkHelper}
              />
              <MyAutoComplete
                value={
                  regionGazSignUp.region !== '' ? regionGazSignUp.region : ' '
                }
                onChange={(e, v) =>
                  setRegionGazSignUp({
                    ...regionGazSignUp,
                    region: v !== null ? v : ' ',
                  })
                }
                arrayList={Object.values(SpisokRegionovEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                maxHeight="100px"
                disabled={!isEdit}
                label="Регион"
                error={checkHelper}
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={regionGazSignUp.login}
                onChange={(e) =>
                  setRegionGazSignUp({
                    ...regionGazSignUp,
                    login: e.target.value,
                  })
                }
                disabled
                label="Логин"
                size="small"
              />
            </Stack>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeFilial}
                    checked={regionGazSignUp.isOpenFilial === 1}
                  />
                }
                disabled={!isEdit}
                label="Разрешение на просмотр филиальных работ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeReestr}
                    checked={regionGazSignUp.isOpenReestr === 1}
                  />
                }
                disabled={!isEdit}
                label="Разрешение на просмотр прямых договоров"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeAct}
                    checked={regionGazSignUp.isDownloadAct === 1}
                  />
                }
                disabled={!isEdit}
                label="Разрешение на скачивание акта"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeExpertise}
                    checked={regionGazSignUp.isDownloadExpertise === 1}
                  />
                }
                disabled={!isEdit}
                label="Разрешение на скачивание экспертизы"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeMethodology}
                    checked={regionGazSignUp.isDownloadMethodology === 1}
                  />
                }
                disabled={!isEdit}
                label="Разрешение на скачивание методики измерений"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: MAIN_COLOR_STRING }}
                    onChange={handleChangeOwnRegNum}
                    checked={regionGazSignUp.isOwnRegNum === 1}
                  />
                }
                disabled={!isEdit}
                label="Разрешение на ввод своего номера"
              />
            </Stack>
            {isEdit && (
              <Stack direction="row" spacing={1}>
                <MyButton
                  text="Отмена"
                  background={MAIN_COLOR_STRING}
                  onClick={() => setIsEdit(false)}
                />
                <MyButton
                  text="Сохранить"
                  background={MAIN_COLOR_STRING}
                  onClick={handleSave}
                />
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogRegionGazMoreInfo.defaultProps = {
  dto: undefined,
};
