import React, { FunctionComponent, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getSimvol } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyPasswordTextField } from '../../UI/MyPasswordTextField';
import { MyButton } from '../../UI/MyButton';
import { AccountantSignUpDto } from '../../../_dto/_accountant/AccountantSignUpDto';
import { apiSignUpAccountant } from '../../../services/accountant/apiSignUpAccountant';
import { MyDateTextField } from '../../UI/MyDateTextField';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCreateAccountant: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [accountantSignUp, setAccountantSignUp] = useState<AccountantSignUpDto>(
    {
      login: '',
      password: '',
      name: '',
      position: '',
      phone: '',
      email: '',
      birthDate: '',
      employmentDate: '',
    }
  );

  const handleClose = () => {
    setAccountantSignUp({
      login: '',
      password: '',
      name: '',
      position: '',
      phone: '',
      email: '',
      birthDate: '',
      employmentDate: '',
    });
    onClose();
    setCheckHelper(false);
  };

  const signUp = async () => {
    const apiResult: IApiResult = await apiSignUpAccountant(accountantSignUp);
    if (apiResult.isSuccess) {
      handleClose();
    } else if (apiResult.status === 409) {
      setAlertProps({
        message: 'Такой логин существует',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const generateLogin = () => {
    setAccountantSignUp({
      ...accountantSignUp,
      login: getSimvol(8),
      password: getSimvol(8),
    });
  };

  const handleSignUp = async () => {
    if (
      accountantSignUp.login === '' ||
      accountantSignUp.name === '' ||
      accountantSignUp.password === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await signUp();
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Регистрация
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={accountantSignUp.name}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    name: e.target.value,
                  })
                }
                error={checkHelper}
                label="Полное имя"
                size="small"
              />
              <MyTextFiled
                value={accountantSignUp.position}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    position: e.target.value,
                  })
                }
                label="Должность"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={accountantSignUp.phone}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    phone: e.target.value,
                  })
                }
                label="Телефон"
                size="small"
              />
              <MyTextFiled
                value={accountantSignUp.email}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    email: e.target.value,
                  })
                }
                label="E-mail"
                size="small"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={accountantSignUp.login}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    login: e.target.value,
                  })
                }
                error={checkHelper}
                label="Логин"
                size="small"
              />
              <Stack
                width="100%"
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <MyPasswordTextField
                  value={accountantSignUp.password}
                  onChange={(e) =>
                    setAccountantSignUp({
                      ...accountantSignUp,
                      password: e.target.value,
                    })
                  }
                  label="Пароль"
                  error={checkHelper}
                  size="small"
                />
                <Stack>
                  <IconButton
                    title="Создать логин и пароль"
                    size="small"
                    onClick={() => generateLogin()}
                  >
                    <AutoFixHighIcon
                      fontSize="small"
                      sx={{ color: MAIN_COLOR_STRING }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <MyDateTextField
                value={accountantSignUp.birthDate}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    birthDate: e.target.value,
                  })
                }
                label="Дата рождения"
                size="small"
              />
              <MyDateTextField
                value={accountantSignUp.employmentDate}
                onChange={(e) =>
                  setAccountantSignUp({
                    ...accountantSignUp,
                    employmentDate: e.target.value,
                  })
                }
                label="Дата устройства на работу"
                size="small"
              />
            </Stack>
            <MyButton
              text="Зарегистрировать"
              background={MAIN_COLOR_STRING}
              onClick={handleSignUp}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
