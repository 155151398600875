import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { RegionGazDeleteDto } from '../../_dto/_regiongaz/RegionGazDeleteDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteRegionGaz = async (dto: RegionGazDeleteDto) => {
  const params: IApiParams = {
    url: '/regiongaz/delete.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'DELETE', dto);
};
