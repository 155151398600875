import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import TelegramIcon from '@mui/icons-material/Telegram';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
} from '../../_constant/constants';
import { IApiResult } from '../../_interface/IApiResult';
import { apiGetWorker } from '../../services/apiGetWorker';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { WorkerGetDto } from '../../_dto/_worker/WorkerGetDto';
import { MyButton } from '../UI/MyButton';
import { WorkerDeleteDto } from '../../_dto/_worker/WorkerDeleteDto';
import { apiDelete } from '../../services/worker/apiDelete';
import { DialogMoreInfo } from '../dialog/worker/DialogMoreInfo';
import { DialogChangePassword } from '../dialog/worker/DialogChangePassword';
import { DialogWorkerCreate } from '../dialog/worker/DialogWorkerCreate';
import { DialogAddTelegram } from '../dialog/worker/DialogAddTelegram';
import { DialogConfirmation } from '../dialog/DialogConfirmation';

export const WorkerDiv = () => {
  const root = getRoot();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogTelegramId, setOpenDialogTelegramId] = useState(false);
  const [getWorkerDto, setGetWorkerDto] = useState<WorkerGetDto>({
    id: -1,
    login: '',
    name: '',
    position: '',
    phone: '',
    email: '',
    root: -1,
    birthDate: '',
    employmentDate: '',
    lockUser: -1,
    completedContract: -1,
    activeContract: -1,
    createdDate: '',
    modificationDate: '',
    code: '',
    telegramId: '',
  });
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [getWorkerDtos, setGetWorkerDtos] = useState<WorkerGetDto[]>([]);

  const getWorker = async () => {
    const apiResult: IApiResult = await apiGetWorker();
    if (apiResult.isSuccess) {
      setGetWorkerDtos(apiResult.data.worker);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (!openDialog && !openDialogCreate && !openDialogTelegramId) {
      getWorker();
    }
  }, [openDialog, openDialogCreate, openDialogTelegramId]);

  const deleteWorker = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const workerDeleteDto: WorkerDeleteDto = {
      id: getWorkerDto.id,
    };
    const apiResult: IApiResult = await apiDelete(workerDeleteDto);
    if (apiResult.isSuccess) {
      await getWorker();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: WorkerGetDto) => {
    setGetWorkerDto(dto);
    setOpenDialogConfirmation(true);
  };

  const getMoreInformation = (dto: WorkerGetDto) => {
    setGetWorkerDto(dto);
    setOpenDialog(true);
  };

  const changePassword = (dto: WorkerGetDto) => {
    setGetWorkerDto(dto);
    setOpenDialogPassword(true);
  };

  const addTelegram = (dto: WorkerGetDto) => {
    setGetWorkerDto(dto);
    setOpenDialogTelegramId(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      renderHeader: () => <strong>Полное имя</strong>,
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    {
      field: 'position',
      width: 400,
      renderHeader: () => <strong>Должность</strong>,
      editable: false,
    },
    {
      field: 'email',
      width: 250,
      renderHeader: () => <strong>Почта</strong>,
      editable: false,
    },
    {
      field: 'phone',
      width: 250,
      renderHeader: () => <strong>Телефон</strong>,
      editable: false,
    },
    {
      field: 'telegramId',
      width: 150,
      renderHeader: () => <strong>Телеграм ID</strong>,
      editable: false,
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: root === ROOT_ADMIN_VALUE ? 140 : 35,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="left">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <PersonSearchIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Добавить уведомления в телеграмм"
              size="small"
              onClick={() => addTelegram(row.row)}
            >
              <TelegramIcon
                fontSize="small"
                sx={{ color: row.row.telegramId !== '' ? 'blue' : 'grey' }}
              />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && row.row.root !== 4 && (
            <IconButton
              title="Смена пароля"
              size="small"
              onClick={() => changePassword(row.row)}
            >
              <SyncLockIcon fontSize="small" sx={{ color: 'purple' }} />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && row.row.root !== 4 && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Исполнители
            </Typography>
            <MyButton
              text="Создать"
              width="100px"
              height="30px"
              background={MAIN_COLOR_STRING}
              onClick={() => setOpenDialogCreate(true)}
            />
          </Stack>
          <Divider />
          <div>
            <Box sx={{ width: '100%' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={getWorkerDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </Stack>
      </Card>
      <DialogWorkerCreate
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogMoreInfo
        open={openDialog}
        dto={getWorkerDto}
        onClose={() => setOpenDialog(false)}
      />
      <DialogChangePassword
        open={openDialogPassword}
        dto={getWorkerDto}
        onClose={() => setOpenDialogPassword(false)}
      />
      <DialogAddTelegram
        open={openDialogTelegramId}
        dto={getWorkerDto}
        onClose={() => setOpenDialogTelegramId(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteWorker}
      />
    </Stack>
  );
};
