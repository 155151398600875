import {
  Badge,
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BoltIcon from '@mui/icons-material/Bolt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  getContracState,
  getDefaultAlertProps,
  getRoot,
} from '../../utils/utils';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
  ROOT_CUSTOMER_VALUE,
  ROOT_WORKER_VALUE,
} from '../../_constant/constants';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { IApiResult } from '../../_interface/IApiResult';
import { MyAlert } from '../UI/MyAlert';
import { RequestDto } from '../../_dto/_request/RequestDto';
import { DialogRequestCreate } from '../dialog/request/DialogRequestCreate';
import { DialogMoreInfo } from '../dialog/request/DialogMoreInfo';
import { apiGetContract } from '../../services/contract/apiGetContract.';
import { ContractGetContractDto } from '../../_dto/_contract/ContractGetContractDto';
import { apiDeleteContract } from '../../services/contract/apiDeleteContract';
import { ContractDeleteContractDto } from '../../_dto/_contract/ContractDeleteContractDto';
import { ContractChangeStageDto } from '../../_dto/_contract/ContractChangeStageDto';
import { DialogDocumentAdd } from '../dialog/document/DialogDocumentAdd';
import { ContractDocumentType } from '../../_enum/ContractDocumentType';
import { apiChangeStageWorker } from '../../services/contract/apiChangeStageWorker';
import { DialogChangeWorker } from '../dialog/worker/DialogChangeWorker';
import { DialogChat } from '../dialog/chat/DialogChat';
import { WorkTypeEnum } from '../../_enum/WorkTypeEnum';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { RequestChangeStage } from '../../_dto/_request/RequestChangeStage';
import { apiChangeStage } from '../../services/request/apiChangeStage';
import { DialogConfirmationCustom } from '../dialog/DialogConfirmationCustom';
import { ReestrDto } from '../../_dto/_reestr/ReestrDto';
import { apiGetReestrStart } from '../../services/reestr/apiGetReestrStart';
import { DialogReestrMoreInfo } from '../dialog/reestr/DialogReestrMoreInfo';
import { DialogAddPdf } from '../dialog/reestr/DialogAddPdf';
import { ReestrChangeStageDto } from '../../_dto/_reestr/ReestrChangeStageDto';
import { apiChangeStageReestr } from '../../services/reestr/apiChangeStageReestr';
import { SIGN_IN_ROUTE } from '../../_constant/routes';

export const ContractDiv = () => {
  const root = getRoot();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogReestr, setOpenDialogReestr] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialogWorkerChange, setOpenDialogWorkerChange] = useState(false);
  const [openDialogAddPdf, setOpenDialogAddPdf] = useState(false);
  const [openDialogChat, setOpenDialogChat] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogAddFile, setOpenDialogAddFile] = useState(false);
  const [openConfirmationBack, setOpenConfirmationBack] = useState(false);
  const [openConfirmationSend, setOpenConfirmationSend] = useState(false);
  const [openConfirmationBackReestr, setOpenConfirmationBackReestr] =
    useState(false);
  const [openConfirmationSendReestr, setOpenConfirmationSendReestr] =
    useState(false);
  const [openDialogAddResultWork, setOpenDialogAddResultWork] = useState(false);
  const [contractDto, setContractDto] = useState<ContractGetContractDto>({
    id: -1,
    customerId: -1,
    workerId: -1,
    contractNum: '',
    subject: '',
    koeff: 1,
    cost: '',
    objectName: '',
    workType: -1,
    createdDate: '',
    modificationDate: '',
    contractStage: -1,
    workStart: '',
    workEnd: '',
    note: '',
    isMain: -1,
    organization: '',
    hasDocument0: -1,
    hasDocument1: -1,
    hasContract: -1,
    subjectCnt: -1,
    workCnt: -1,
    customerName: '',
    workPeriod: '',
    addToContract: -1,
    customerContractId: -1,
    customerContractName: '',
    messageToCustomer: -1,
    messageToWorker: -1,
    hopeDate: '',
    isPaymentCreate: -1,
    regNum: '',
    regionNum: '',
    address: '',
    region: '',
    regDate: '',
    isOpenRegion: 0,
    isPaymentDocCreate: -1,
  });
  const [reestrDto, setReestrDto] = useState<ReestrDto>({
    id: -1,
    regNum: '',
    workType: -1,
    workerId: -1,
    subject: '',
    cost: -1,
    customer: '',
    customerContract: '',
    customerId: -1,
    customerContractId: -1,
    isCrmMetrolog: -1,
    contractNum: '',
    createdDate: '',
    modificationDate: '',
    organization: '',
    region: '',
    isPaymentCreate: -1,
    regionNum: '',
    reportType: -1,
    reportNum: '',
    reportDate: '',
    objectName: '',
    address: '',
    regDate: '',
    workStage: -1,
    sendNum: '',
    sendDate: '',
    note: '',
    period: -1,
    periodBegin: -1,
    contractDate: '',
    dateType: -1,
    workStart: '',
    workEnd: '',
    koeff: 1,
    isOpenRegion: 0,
    isPaymentDocCreate: -1,
  });
  const [reestrDtos, setReestrDtos] = useState<ReestrDto[]>([]);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [contractDtos, setContractDtos] = useState<ContractGetContractDto[]>(
    []
  );
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);

  const getContract = async () => {
    const apiResult: IApiResult = await apiGetContract();
    if (apiResult.isSuccess) {
      setContractDtos(apiResult.data.contract);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getReestr = async () => {
    const apiResult: IApiResult = await apiGetReestrStart();
    if (apiResult.isSuccess) {
      setReestrDtos(apiResult.data.reestr);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [valueTabNew, setValueTabNew] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };

  useEffect(() => {
    if (
      !openDialog &&
      !openDialogReestr &&
      !openDialogCreate &&
      !openDialogWorkerChange &&
      !openDialogAddResultWork &&
      !openDialogChat &&
      !openDialogAddPdf
    ) {
      if (valueTabNew === 0) {
        getContract();
      }
      if (valueTabNew === 1) {
        getReestr();
      }
    }
  }, [
    openDialog,
    openDialogReestr,
    openDialogCreate,
    openDialogWorkerChange,
    openDialogAddResultWork,
    openDialogChat,
    openDialogAddPdf,
    valueTabNew,
  ]);

  const deleteContract = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const deleteDto: ContractDeleteContractDto = {
      contractId: contractDto.id,
    };
    const apiResult: IApiResult = await apiDeleteContract(deleteDto);
    if (apiResult.isSuccess) {
      await getContract();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const confirmDelete = (dto: RequestDto) => {
    setContractDto(dto);
    setOpenDialogConfirmation(true);
  };

  const changeStage = async (confirm: boolean) => {
    if (!confirm) {
      setOpenConfirmationSend(false);
      return;
    }
    const changeStageDto: ContractChangeStageDto = {
      contractId: contractDto.id,
      contractStage: 3,
    };
    const apiResult: IApiResult = await apiChangeStageWorker(changeStageDto);
    if (apiResult.isSuccess) {
      await getContract();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenConfirmationSend(false);
  };

  const changeStageReestr = async (confirm: boolean) => {
    if (!confirm) {
      setOpenConfirmationSendReestr(false);
      return;
    }
    const changeStageDto: ReestrChangeStageDto = {
      reestrId: reestrDto.id,
      reestrStage: 3,
    };
    const apiResult: IApiResult = await apiChangeStageReestr(changeStageDto);
    if (apiResult.isSuccess) {
      await getReestr();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenConfirmationSendReestr(false);
  };

  const getMoreInformation = (dto: RequestDto) => {
    setContractDto(dto);
    setOpenDialog(true);
  };

  const endWork = (dto: RequestDto) => {
    if (dto.hasDocument1 === 0) {
      setAlertProps({
        message: 'Сначала загрузите работу',
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    setContractDto(dto);
    setMessage(
      'Вы собираетесь завершить работу. Если уверены, то нажмите продолжить'
    );
    setOpenConfirmationSend(true);
  };

  const endWorkReestr = (dto: ReestrDto) => {
    if (dto.regNum === 'Номер не присвоен') {
      setAlertProps({
        message: 'Сначала загрузите работу',
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    setReestrDto(dto);
    setMessage(
      'Вы собираетесь завершить работу. Если уверены, то нажмите продолжить'
    );
    setOpenConfirmationSendReestr(true);
  };

  const openChat = (dto: RequestDto) => {
    setContractDto(dto);
    setOpenDialogChat(true);
  };

  const openAddResultWork = (dto: RequestDto) => {
    setContractDto(dto);
    setOpenDialogAddResultWork(true);
  };

  const openChangeWork = (dto: RequestDto) => {
    setContractDto(dto);
    setOpenDialogWorkerChange(true);
  };

  const changeStageToLoadDocument = async (confirm: boolean) => {
    if (!confirm) {
      setOpenConfirmationBack(false);
      return;
    }
    const changeStageDto: RequestChangeStage = {
      contractId: contractDto.id,
      contractStage: 6,
    };
    const apiResult: IApiResult = await apiChangeStage(changeStageDto);
    if (apiResult.isSuccess) {
      await getContract();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenConfirmationBack(false);
  };

  const changeStageToLoadDocumentReestr = async (confirm: boolean) => {
    if (!confirm) {
      setOpenConfirmationBackReestr(false);
      return;
    }
    const changeStageDto: ReestrChangeStageDto = {
      reestrId: reestrDto.id,
      reestrStage: 0,
    };
    const apiResult: IApiResult = await apiChangeStageReestr(changeStageDto);
    if (apiResult.isSuccess) {
      await getReestr();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenConfirmationBackReestr(false);
  };

  const sendToLoadDocument = (dto: RequestDto) => {
    setContractDto(dto);
    setMessage(
      'Вы собираетесь вернуть заявку на доработку. Если уверены, то нажмите продолжить'
    );
    setOpenConfirmationBack(true);
  };

  const getMoreInformationReestr = (dto: ReestrDto) => {
    setReestrDto(dto);
    setOpenDialogReestr(true);
  };

  const sendToLoadDocumentReestr = (dto: ReestrDto) => {
    setReestrDto(dto);
    setMessage(
      'Вы собираетесь вернуть заявку. Если уверены, то нажмите продолжить'
    );
    setOpenConfirmationBackReestr(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'koeff',
      width: 70,
      headerName: '',
      editable: false,
      disableColumnMenu: true,
      renderCell: (row) => (
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <BoltIcon
            fontSize="medium"
            sx={{ color: row.row.koeff !== 1 ? 'orange' : 'grey' }}
          />
          <CurrencyRubleIcon
            fontSize="medium"
            sx={{ color: row.row.isPaymentCreate === 1 ? 'purple' : 'grey' }}
          />
        </Stack>
      ),
    },
    {
      field: 'customerName',
      renderHeader: () => <strong>Филиал</strong>,
      width: 200,
      editable: false,
      hide: root === ROOT_CUSTOMER_VALUE,
    },
    {
      field: 'workType',
      width: 250,
      renderHeader: () => <strong>Тип работы</strong>,
      editable: false,
      renderCell: (row) => WorkTypeEnum[row.row.workType],
    },
    {
      field: 'subject',
      flex: 1,
      minWidth: 300,
      renderHeader: () => <strong>Предмет</strong>,
      editable: false,
    },
    {
      field: 'objectName',
      renderHeader: () => <strong>Объект</strong>,
      flex: 1,
      minWidth: 300,
      editable: false,
      renderCell: (row) => row.row.objectName.replaceAll('_', '"'),
    },
    {
      field: 'workStart',
      width: 130,
      renderHeader: () => <strong>Начало работы</strong>,
      editable: false,
      cellClassName: (row) =>
        getContracState(
          row.row.workStart,
          row.row.workPeriod,
          row.row.contractStage
        ),
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: root !== ROOT_CUSTOMER_VALUE ? 215 : 70,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <FindInPageIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
          {root !== ROOT_CUSTOMER_VALUE && (
            <IconButton
              title="Загрузка результата работы"
              size="small"
              onClick={() =>
                row.row.contractStage === 2
                  ? openAddResultWork(row.row)
                  : undefined
              }
            >
              <FileUploadIcon
                fontSize="small"
                sx={{ color: row.row.contractStage === 2 ? 'orange' : 'grey' }}
              />
            </IconButton>
          )}
          <IconButton
            size="small"
            title="Чат"
            onClick={() => openChat(row.row)}
          >
            <Badge
              color="secondary"
              variant={
                (root === ROOT_CUSTOMER_VALUE &&
                  row.row.messageToCustomer === 1) ||
                (root !== ROOT_CUSTOMER_VALUE && row.row.messageToWorker === 1)
                  ? 'dot'
                  : 'standard'
              }
            >
              <MailOutlineIcon fontSize="small" sx={{ color: 'purple' }} />
            </Badge>
          </IconButton>
          {root !== ROOT_WORKER_VALUE && root !== ROOT_CUSTOMER_VALUE && (
            <IconButton
              title="Назначить/сменить исполнителя"
              size="small"
              onClick={() =>
                row.row.contractStage !== 3
                  ? openChangeWork(row.row)
                  : undefined
              }
            >
              <PersonAddIcon
                fontSize="small"
                sx={{
                  color:
                    row.row.contractStage !== 3 ? MAIN_COLOR_STRING : 'grey',
                }}
              />
            </IconButton>
          )}
          {(root === ROOT_ADMIN_VALUE || root === ROOT_WORKER_VALUE) && (
            <IconButton
              title="Не хватает документов"
              size="small"
              onClick={() =>
                row.row.contractStage === 2
                  ? sendToLoadDocument(row.row)
                  : undefined
              }
            >
              <ArrowBackIcon
                fontSize="small"
                sx={{ color: row.row.contractStage === 2 ? 'red' : 'grey' }}
              />
            </IconButton>
          )}
          {root !== ROOT_CUSTOMER_VALUE && (
            <IconButton
              title="Завершить работу"
              size="small"
              onClick={() =>
                row.row.contractStage === 2 ? endWork(row.row) : undefined
              }
            >
              <ArrowForwardIcon
                fontSize="small"
                sx={{ color: row.row.contractStage === 2 ? 'blue' : 'grey' }}
              />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const openAddPdf = (dto: ReestrDto) => {
    setReestrDto(dto);
    setOpenDialogAddPdf(true);
  };

  const columnsReestr: GridColDef[] = [
    {
      field: 'koeff',
      width: 70,
      headerName: '',
      editable: false,
      disableColumnMenu: true,
      renderCell: (row) => (
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <BoltIcon
            fontSize="medium"
            sx={{ color: row.row.koeff !== 1 ? 'orange' : 'grey' }}
          />
          <CurrencyRubleIcon
            fontSize="medium"
            sx={{ color: row.row.isPaymentCreate === 1 ? 'purple' : 'grey' }}
          />
        </Stack>
      ),
    },
    {
      field: 'contractNum',
      renderHeader: () => <strong>Номер договора</strong>,
      width: 250,
      editable: false,
      renderCell: (row) => row.row.contractNum.replaceAll('_', '"'),
    },
    {
      field: 'workType',
      renderHeader: () => <strong>Тип работы</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => WorkTypeEnum[row.row.workType],
    },
    {
      field: 'subject',
      minWidth: 350,
      flex: 1,
      renderHeader: () => <strong>Предмет</strong>,
      editable: false,
    },
    {
      field: 'objectName',
      renderHeader: () => <strong>Объект</strong>,
      flex: 1,
      minWidth: 300,
      editable: false,
      renderCell: (row) => row.row.objectName.replaceAll('_', '"'),
    },
    {
      field: 'workStart',
      width: 130,
      renderHeader: () => <strong>Начало работы</strong>,
      editable: false,
      cellClassName: (row) =>
        getContracState(row.row.workStart, row.row.period, row.row.workStage),
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 140,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformationReestr(row.row)}
          >
            <FindInPageIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
          <IconButton
            title="Загрузка результата работы"
            size="small"
            onClick={() => openAddPdf(row.row)}
          >
            <FileUploadIcon fontSize="small" sx={{ color: 'orange' }} />
          </IconButton>
          <IconButton
            title="Вернуть работу"
            size="small"
            onClick={() => sendToLoadDocumentReestr(row.row)}
          >
            <ArrowBackIcon fontSize="small" sx={{ color: 'red' }} />
          </IconButton>
          <IconButton
            title="Завершить работу"
            size="small"
            onClick={() => endWorkReestr(row.row)}
          >
            <ArrowForwardIcon fontSize="small" sx={{ color: 'blue' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Заявки в работе
            </Typography>
            {root !== ROOT_CUSTOMER_VALUE && (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={valueTabNew}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Филиалы" />
                  <Tab label="Прямые договора" />
                </Tabs>
              </Box>
            )}
          </Stack>
          <Divider />
          <div>
            {valueTabNew === 0 && (
              <Box
                sx={{
                  width: '100%',
                  '& .stage-green': {
                    backgroundColor: 'green',
                    fontWeight: '600',
                  },
                  '& .stage-orange': {
                    backgroundColor: 'orange',
                    fontWeight: '600',
                  },
                  '& .stage-red': {
                    backgroundColor: 'red',
                    fontWeight: '600',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '10px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    rows={contractDtos}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...other}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            )}
            {valueTabNew === 1 && (
              <Box
                sx={{
                  width: '100%',
                  '& .stage-green': {
                    backgroundColor: 'green',
                    fontWeight: '600',
                  },
                  '& .stage-orange': {
                    backgroundColor: 'orange',
                    fontWeight: '600',
                  },
                  '& .stage-red': {
                    backgroundColor: 'red',
                    fontWeight: '600',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '10px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={columnsReestr}
                    rows={reestrDtos}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...other}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            )}
          </div>
        </Stack>
      </Card>
      <DialogChat
        open={openDialogChat}
        idContract={contractDto.id}
        onClose={() => setOpenDialogChat(false)}
      />
      <DialogRequestCreate
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogChangeWorker
        open={openDialogWorkerChange}
        dto={contractDto}
        onClose={() => setOpenDialogWorkerChange(false)}
      />
      <DialogDocumentAdd
        open={openDialogAddFile}
        name="Исходная информация"
        type={ContractDocumentType.INFO}
        dto={contractDto}
        onClose={() => setOpenDialogAddFile(false)}
      />
      <DialogDocumentAdd
        open={openDialogAddResultWork}
        name="Загрузка результата работ"
        type={ContractDocumentType.RESULT}
        dto={contractDto}
        onClose={() => setOpenDialogAddResultWork(false)}
      />
      <DialogMoreInfo
        open={openDialog}
        dto={contractDto}
        onClose={() => setOpenDialog(false)}
      />
      <DialogReestrMoreInfo
        open={openDialogReestr}
        dto={reestrDto}
        onClose={() => setOpenDialogReestr(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteContract}
      />
      <DialogConfirmationCustom
        open={openConfirmationSend}
        message={message}
        onClose={changeStage}
      />
      <DialogConfirmationCustom
        open={openConfirmationSendReestr}
        message={message}
        onClose={changeStageReestr}
      />
      <DialogConfirmationCustom
        open={openConfirmationBack}
        message={message}
        onClose={changeStageToLoadDocument}
      />
      <DialogConfirmationCustom
        open={openConfirmationBackReestr}
        message={message}
        onClose={changeStageToLoadDocumentReestr}
      />
      <DialogAddPdf
        open={openDialogAddPdf}
        name="Загрузка работы"
        dto={reestrDto}
        onClose={() => setOpenDialogAddPdf(false)}
      />
    </Stack>
  );
};
