import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../_constant/constants';
import { IApiResult } from '../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { WorkDto } from '../../_dto/_work/WorkDto';
import { WorkDeleteDto } from '../../_dto/_work/WorkDeleteDto';
import { apiDeleteWork } from '../../services/work/apiDeleteWork';
import { apiGetWork } from '../../services/work/apiGetWork';
import { DialogWorkCreate } from '../dialog/work/DialogWorkCreate';
import { DialogMoreInfo } from '../dialog/work/DialogMoreInfo';
import { WorkTypeEnum } from '../../_enum/WorkTypeEnum';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { CostDto } from '../../_dto/_cost/CostDto';
import { apiGetCost } from '../../services/cost/apiGetCost';
import { CustomerGetCustomerRespDto } from '../../_dto/_customer/CustomerGetCustomerRespDto';
import { apiGetCustomer } from '../../services/customer/apiGetCustomer';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { DialogCostMoreInfo } from '../dialog/cost/DialogCostMoreInfo';

export const WorkDiv = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogCostInfo, setOpenDialogCostInfo] = useState(false);
  const [workDto, setWorkDto] = useState<WorkDto>({
    id: -1,
    workType: -1,
    subject: '',
    period: -1,
    expressPeriod: -1,
  });
  const [costDto, setCostDto] = useState<CostDto>({
    id: -1,
    workType: -1,
    subject: '',
    cost: -1,
    expressCost: -1,
    customerContractId: -1,
    customerContractName: '',
    customerId: -1,
    customerName: '',
  });

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [userId, setUserId] = useState();
  const [getCustomerDtos, setGetCustomerDtos] = useState<
    CustomerGetCustomerRespDto[]
  >([]);
  const getCustomer = async () => {
    const apiResult: IApiResult = await apiGetCustomer();
    if (apiResult.isSuccess) {
      setGetCustomerDtos(apiResult.data.customer);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getName = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].name);
    }
    return arrres;
  };

  const getNameId = (data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      arrres.push(data[i].id);
    }
    return arrres;
  };

  const [workDtos, setWorkDtos] = useState<WorkDto[]>([]);
  const getWork = async () => {
    const apiResult: IApiResult = await apiGetWork();
    if (apiResult.isSuccess) {
      setWorkDtos(apiResult.data.work);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [costDtos, setCostDtos] = useState<CostDto[]>([]);
  const getCost = async () => {
    const apiResult: IApiResult = await apiGetCost(userId);
    if (apiResult.isSuccess) {
      setCostDtos(apiResult.data.cost);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);

  const [valueTabNew, setValueTabNew] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };

  useEffect(() => {
    if (!openDialogCreate && !openDialogCostInfo) {
      if (valueTabNew === 0) {
        getWork();
      }
      if (valueTabNew === 1) {
        getCustomer();
      }
    }
    // eslint-disable-next-line
  }, [openDialogCreate, openDialog, valueTabNew]);

  useEffect(() => {
    if (!openDialogCreate && !openDialogCostInfo) {
      if (valueTabNew === 1) {
        if (userId !== undefined) {
          getCost();
        }
      }
    }
    // eslint-disable-next-line
  }, [userId, openDialogCostInfo]);

  const deleteWork = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const workDeleteDto: WorkDeleteDto = {
      workId: workDto.id,
    };
    const apiResult: IApiResult = await apiDeleteWork(workDeleteDto);
    if (apiResult.isSuccess) {
      getWork();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: WorkDto) => {
    setWorkDto(dto);
    setOpenDialogConfirmation(true);
  };

  const getMoreInformation = (dto: WorkDto) => {
    setWorkDto(dto);
    setOpenDialog(true);
  };

  const getCostMoreInformation = (dto: CostDto) => {
    setCostDto(dto);
    setOpenDialogCostInfo(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columnsWork: GridColDef[] = [
    {
      field: 'workType',
      renderHeader: () => <strong>Тип работы</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => WorkTypeEnum[row.row.workType],
    },
    {
      field: 'subject',
      minWidth: 350,
      flex: 1,
      renderHeader: () => <strong>Предмет</strong>,
      editable: false,
    },
    {
      field: 'period',
      width: 250,
      renderHeader: () => <strong>Срок, день</strong>,
      editable: false,
      renderCell: (row) => `${row.row.period} / ${row.row.expressPeriod}`,
    },
    {
      field: 'id',
      width: 70,
      type: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: MAIN_COLOR_STRING }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDelete(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsCost: GridColDef[] = [
    {
      field: 'customerContractName',
      renderHeader: () => <strong>Номер договора</strong>,
      headerName: '',
      width: 350,
      editable: false,
    },
    {
      field: 'workType',
      renderHeader: () => <strong>Тип работы</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => WorkTypeEnum[row.row.workType],
    },
    {
      field: 'subject',
      minWidth: 250,
      flex: 1,
      renderHeader: () => <strong>Предмет</strong>,
      editable: false,
    },
    {
      field: 'cost',
      width: 250,
      renderHeader: () => <strong>Стоимость, руб</strong>,
      editable: false,
      renderCell: (row) => `${row.row.cost} / ${row.row.expressCost}`,
    },
    {
      field: 'id',
      width: 35,
      type: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getCostMoreInformation(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: MAIN_COLOR_STRING }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Прайс-лист
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={valueTabNew}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Виды работ" />
                <Tab label="Стоимость" />
              </Tabs>
            </Box>
          </Stack>
          <Divider />
          <Stack>
            {valueTabNew === 0 && (
              <Stack spacing={2}>
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                  spacing={1}
                >
                  <Typography
                    style={{
                      fontFamily: 'SF Compact Rounded',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: 1,
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Виды работ
                  </Typography>
                  <MyButton
                    text="Добавить"
                    width="100px"
                    height="30px"
                    background={MAIN_COLOR_STRING}
                    onClick={() => setOpenDialogCreate(true)}
                  />
                </Stack>
                <div>
                  <Box sx={{ width: '100%' }}>
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '10px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columnsWork}
                        rows={workDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </div>
                <DialogWorkCreate
                  open={openDialogCreate}
                  onClose={() => setOpenDialogCreate(false)}
                />
                <DialogMoreInfo
                  open={openDialog}
                  dto={workDto}
                  onClose={() => setOpenDialog(false)}
                />
                <DialogConfirmation
                  open={openDialogConfirmation}
                  onClose={deleteWork}
                />
              </Stack>
            )}
            {valueTabNew === 1 && (
              <Stack spacing={2}>
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                  spacing={1}
                >
                  <Typography
                    style={{
                      fontFamily: 'SF Compact Rounded',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: 1,
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Стоимость
                  </Typography>
                  <MyAutoComplete
                    value={
                      userId !== undefined
                        ? getName(getCustomerDtos)[
                            Object.values(getNameId(getCustomerDtos)).indexOf(
                              userId
                            )
                          ]
                        : ' '
                    }
                    onChange={(e, v) =>
                      setUserId(
                        getNameId(getCustomerDtos)[
                          Object.values(getName(getCustomerDtos)).indexOf(v)
                        ]
                      )
                    }
                    width="400px"
                    arrayList={getName(getCustomerDtos)}
                    size="small"
                    label="Филиал"
                  />
                </Stack>
                <div>
                  <Box sx={{ width: '100%' }}>
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '10px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columnsCost}
                        rows={costDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </div>
                <DialogCostMoreInfo
                  open={openDialogCostInfo}
                  dto={costDto}
                  onClose={() => setOpenDialogCostInfo(false)}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
