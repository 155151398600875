import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../../_constant/constants';
import { IApiResult } from '../../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { MyButton } from '../../UI/MyButton';
import { WorkTypeEnum } from '../../../_enum/WorkTypeEnum';
import { CostDto } from '../../../_dto/_cost/CostDto';
import { CostUpdateDto } from '../../../_dto/_cost/CostUpdateDto';
import { apiUpdateCost } from '../../../services/cost/apiUpdateCost';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: CostDto;
}

export const DialogCostMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [updateDto, setUpdateDto] = useState<CostUpdateDto>({
    costId: dto?.id ?? -1,
    workType: dto?.workType ?? -1,
    subject: dto?.subject ?? '',
    cost: dto?.cost ?? -1,
    expressCost: dto?.expressCost ?? -1,
  });

  const handleClose = () => {
    onClose();
  };

  const updateCost = async () => {
    const apiResult: IApiResult = await apiUpdateCost(updateDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    await updateCost();
  };

  useEffect(() => {
    if (open) {
      setUpdateDto({
        costId: dto?.id ?? -1,
        workType: dto?.workType ?? -1,
        subject: dto?.subject ?? '',
        cost: dto?.cost ?? -1,
        expressCost: dto?.expressCost ?? -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Редактирование стоимости
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <MyAutoComplete
              value={
                updateDto.workType === -1
                  ? ' '
                  : WorkTypeEnum[updateDto.workType]
              }
              onChange={(e, v) =>
                setUpdateDto({
                  ...updateDto,
                  workType: Object.values(WorkTypeEnum).indexOf(v),
                })
              }
              arrayList={Object.values(WorkTypeEnum).filter(
                (value) => typeof value === 'string'
              )}
              disabled
              size="small"
              label="Тип работы"
            />
            <MyTextFiled
              value={updateDto.subject}
              onChange={(e) =>
                setUpdateDto({
                  ...updateDto,
                  subject: e.target.value,
                })
              }
              disabled
              label="Предмет"
              size="small"
            />
            <Stack width="100%" direction="row" spacing={2}>
              <MyTextFiled
                value={`${updateDto.cost}`}
                onChange={(e) =>
                  setUpdateDto({
                    ...updateDto,
                    cost: e.target.value,
                  })
                }
                type="number"
                label="Стоимость, руб"
                size="small"
              />
              <MyTextFiled
                value={`${updateDto.expressCost}`}
                onChange={(e) =>
                  setUpdateDto({
                    ...updateDto,
                    expressCost: e.target.value,
                  })
                }
                type="number"
                label="Стоимость (срочная), руб"
                size="small"
              />
            </Stack>
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleSave}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogCostMoreInfo.defaultProps = {
  dto: undefined,
};
