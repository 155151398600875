import { JSON_CONTENT_TYPE } from '../_constant/constants';
import { UserAuthDto } from '../_dto/UserAuthDto';
import { IApiParams } from '../_interface/IApiParams';
import { getApi } from './getApi';

export const apiSignIn = async (userAuthDto: UserAuthDto) => {
  const params: IApiParams = {
    url: '/signIn.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
    },
  };
  return getApi(params, 'POST', userAuthDto);
};
