import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { WorkerTelegramDto } from '../../_dto/_worker/WorkerTelegramDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiTelegramId = async (workerTelegramDto: WorkerTelegramDto) => {
  const params: IApiParams = {
    url: '/worker/addIdTelegram.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', workerTelegramDto);
};
