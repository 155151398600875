import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Checkbox,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  API_STATUS_PROBLEM,
  EXPRESS_WORK_KOEFF,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
  ROOT_REGIONGAZ_VALUE,
  ROOT_WORKER_VALUE,
} from '../../../_constant/constants';
import { IAlertProps } from '../../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { ReestrDto } from '../../../_dto/_reestr/ReestrDto';
import { MyAutoComplete } from '../../UI/MuAutoComplete';
import { SpisokRegionovEnum } from '../../../_enum/SpisokRegionovEnum';
import { WorkTypeEnum } from '../../../_enum/WorkTypeEnum';
import { WorkDto } from '../../../_dto/_work/WorkDto';
import { IApiResult } from '../../../_interface/IApiResult';
import { apiGetWork } from '../../../services/work/apiGetWork';
import { AlertSeverityEnum } from '../../../_enum/AlertSeverityEnum';
import { ReestrUpdateDto } from '../../../_dto/_reestr/ReestrUpdateDto';
import { apiUpdateReestr } from '../../../services/reestr/apiUpdateReestr';
import { MyButton } from '../../UI/MyButton';
import { ReestrDeletePdfDto } from '../../../_dto/_reestr/ReestrDeletePdfDto';
import { ReestrPdfDto } from '../../../_dto/_reestr/ReestrPdfDto';
import { apiGetPdf } from '../../../services/reestr/apiGetPdf';
import { apiDeletePdf } from '../../../services/reestr/apiDeletePdf';
import { DialogConfirmation } from '../DialogConfirmation';
import { MyDateTextField } from '../../UI/MyDateTextField';
import { WorkStartTypeEnum } from '../../../_enum/WorkStartTypeEnum';
import { PeriodDateTypeEnum } from '../../../_enum/PeriodDateTypeEnum';
import { ReportTypeEnum } from '../../../_enum/ReportTypeEnum';
import { ReestrMailInfo } from '../../../_dto/_reestr/ReestrMailInfoDto';
import { ReestrPaymentInfo } from '../../../_dto/_reestr/ReestrPaymentInfoDto';
import { apiUpdateReestrPayment } from '../../../services/reestr/apiUpdateReestrPayment';
import { apiUpdateReestrMail } from '../../../services/reestr/apiUpdateReestrMail';
import { apiDownloadFile } from '../../../services/apiDownloadFile';
import { ProgressBox } from '../../progress/ProgressBox';

interface Props {
  open: boolean;
  onClose: () => void;
  dto: ReestrDto;
}

export const DialogReestrMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const root = getRoot();
  const [shouldShowLoader, setShowLoader] = useState(false);
  const [uploadText, setUploadText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const progressRef = useRef(0);
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [valueTabNew, setValueTabNew] = React.useState(0);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [documentDto, setDocumentDto] = useState<ReestrPdfDto>({
    id: -1,
    workerId: -1,
    descr: '',
    name: '',
    datetime: '',
    size: '',
    crc: '',
  });
  const [documentDtos, setDocumentDtos] = useState<ReestrPdfDto[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabNew(newValue);
  };
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };

  const [isEditPayment, setIsEditPayment] = useState(false);
  const handleEditPayment = () => {
    setIsEditPayment(true);
  };

  const [isEditMail, setIsEditMail] = useState(false);
  const handleEditMail = () => {
    setIsEditMail(true);
  };

  const getParamArray = (type: number, data: any) => {
    const arrres = [];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].workType === type) {
        arrres.push(data[i].subject);
      }
    }
    return arrres;
  };
  const [workDtos, setWorkDtos] = useState<WorkDto[]>([]);
  const getWork = async () => {
    const apiResult: IApiResult = await apiGetWork();
    if (apiResult.isSuccess) {
      setWorkDtos(apiResult.data.work);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getDocument = async () => {
    const apiResult: IApiResult = await apiGetPdf(dto?.id);
    if (apiResult.isSuccess) {
      setDocumentDtos(apiResult.data.document);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [reestrUpdateDto, setUpdateAddDto] = useState<ReestrUpdateDto>({
    reestrId: dto.id,
    workType: dto.workType,
    subject: dto.subject,
    cost: dto.cost,
    period: dto.period,
    periodBegin: dto.periodBegin,
    dateType: dto.dateType,
    regDate: dto.regDate,
    regNum: dto.regNum,
    contractNum: dto.contractNum,
    contractDate: dto.contractDate,
    organization: dto.organization,
    region: dto.region,
    objectName: dto.objectName,
    address: dto.address,
    note: dto.note,
    koeff: dto.koeff,
  });

  const [reestrUpdatePaymentDto, setUpdatePaymentDto] =
    useState<ReestrPaymentInfo>({
      reestrId: dto.id,
      reportType: dto.reportType,
      reportNum: dto.reportNum,
      reportDate: dto.reportDate,
    });

  const [reestrUpdateMailDto, setUpdateMailDto] = useState<ReestrMailInfo>({
    reestrId: dto.id,
    sendNum: dto.sendNum,
    sendDate: dto.sendDate,
  });

  const handleClose = () => {
    onClose();
    setUpdateAddDto({
      reestrId: dto.id,
      workType: dto.workType,
      subject: dto.subject,
      cost: dto.cost,
      period: dto.period,
      periodBegin: dto.periodBegin,
      dateType: dto.dateType,
      regDate: dto.regDate,
      regNum: dto.regNum,
      contractNum: dto.contractNum,
      contractDate: dto.contractDate,
      organization: dto.organization,
      region: dto.region,
      objectName: dto.objectName,
      address: dto.address,
      note: dto.note,
      koeff: dto.koeff,
    });

    setIsEdit(false);
    setIsEditPayment(false);
    setIsEditMail(false);
    setValueTabNew(0);
    setCheckHelper(false);
    setUploadProgress(0);
    progressRef.current = 0;
  };

  const updateReestr = async () => {
    const apiResult: IApiResult = await apiUpdateReestr(reestrUpdateDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const updateReestrPayment = async () => {
    const apiResult: IApiResult = await apiUpdateReestrPayment(
      reestrUpdatePaymentDto
    );
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const updateReestrMail = async () => {
    const apiResult: IApiResult = await apiUpdateReestrMail(
      reestrUpdateMailDto
    );
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const setOrganization = (inputName: string) => {
    setUpdateAddDto({
      ...reestrUpdateDto,
      organization: inputName.replaceAll('"', '_'),
    });
  };

  const setAddress = (inputName: string) => {
    setUpdateAddDto({
      ...reestrUpdateDto,
      address: inputName.replaceAll('"', '_'),
    });
  };

  const setOther = (inputName: string) => {
    setUpdateAddDto({
      ...reestrUpdateDto,
      note: inputName.replaceAll('"', '_'),
    });
  };

  const setObject = (inputName: string) => {
    setUpdateAddDto({
      ...reestrUpdateDto,
      objectName: inputName.replaceAll('"', '_'),
    });
  };

  const setContractNum = (inputName: string) => {
    setUpdateAddDto({
      ...reestrUpdateDto,
      contractNum: inputName.replaceAll('"', '_'),
    });
  };

  const setReportNum = (inputName: string) => {
    setUpdatePaymentDto({
      ...reestrUpdatePaymentDto,
      reportNum: inputName.replaceAll('"', '_'),
    });
  };

  const setSendNum = (inputName: string) => {
    setUpdateMailDto({
      ...reestrUpdateMailDto,
      sendNum: inputName.replaceAll('"', '_'),
    });
  };

  const handleProgressUpdate = (event: any) => {
    const progress = (event.loaded / event.total) * 100;
    if (progress > progressRef.current) {
      progressRef.current = progress;
      setUploadProgress(progressRef.current);
    }
  };
  const cancelToken = useRef(axios.CancelToken.source());
  const openFile = async (name: string) => {
    cancelToken.current = axios.CancelToken.source();
    setUploadProgress(0);
    progressRef.current = 0;
    setShowLoader(true);
    const nameArray = name.split(';');
    for (let i = 0; i < nameArray.length; i += 1) {
      if (nameArray[i].length > 4) {
        setUploadText(`${i + 1} из ${nameArray.length - 1}`);
        // eslint-disable-next-line no-await-in-loop
        await apiDownloadFile(
          `/contract/${nameArray[i]}`,
          nameArray[i],
          handleProgressUpdate,
          cancelToken.current
        );
      }
      progressRef.current = 0;
    }
    setShowLoader(false);
  };
  const cancelCreationOfTweet = () => {
    cancelToken.current.cancel();
    setShowLoader(false);
  };

  const handleUpdateReestr = async () => {
    if (
      reestrUpdateDto.objectName === '' ||
      reestrUpdateDto.organization === '' ||
      reestrUpdateDto.region === '' ||
      reestrUpdateDto.contractNum === '' ||
      reestrUpdateDto.contractDate === '' ||
      reestrUpdateDto.address === '' ||
      reestrUpdateDto.workType === -1 ||
      reestrUpdateDto.subject === '' ||
      reestrUpdateDto.cost === -1 ||
      reestrUpdateDto.period === -1 ||
      reestrUpdateDto.periodBegin === -1 ||
      reestrUpdateDto.dateType === -1
    ) {
      setCheckHelper(true);
      return;
    }
    await updateReestr();
  };

  const handleUpdatePayment = async () => {
    if (
      reestrUpdatePaymentDto.reportNum === '' ||
      reestrUpdatePaymentDto.reportDate === '' ||
      reestrUpdatePaymentDto.reportType === -1
    ) {
      setCheckHelper(true);
      return;
    }
    await updateReestrPayment();
  };

  const handleUpdateMail = async () => {
    if (
      reestrUpdateMailDto.sendNum === '' ||
      reestrUpdateMailDto.sendDate === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await updateReestrMail();
  };

  const deletePdf = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const deleteDto: ReestrDeletePdfDto = {
      pdfId: documentDto.id,
    };
    const apiResult: IApiResult = await apiDeletePdf(deleteDto);
    if (apiResult.isSuccess) {
      await getDocument();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dtoContract: ReestrPdfDto) => {
    setDocumentDto(dtoContract);
    setOpenDialogConfirmation(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'descr',
      renderHeader: () => <strong>Номер договора</strong>,
      flex: 1,
      minWidth: 250,
      editable: false,
      renderCell: (row) =>
        row.row.type === '2'
          ? `${row.row.descr} ${row.row.regNum}`
          : `${row.row.descr}`,
    },
    {
      field: 'datetime',
      renderHeader: () => <strong>Дата загрузки</strong>,
      width: 200,
      editable: false,
    },
    {
      field: 'size',
      renderHeader: () => <strong>Файлов</strong>,
      width: 100,
      editable: false,
      renderCell: (row) => row.row.name.split(';').length - 1,
    },
    {
      field: 'id',
      headerName: '',
      width: 100,
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="left">
          {row.row.name.includes('pdf') && (
            <PictureAsPdfIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
          {row.row.name.includes('doc') && (
            <ArticleIcon fontSize="medium" sx={{ color: 'grey' }} />
          )}
        </Stack>
      ),
    },
    {
      field: 'name',
      type: 'actions',
      width: root === ROOT_ADMIN_VALUE || root === ROOT_WORKER_VALUE ? 70 : 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            title="Скачать"
            size="small"
            onClick={() => openFile(row.row.name)}
          >
            <FileDownloadIcon fontSize="small" sx={{ color: 'blue' }} />
          </IconButton>
          {(root === ROOT_ADMIN_VALUE || root === ROOT_WORKER_VALUE) && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const handleChangeExpressWork = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let check = 1;
    if (event.target.checked) {
      check = EXPRESS_WORK_KOEFF;
    }
    setUpdateAddDto({
      ...reestrUpdateDto,
      koeff: check,
    });
  };

  useEffect(() => {
    if (open) {
      if (root === ROOT_REGIONGAZ_VALUE) {
        setValueTabNew(2);
      }
      getWork();
      getDocument();
      setUpdateAddDto({
        reestrId: dto.id,
        workType: dto.workType,
        subject: dto.subject,
        cost: dto.cost,
        period: dto.period,
        periodBegin: dto.periodBegin,
        dateType: dto.dateType,
        regDate: dto.regDate,
        regNum: dto.regNum,
        contractNum: dto.contractNum,
        contractDate: dto.contractDate,
        organization: dto.organization,
        region: dto.region,
        objectName: dto.objectName,
        address: dto.address,
        note: dto.note,
        koeff: dto.koeff,
      });

      setUpdatePaymentDto({
        reestrId: dto.id,
        reportType: dto.reportType,
        reportNum: dto.reportNum,
        reportDate: dto.reportDate,
      });

      setUpdateMailDto({
        reestrId: dto.id,
        sendNum: dto.sendNum,
        sendDate: dto.sendDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme2) => theme2.zIndex.drawer + 1 }}
          open={shouldShowLoader}
          onClick={cancelCreationOfTweet}
        >
          <Stack alignItems="center" spacing={1}>
            <Typography variant="caption" component="div" color="inherit">
              {uploadText}
            </Typography>
            <ProgressBox progress={uploadProgress} />
            <Typography variant="caption" component="div" color="inherit">
              Если нажать на экран, то произойдет отмена загрузки
            </Typography>
          </Stack>
        </Backdrop>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {!isEdit && valueTabNew === 0 && (
                <IconButton size="medium" onClick={handleEdit}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
              {!isEditPayment && valueTabNew === 1 && (
                <IconButton size="medium" onClick={handleEditPayment}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
              {!isEditMail && valueTabNew === 3 && (
                <IconButton size="medium" onClick={handleEditMail}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
            </Stack>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={valueTabNew}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    disabled={
                      isEditPayment ||
                      isEditMail ||
                      root === ROOT_REGIONGAZ_VALUE
                    }
                    label="Работа"
                  />
                  <Tab
                    disabled={
                      isEdit || isEditMail || root === ROOT_REGIONGAZ_VALUE
                    }
                    label="Оплата"
                  />
                  <Tab
                    disabled={isEdit || isEditPayment || isEditMail}
                    label="Сканы работы"
                  />
                  <Tab
                    disabled={
                      isEdit || isEditPayment || root === ROOT_REGIONGAZ_VALUE
                    }
                    label="Почта"
                  />
                </Tabs>
              </Box>
              <Stack>
                <IconButton size="medium" onClick={() => handleClose()}>
                  <CloseIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack>
            {valueTabNew === 0 && (
              <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={reestrUpdateDto.objectName.replaceAll('_', '"')}
                    onChange={(e) => setObject(e.target.value)}
                    disabled={!isEdit}
                    label="Объект"
                    error={checkHelper}
                    size="small"
                  />
                  <MyTextFiled
                    value={reestrUpdateDto.organization.replaceAll('_', '"')}
                    onChange={(e) => setOrganization(e.target.value)}
                    disabled={!isEdit}
                    label="Организация"
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyAutoComplete
                    value={reestrUpdateDto.region}
                    onChange={(e, v) =>
                      setUpdateAddDto({
                        ...reestrUpdateDto,
                        region: v,
                      })
                    }
                    disabled={!isEdit}
                    arrayList={Object.values(SpisokRegionovEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    size="small"
                    error={checkHelper}
                    label="Регион"
                  />
                  <MyTextFiled
                    value={reestrUpdateDto.address.replaceAll('_', '"')}
                    onChange={(e) => setAddress(e.target.value)}
                    disabled={!isEdit}
                    label="Адрес"
                    error={checkHelper}
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Stack width="100%" direction="row" spacing={1}>
                    <MyTextFiled
                      value={reestrUpdateDto.contractNum.replaceAll('_', '"')}
                      onChange={(e) => setContractNum(e.target.value)}
                      disabled={!isEdit}
                      error={checkHelper}
                      label="Номер договора"
                      size="small"
                    />
                    <MyDateTextField
                      value={reestrUpdateDto.contractDate}
                      onChange={(e) =>
                        setUpdateAddDto({
                          ...reestrUpdateDto,
                          contractDate: e.target.value,
                        })
                      }
                      disabled={!isEdit}
                      width="40%"
                      error={checkHelper}
                      label="Дата договора"
                      size="small"
                    />
                  </Stack>
                  <MyAutoComplete
                    value={
                      reestrUpdateDto.workType === -1
                        ? ''
                        : WorkTypeEnum[reestrUpdateDto.workType]
                    }
                    onChange={(e, v) =>
                      setUpdateAddDto({
                        ...reestrUpdateDto,
                        workType: Object.values(WorkTypeEnum).indexOf(v),
                      })
                    }
                    disabled={!isEdit}
                    arrayList={Object.values(WorkTypeEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    size="small"
                    error={checkHelper}
                    label="Тип работы"
                  />
                </Stack>
                <MyAutoComplete
                  value={reestrUpdateDto.subject}
                  onChange={(e, v) =>
                    setUpdateAddDto({
                      ...reestrUpdateDto,
                      subject: v,
                    })
                  }
                  disabled={!isEdit}
                  arrayList={getParamArray(reestrUpdateDto.workType, workDtos)}
                  size="small"
                  label="Предмет"
                  error={checkHelper}
                />
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={`${reestrUpdateDto.cost}`}
                    onChange={(e) =>
                      setUpdateAddDto({
                        ...reestrUpdateDto,
                        cost: e.target.value,
                      })
                    }
                    disabled={!isEdit}
                    error={checkHelper}
                    label="Стоимость, руб"
                    type="number"
                    size="small"
                  />
                  <MyTextFiled
                    value={`${reestrUpdateDto.period}`}
                    onChange={(e) =>
                      setUpdateAddDto({
                        ...reestrUpdateDto,
                        period: e.target.value,
                      })
                    }
                    disabled={!isEdit}
                    error={checkHelper}
                    label="Срок работы, день"
                    type="number"
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyAutoComplete
                    value={
                      reestrUpdateDto.periodBegin === -1
                        ? ''
                        : WorkStartTypeEnum[reestrUpdateDto.periodBegin]
                    }
                    onChange={(e, v) =>
                      setUpdateAddDto({
                        ...reestrUpdateDto,
                        periodBegin:
                          Object.values(WorkStartTypeEnum).indexOf(v),
                      })
                    }
                    arrayList={Object.values(WorkStartTypeEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    disabled={!isEdit}
                    error={checkHelper}
                    size="small"
                    label="Отсчет начала работы"
                  />
                  <MyAutoComplete
                    value={
                      reestrUpdateDto.dateType === -1
                        ? ''
                        : PeriodDateTypeEnum[reestrUpdateDto.dateType]
                    }
                    onChange={(e, v) =>
                      setUpdateAddDto({
                        ...reestrUpdateDto,
                        dateType: Object.values(PeriodDateTypeEnum).indexOf(v),
                      })
                    }
                    disabled={!isEdit}
                    arrayList={Object.values(PeriodDateTypeEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    size="small"
                    error={checkHelper}
                    label="В каких днях рассчитывается действие"
                  />
                </Stack>
                <FormControlLabel
                  disabled={!isEdit}
                  control={
                    <Checkbox
                      sx={{ color: MAIN_COLOR_STRING }}
                      onChange={handleChangeExpressWork}
                      checked={reestrUpdateDto.koeff === EXPRESS_WORK_KOEFF}
                    />
                  }
                  label="Срочная работа"
                />
                <MyTextFiled
                  value={reestrUpdateDto.note.replaceAll('_', '"')}
                  onChange={(e) => setOther(e.target.value)}
                  disabled={!isEdit}
                  label="Примечание"
                  size="small"
                />
                {dto.regNum !== 'Номер не присвоен' && (
                  <Stack direction="row" spacing={1}>
                    <MyTextFiled
                      value={reestrUpdateDto.regNum}
                      onChange={(e) =>
                        setUpdateAddDto({
                          ...reestrUpdateDto,
                          regNum: e.target.value,
                        })
                      }
                      disabled={
                        (!isEdit && root === ROOT_ADMIN_VALUE) ||
                        root !== ROOT_ADMIN_VALUE
                      }
                      label="Регистрационный номер"
                      size="small"
                    />
                    <MyDateTextField
                      value={reestrUpdateDto.regDate}
                      onChange={(e) =>
                        setUpdateAddDto({
                          ...reestrUpdateDto,
                          regDate: e.target.value,
                        })
                      }
                      disabled={!isEdit && root === ROOT_ADMIN_VALUE}
                      label="Дата регистрации"
                      size="small"
                    />
                  </Stack>
                )}
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={dto.workStart}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата начала работы"
                    size="small"
                  />
                  <MyTextFiled
                    value={dto.workEnd}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата конца работы"
                    size="small"
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={dto.createdDate}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата создания"
                    size="small"
                  />
                  <MyTextFiled
                    value={dto.modificationDate}
                    // eslint-disable-next-line react/jsx-boolean-value
                    disabled={true}
                    label="Дата модификации"
                    size="small"
                  />
                </Stack>
                {isEdit && (
                  <Stack direction="row" spacing={1}>
                    <MyButton
                      text="Отмена"
                      background={MAIN_COLOR_STRING}
                      onClick={() => setIsEdit(false)}
                    />
                    <MyButton
                      text="Сохранить"
                      background={MAIN_COLOR_STRING}
                      onClick={handleUpdateReestr}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            {valueTabNew === 1 && (
              <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                  <MyAutoComplete
                    value={
                      reestrUpdatePaymentDto.reportType === -1
                        ? ''
                        : ReportTypeEnum[reestrUpdatePaymentDto.reportType]
                    }
                    onChange={(e, v) =>
                      setUpdatePaymentDto({
                        ...reestrUpdatePaymentDto,
                        reportType: Object.values(ReportTypeEnum).indexOf(v),
                      })
                    }
                    arrayList={Object.values(ReportTypeEnum).filter(
                      (value) => typeof value === 'string'
                    )}
                    disabled={!isEditPayment}
                    size="small"
                    error={checkHelper}
                    label="Тип документа"
                  />
                </Stack>
                <MyTextFiled
                  value={reestrUpdatePaymentDto.reportNum.replaceAll('_', '"')}
                  onChange={(e) => setReportNum(e.target.value)}
                  disabled={!isEditPayment}
                  label="Номер документа"
                  error={checkHelper}
                  size="small"
                />
                <MyDateTextField
                  value={reestrUpdatePaymentDto.reportDate}
                  onChange={(e) =>
                    setUpdatePaymentDto({
                      ...reestrUpdatePaymentDto,
                      reportDate: e.target.value,
                    })
                  }
                  disabled={!isEditPayment}
                  label="Дата документа"
                  error={checkHelper}
                  size="small"
                />
                {isEditPayment && (
                  <Stack direction="row" spacing={1}>
                    <MyButton
                      text="Отмена"
                      background={MAIN_COLOR_STRING}
                      onClick={() => setIsEditPayment(false)}
                    />
                    <MyButton
                      text="Сохранить"
                      background={MAIN_COLOR_STRING}
                      onClick={handleUpdatePayment}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            {valueTabNew === 2 && (
              <Stack spacing={2}>
                <Box sx={{ width: '100%' }}>
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                          {
                            py: 1,
                          },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                          {
                            py: '5px',
                          },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                          {
                            py: '10px',
                          },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                          {
                            outline: 'none !important',
                          },
                      }}
                      getRowHeight={() => 'auto'}
                      columns={columns}
                      rows={documentDtos}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...other}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </ThemeProvider>
                </Box>
              </Stack>
            )}
            {valueTabNew === 3 && (
              <Stack spacing={2}>
                <MyTextFiled
                  value={reestrUpdateMailDto.sendNum.replaceAll('_', '"')}
                  onChange={(e) => setSendNum(e.target.value)}
                  disabled={!isEditMail}
                  label="Номер отправления"
                  error={checkHelper}
                  size="small"
                />
                <MyDateTextField
                  value={reestrUpdateMailDto.sendDate}
                  onChange={(e) =>
                    setUpdateMailDto({
                      ...reestrUpdateMailDto,
                      sendDate: e.target.value,
                    })
                  }
                  disabled={!isEditMail}
                  label="Дата отправления"
                  error={checkHelper}
                  size="small"
                />
                {isEditMail && (
                  <Stack direction="row" spacing={1}>
                    <MyButton
                      text="Отмена"
                      background={MAIN_COLOR_STRING}
                      onClick={() => setIsEditMail(false)}
                    />
                    <MyButton
                      text="Сохранить"
                      background={MAIN_COLOR_STRING}
                      onClick={handleUpdateMail}
                    />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogConfirmation open={openDialogConfirmation} onClose={deletePdf} />
      </Dialog>
    </div>
  );
};
