import {
  ACCESS_TOKEN_NAME,
  JSON_CONTENT_TYPE,
} from '../../_constant/constants';
import { ContractChangeStageDto } from '../../_dto/_contract/ContractChangeStageDto';
import { IApiParams } from '../../_interface/IApiParams';
import { getApi } from '../getApi';

export const apiChangeStageWorker = async (
  contractChangeStageDto: ContractChangeStageDto
) => {
  const params: IApiParams = {
    url: '/contract/changeStage.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'PUT', contractChangeStageDto);
};
