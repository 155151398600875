import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TelegramIcon from '@mui/icons-material/Telegram';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CustomerGetCustomerRespDto } from '../../_dto/_customer/CustomerGetCustomerRespDto';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
  ROOT_ADMIN_VALUE,
  ROOT_BUHGALTER_VALUE,
} from '../../_constant/constants';
import { IApiResult } from '../../_interface/IApiResult';
import { apiGetCustomer } from '../../services/customer/apiGetCustomer';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps, getRoot } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { CustomerDeleteDto } from '../../_dto/_customer/CustomerDeleteDto';
import { apiDelete } from '../../services/customer/apiDelete';
import { DialogMoreInfo } from '../dialog/customer/DialogMoreInfo';
import { DialogChangePassword } from '../dialog/customer/DialogChangePassword';
import { DialogCustomerCreate } from '../dialog/customer/DialogCustomerCreate';
import { DialogContractAdd } from '../dialog/contract/DialogContractAdd';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { SIGN_IN_ROUTE } from '../../_constant/routes';
import { DialogAddTelegram } from '../dialog/customer/DialogAddTelegram';

export const CustomerDiv = () => {
  const root = getRoot();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [openDialogTelegramId, setOpenDialogTelegramId] = useState(false);
  const [openDialogAddContract, setOpenDialogAddContract] = useState(false);
  const [getCustomerDto, setGetCustomerDto] =
    useState<CustomerGetCustomerRespDto>({
      id: -1,
      login: '',
      region: '',
      name: '',
      inn: '',
      phone: '',
      email: '',
      address: '',
      other: '',
      lockUser: -1,
      completedContract: -1,
      activeContract: -1,
      conclusionContract: -1,
      createdDate: '',
      modificationDate: '',
      code: '',
      delegate: '',
      workerId: -1,
      telegramId: '',
    });
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [getCustomerDtos, setGetCustomerDtos] = useState<
    CustomerGetCustomerRespDto[]
  >([]);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);

  const getCustomer = async () => {
    const apiResult: IApiResult = await apiGetCustomer();
    if (apiResult.isSuccess) {
      setGetCustomerDtos(apiResult.data.customer);
    } else if (
      apiResult.status === 401 ||
      localStorage.getItem(ACCESS_TOKEN_NAME) === null
    ) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (!openDialog && !openDialogCreate && !openDialogTelegramId) {
      getCustomer();
    }
  }, [openDialog, openDialogCreate, openDialogTelegramId]);

  const deleteCustomer = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const customerDeleteDto: CustomerDeleteDto = {
      id: getCustomerDto.id,
    };
    const apiResult: IApiResult = await apiDelete(customerDeleteDto);
    if (apiResult.isSuccess) {
      getCustomer();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: CustomerGetCustomerRespDto) => {
    setGetCustomerDto(dto);
    setOpenDialogConfirmation(true);
  };

  const getMoreInformation = (dto: CustomerGetCustomerRespDto) => {
    setGetCustomerDto(dto);
    setOpenDialog(true);
  };

  const changePassword = (dto: CustomerGetCustomerRespDto) => {
    setGetCustomerDto(dto);
    setOpenDialogPassword(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const addTelegram = (dto: CustomerGetCustomerRespDto) => {
    setGetCustomerDto(dto);
    setOpenDialogTelegramId(true);
  };

  const openAddContract = (dto: CustomerGetCustomerRespDto) => {
    setGetCustomerDto(dto);
    setOpenDialogAddContract(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      renderHeader: () => <strong>Филиал</strong>,
      width: 350,
      editable: false,
      renderCell: (row) => row.row.name.replaceAll('_', '"'),
    },
    {
      field: 'region',
      width: 350,
      renderHeader: () => <strong>Регион</strong>,
      editable: false,
    },
    {
      field: 'address',
      flex: 1,
      minWidth: 350,
      renderHeader: () => <strong>Адрес</strong>,
      editable: false,
    },
    {
      field: 'id',
      type: 'actions',
      width: root === ROOT_ADMIN_VALUE ? 175 : 70,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <PersonSearchIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
          {(root === ROOT_ADMIN_VALUE || root === ROOT_BUHGALTER_VALUE) && (
            <IconButton
              title="Загрузка договора"
              size="small"
              onClick={() => openAddContract(row.row)}
            >
              <FileUploadIcon fontSize="small" sx={{ color: 'blue' }} />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Добавить уведомления в телеграмм"
              size="small"
              onClick={() => addTelegram(row.row)}
            >
              <TelegramIcon
                fontSize="small"
                sx={{ color: row.row.telegramId !== '' ? 'blue' : 'grey' }}
              />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Смена пароля"
              size="small"
              onClick={() => changePassword(row.row)}
            >
              <SyncLockIcon fontSize="small" sx={{ color: 'purple' }} />
            </IconButton>
          )}
          {root === ROOT_ADMIN_VALUE && (
            <IconButton
              title="Удалить"
              size="small"
              onClick={() => confirmDelete(row.row)}
            >
              <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Филиалы
            </Typography>
            {root === ROOT_ADMIN_VALUE && (
              <MyButton
                text="Создать"
                width="100px"
                height="30px"
                background={MAIN_COLOR_STRING}
                onClick={() => setOpenDialogCreate(true)}
              />
            )}
          </Stack>
          <Divider />
          <div>
            <Box sx={{ width: '100%' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={getCustomerDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </Stack>
      </Card>
      <DialogCustomerCreate
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogMoreInfo
        open={openDialog}
        dto={getCustomerDto}
        onClose={() => setOpenDialog(false)}
      />
      <DialogChangePassword
        open={openDialogPassword}
        dto={getCustomerDto}
        onClose={() => setOpenDialogPassword(false)}
      />
      <DialogContractAdd
        open={openDialogAddContract}
        name="Загрузка договора"
        dto={getCustomerDto}
        onClose={() => setOpenDialogAddContract(false)}
      />
      <DialogAddTelegram
        open={openDialogTelegramId}
        dto={getCustomerDto}
        onClose={() => setOpenDialogTelegramId(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteCustomer}
      />
    </Stack>
  );
};
